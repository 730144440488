// General
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import { Formik, Form, Field } from 'formik';
import { TextField, InputAdornment, IconButton } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { login, resetLoginStatus } from '../../../storages/slices/LoginSlice';
import { addToast } from '../../../storages/slices/ToastSlice';
// Icons
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FaDoorOpen } from "react-icons/fa";

function Login() {
  // Storage Import
  const dispatch = useDispatch();
  const { loginstatus, error } = useSelector((state) => state.login);
  // Variables
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => { setShowPassword(!showPassword) }
  // Helper Function
  const handleLogin = (values) => { dispatch(login({ username: values.username, password: values.password })); }
  const navigate = useNavigate();
  useEffect(() => {
    if (loginstatus === 'fulfilled') {
      navigate("/anasayfa");
      dispatch(addToast({ background: 'success', icon: 'login', message: 'Başarı ile giriş Yaptınız!', delay: 2000 }));
      dispatch(resetLoginStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginstatus]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 5000 })) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // HTML
  return (
    <section className="vh-100 gradient-custom">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-light" style={{ borderRadius: '1rem' }}>
              <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-3">
                  <h2 className="fw-bold mb-2 text-uppercase">ITS</h2>
                  <p className="text-black-50 mb-5">Tekrar hoş geldiniz! Lütfen giriş yapın.</p>
                  <Formik initialValues={{ username: '', password: '' }} onSubmit={(values) => handleLogin(values)}>
                    {(
                      <Form className='w-100 h-100'>
                        <div className="mb-3">
                          <Field
                            as={TextField}
                            id='Login_username'
                            name='username'
                            className='form-control'
                            label="Kullanıcı Adı"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className="mb-3">
                          <Field
                            as={TextField}
                            id='Login_password'
                            name='password'
                            className="form-control"
                            label="Şifre"
                            type={showPassword ? 'text' : 'password'}
                            slotProps={{
                              input: {
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                      {showPassword ? <IoEye /> : <IoEyeOff />}
                                    </IconButton>
                                  </InputAdornment>)
                              }
                            }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <button className="button-with-icon btn btn-outline-primary btn-lg w-100" type='submit'>
                            <FaDoorOpen className='button-icon' />
                            Giriş Yap
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;