// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from '@mui/material/Checkbox';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
// Contexts
import { useData } from '../../../../contexts/DataContext';
// Icons
import { TiDelete } from "react-icons/ti";
import { TbUserMinus, TbFolderCog } from "react-icons/tb";
// Modals
import UserServicesModal from './UserServicesModal';

function ServicePlannerModal({ show, handleClose }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  // Context Import
  const { cities, serviceOperations, sources } = useData();
  // Filtering
  const [filterDate, setFilterDate] = useState('2024-09-10');
  const [filterCity, setFilterCity] = useState('İstanbul');
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [filterDevice, setFilterDevice] = useState([]);
  const [filterSource, setFilterSource] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  useEffect(() => {
    dispatch(getDistricts({ apikey: "tuyak_furkanerhan", city: filterCity }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCity]);
  // Data & Api
  const [allData, setAllData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const handleChangeSelected = (id, value) => {
    setAllData(allData.map(item => item.id === id ? ({ ...item, selected: value }) : item));
  }
  useEffect(() => {
    setSelectedData(allData.filter(item => item.selected === true));
  }, [allData]);
  // Table Functions
  const select = (rowData) => {
    console.log(rowData);
  }
  const check = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <Checkbox checked={rowData.selected} onChange={(e) => handleChangeSelected(rowData.id, e.target.checked ? true : false)} />
      </div>
    );
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className='btn btn-danger m-0 p-2' onClick={() => handleChangeSelected(rowData.id, false)}><TbUserMinus style={{ fontSize: '1.2em' }} /></button>
      </div>
    );
  };
  // Modals
  // User Services Modal
  const [showUserServicesModal, setShowUserServicesModal] = useState(false);
  const [inputDataForUserServicesModal, setInputDataForUserServicesModal] = useState([]);
  const handleCloseUserServicesModal = () => {
    setShowUserServicesModal(false);
    setInputDataForUserServicesModal([]);
  };
  const handleShowUserServicesModal = (data) => {
    setInputDataForUserServicesModal(data);
    setShowUserServicesModal(true);
  };
  // Asign Employee
  const employees = [];
  const [selectedEmployee, setSelectedEmployee] = useState('');
  // HTML
  return (
    <Modal show={show} onHide={handleClose} fullscreen centered>
      <Modal.Header closeButton>
        <Modal.Title>Servis Planlama</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row g-2'>
          <div className='col-12 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body'>
                <div className='row g-2'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      id='date'
                      name='date'
                      type="date"
                      variant='outlined'
                      size='small'
                      value={filterDate}
                      onChange={(e) => setFilterDate(e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='filterCity'
                      name='filterCity'
                      value={filterCity}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFilterCity(newValue);
                          setFilterDistrict([]);
                        } else {
                          setFilterCity('');
                          setFilterDistrict([]);
                        }
                      }}
                      variant='outlined'
                      size='small'
                      options={cities}
                      renderInput={(params) => <TextField {...params} label="İl" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='filterDistrict'
                      name='filterDistrict'
                      multiple
                      limitTags={3}
                      options={districts}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterDistrict || []}
                      onChange={(event, newValue) => { if (filterCity !== '') { setFilterDistrict(newValue); } }}
                      disabled={filterCity === ''}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterDistrict.filter((i) => i !== option);
                                    setFilterDistrict(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="İlçeler" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='device'
                      name='device'
                      multiple
                      limitTags={3}
                      options={[]}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterDevice || []}
                      onChange={(event, newValue) => { setFilterDevice(newValue); }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterDevice.filter((i) => i !== option);
                                    setFilterDevice(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Cihazlar" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='source'
                      name='source'
                      multiple
                      limitTags={3}
                      options={sources}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterSource || []}
                      onChange={(event, newValue) => { setFilterSource(newValue); }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterSource.filter((i) => i !== option);
                                    setFilterSource(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Kaynaklar" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='status'
                      name='status'
                      value={filterStatus}
                      onChange={(event, newValue) => newValue ? setFilterStatus(newValue) : setFilterStatus('')}
                      variant='outlined'
                      size='small'
                      options={serviceOperations}
                      renderInput={(params) => <TextField {...params} label="Durum" />}
                    />
                  </div>
                  <div className='col-12 col-md-8'></div>
                  <div className='col-12 col-md-4'>
                    <button className='btn btn-primary w-100'>Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body'>
                <DataTable
                  value={allData}
                  size='small'
                  className='activehover'
                  stripedRows
                  emptyMessage="Bu tabloda henüz veri yok!"
                  showGridlines
                  paginatorLeft
                  paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                  paginator
                  paginatorClassName='bg-light'
                  rows={5}
                  onSelectionChange={(e) => select((e.value.id))}
                  selectionMode="single">
                  <Column align='center' body={check} bodyClassName='align-middle' />
                  <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field='customer' header='MÜŞTERİ' bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field='district' header='İLÇE' bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field='device' header='CİHAZ' bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field='problem' header='ARIZA' bodyClassName='align-middle fs-6 text-center' />
                </DataTable >
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body d-flex flex-column'>
                <div className='row g-2 flex-grow-1'>
                  <div className='col-12'>
                    <DataTable
                      value={selectedData}
                      size='small'
                      className='activehover'
                      stripedRows
                      emptyMessage="Bu tabloda henüz veri yok!"
                      showGridlines
                      paginatorLeft
                      paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                      paginator
                      paginatorClassName='bg-light'
                      rows={5}
                      onSelectionChange={(e) => select((e.value.id))}
                      selectionMode="single">
                      <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                      <Column align='center' sortable field='customer' header='MÜŞTERİ' bodyClassName='align-middle fs-6 text-center' />
                      <Column align='center' sortable field='district' header='İLÇE' bodyClassName='align-middle fs-6 text-center' />
                      <Column align='center' sortable field='device' header='CİHAZ' bodyClassName='align-middle fs-6 text-center' />
                      <Column align='center' sortable field='problem' header='ARIZA' bodyClassName='align-middle fs-6 text-center' />
                      <Column align='center' body={buttons} bodyClassName='align-middle' />
                    </DataTable >
                  </div>
                  <div className='col-12 mt-auto'>
                    <div className='row g-2'>
                      <div className='col-12 col-md-4'>
                        <Autocomplete
                          value={selectedEmployee}
                          onChange={(event, newValue) => newValue ? setSelectedEmployee(newValue) : setSelectedEmployee('')}
                          variant='outlined'
                          size='small'
                          options={employees}
                          renderInput={(params) => <TextField {...params} label="Personel" />}
                        />
                      </div>
                      <div className='col-12 col-md-4'>
                        <button className='btn btn-warning w-100' onClick={() => handleShowUserServicesModal([])} disabled={selectedEmployee === ''}>
                          Atanmış Servisleri Gör
                        </button>
                      </div>
                      <div className='col-12 col-md-4'>
                        <button className='btn btn-success w-100'>Atama Yap</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <UserServicesModal
        show={showUserServicesModal}
        handleClose={handleCloseUserServicesModal}
        inputData={inputDataForUserServicesModal}
      />
    </Modal>
  )
}

export default ServicePlannerModal