// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  getAllShelfs,
  getShelfById,
  addNewShelf,
  updateShelf,
  deleteShelf,
  resetAddStatus,
  resetUpdateStatus,
  resetDeleteStatus
} from '../../../storages/slices/backend/ShelfSlice';
// Icons
import { BsFillPatchPlusFill, BsFillPatchCheckFill, BsFillPatchMinusFill } from "react-icons/bs";
import { TbPrinter } from "react-icons/tb";
// Modals
import ShelveAddModal from "../modals/shelves/ShelveAddModal";
import DeleteModal from "../modals/DeleteModal";
import ShelveUpdateModal from "../modals/shelves/ShelveUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Shelves() {
  // Storage Import
  const dispatch = useDispatch();
  const { shelves, shelf, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.shelf);
  // Data & Api
  useEffect(() => {
    dispatch(getAllShelfs());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseShelveAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllShelfs());
      dispatch(addToast({ background: 'success', icon: 'patchAdd', message: 'Yeni Raf Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseShelveUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllShelfs());
      dispatch(addToast({ background: 'primary', icon: 'patchUpdate', message: 'Raf Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseShelveDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllShelfs());
      dispatch(addToast({ background: 'danger', icon: 'patchDelete', message: 'Raf Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForShelveUpdateModal(shelf);
        setShowShelveUpdateModal(true);
        setDataRequester('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 })); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'RAF' }, { value: 'warehousename', name: 'DEPO' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'warehousename', 'description'];
  const select = (id) => {
    handleShowShelveUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowShelveUpdateModal(rowData.id); }}><BsFillPatchCheckFill className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowShelveDeleteModal(rowData.id); }}><BsFillPatchMinusFill className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={shelves}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowShelveAddModal(); }}>
          <BsFillPatchPlusFill className='button-icon' />
          Yeni Raf Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: shelves, cols: cols, pdftitle: "Markalar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Brand Add Modal
  const [showShelveAddModal, setShowShelveAddModal] = useState(false);
  const handleCloseShelveAddModal = () => { setShowShelveAddModal(false) };
  const handleShowShelveAddModal = () => { setShowShelveAddModal(true) };
  const handleAddShelveAddModal = (values) => {
    dispatch(addNewShelf({ newshelf: values }));
    handleCloseShelveAddModal();
  }
  // Brand Update Modal
  const [showShelveUpdateModal, setShowShelveUpdateModal] = useState(false);
  const [inputDataForShelveUpdateModal, setInputDataForShelveUpdateModal] = useState({});
  const handleCloseShelveUpdateModal = () => { setShowShelveUpdateModal(false); setInputDataForShelveUpdateModal({}); }
  const handleShowShelveUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getShelfById({ id: id }));
  }
  const handleUpdateShelveUpdateModal = (values) => {
    dispatch(updateShelf({ brand: values }));
    handleCloseShelveUpdateModal();
  }
  // Brand Delete Modal
  const [showShelveDeleteModal, setShowShelveDeleteModal] = useState(false);
  const [inputDataForShelveDeleteModal, setInputDataForShelveDeleteModal] = useState(-1);
  const handleCloseShelveDeleteModal = () => { setShowShelveDeleteModal(false); setInputDataForShelveDeleteModal(-1); }
  const handleShowShelveDeleteModal = (id) => { setInputDataForShelveDeleteModal(id); setShowShelveDeleteModal(true); }
  const handleDeleteShelveDeleteModal = () => {
    if (inputDataForShelveDeleteModal !== -1) { dispatch(deleteShelf({ id: inputDataForShelveDeleteModal })); }
    handleCloseShelveDeleteModal();
  }
  // Return Modals
  const modals = () => {
    return (
      <>
        <ShelveAddModal
          show={showShelveAddModal}
          handleClose={handleCloseShelveAddModal}
          handleAdd={handleAddShelveAddModal}
        />
        <ShelveUpdateModal
          show={showShelveUpdateModal}
          handleClose={handleCloseShelveUpdateModal}
          handleUpdate={handleUpdateShelveUpdateModal}
          inputData={inputDataForShelveUpdateModal}
        />
        <DeleteModal
          show={showShelveDeleteModal}
          handleClose={handleCloseShelveDeleteModal}
          handleDelete={handleDeleteShelveDeleteModal}
          title="Raf'ı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForShelveDeleteModal} id numaralı rafı silmek istediğinize emin misiniz?`}
          deleteIcon={<BsFillPatchMinusFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Markalar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Shelves;