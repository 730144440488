// General
import { createContext, useContext } from 'react';

const FunctionContext = createContext();

const FunctionProvider = ({ children }) => {
  // Variable Manipulater Functions
  const wordCombination = (text) => { return text.replace(/\s/g, '').replace(/ğ/g, 'g'); }
  const stringMaxLenght = (text, limit) => { return text.length > limit ? text.slice(0, limit) : text; }
  const stringOnlyNumbers = (text) => { return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(text[text.length - 1]) ? text : text.slice(0, text.length - 1); }
  const stringMoney = (price, currency) => {
    const fixedPrice = parseFloat(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const lastDotIndex = fixedPrice.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fixedPrice.substring(0, lastDotIndex) + ',' + fixedPrice.substring(lastDotIndex + 1) + ' ' + currency;
    }
    return fixedPrice + ' ' + currency;
  }
  const listSortAlphabetically = (list) => { return list.slice().sort((a, b) => a.localeCompare(b)); }
  const listAddItem = (list, newItem) => { return [...list, newItem]; }
  const listDeleteItem = (list, itemId) => { return list.filter(item => item.id !== itemId); }
  // Calculate Functions
  const calculateMultiplebyCount = (list, columnName) => { return list.map(item => ({ ...item, [`total_${columnName}`]: (item['count'] || 0) * (item[columnName] || 0) })); }
  const calculateTotalOfColumn = (list, columnName) => { return list.reduce((total, item) => { return total + (item[columnName] || 0); }, 0); }
  const calculateAmountWithoutKdv = (amount, kdvRate) => { return amount / (1 + kdvRate); }
  const calculateTotal = (amount, kdvRate, withholdingRate) => {
    const kdvAmount = amount * kdvRate;
    const withholdingAmount = kdvAmount * withholdingRate;
    return amount + kdvAmount - withholdingAmount;
  }
  // Page Navigator Funcions
  const openNewPage = (page, filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    const url = `/${page}?${queryParams}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  // Export
  const values = { stringMaxLenght, stringOnlyNumbers, stringMoney, listSortAlphabetically, listAddItem, listDeleteItem, calculateMultiplebyCount, calculateTotalOfColumn, calculateAmountWithoutKdv, calculateTotal, openNewPage, wordCombination }
  return <FunctionContext.Provider value={values}>{children}</FunctionContext.Provider>
}

const useFunctions = () => useContext(FunctionContext);

export { FunctionProvider, useFunctions };