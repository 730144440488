// General
import React from 'react'
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { MdOutlineWarehouse } from "react-icons/md";

function WarehouseStoreAddModal({ show, handleClose, handleAdd }) {
  // Context Import
  const { validationSchema_WarehouseName } = useValidations();
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Yeni Depo Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_WarehouseName} initialValues={{ warehouseName: '' }} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='WarehouseStoreAddModal_warehouseName'
                      name='warehouseName'
                      label='Depo Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.warehouseName && !!errors.warehouseName}
                      helperText={touched.warehouseName && errors.warehouseName}
                      onFocus={() => { setFieldTouched('warehouseName', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><MdOutlineWarehouse className='button-icon' />Depo Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default WarehouseStoreAddModal