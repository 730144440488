// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  addNewRole,
  deleteRole,
  getAllRoles,
  getRoleById,
  resetAddStatus,
  resetDeleteStatus,
  resetError,
  resetgetByIdStatus,
  resetUpdateStatus,
  updateRole
} from '../../../storages/slices/backend/RoleSlice';
// Icons
import { TbLayoutGridAdd, TbLayoutGrid, TbLayoutGridRemove } from "react-icons/tb";
import { TbPrinter } from "react-icons/tb";
// Modals
import RoleAddModal from "../modals/roles/RoleAddModal";
import DeleteModal from "../modals/DeleteModal";
import RoleUpdateModal from "../modals/roles/RoleUpdateModal";
import RolePermissionModal from "../modals/roles/RolePermissionModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Roles() {
  // Storage
  const dispatch = useDispatch();
  const { roles, role, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.role);
  // Data & Api
  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseRoleAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllRoles());
      dispatch(addToast({ background: 'success', icon: 'layoutAdd', message: 'Yeni Rol Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseRoleUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllRoles());
      dispatch(addToast({ background: 'primary', icon: 'layoutUpdate', message: 'Rol Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseRoleDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllRoles());
      dispatch(addToast({ background: 'danger', icon: 'layoutDelete', message: 'Rol Silindi!', delay: 6000 }));
    }
    if (getByIdStatus === 'fulfilled') {
      setInputDataForRoleUpdateModal(role);
      setShowRoleUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, getByIdStatus, dispatch]);
  useEffect(() => {
    if (error) {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'ROL ADI' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => {
    handleShowRoleUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => {
          e.stopPropagation();
          handleShowRoleUpdateModal(rowData.id);
        }}><TbLayoutGrid className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-warning mx-1" onClick={(e) => {
          e.stopPropagation();
        }}><TbLayoutGrid className='button-icon' />İzinler</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => {
          e.stopPropagation();
          handleShowRoleDeleteModal(rowData.id)
        }}><TbLayoutGridRemove className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={roles}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowRoleAddModal(); }}><TbLayoutGridAdd className='button-icon' /> Yeni Rol  Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: roles, cols: cols, pdftitle: "Roller" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Role Add Modal
  const [showRoleAddModal, setShowRoleAddModal] = useState(false);
  const handleCloseRoleAddModal = () => { setShowRoleAddModal(false) };
  const handleShowRoleAddModal = () => { setShowRoleAddModal(true) };
  const handleAddRoleAddModal = (values) => {
    dispatch(addNewRole({ newrole: values }));
    handleCloseRoleAddModal();
  };
  // Role Update Modal
  const [showRoleUpdateModal, setShowRoleUpdateModal] = useState(false);
  const [inputDataForRoleUpdateModal, setInputDataForRoleUpdateModal] = useState({});
  const handleCloseRoleUpdateModal = () => {
    setShowRoleUpdateModal(false)
    setInputDataForRoleUpdateModal({});
  };
  const handleShowRoleUpdateModal = (id) => {
    dispatch(getRoleById({ id: id }));
  }
  const handleUpdateRoleUpdateModal = (values) => {
    console.log(values);
    dispatch(updateRole({ role: values }));
    handleCloseRoleUpdateModal();
  };
  // Setting Permission Modal
  const [showSettingPermissionModal, setShowSettingPermissionModal] = useState(false);
  const [inputDataForSettingPermissionModal, setInputDataForSettingPermissionModal] = useState({});
  const handleCloseSettingPermissionModal = () => {
    setShowSettingPermissionModal(false)
    setInputDataForSettingPermissionModal({});
  };
  const handleShowSettingPermissionModal = (data) => {
    setInputDataForSettingPermissionModal(data);
    setShowSettingPermissionModal(true);
  };
  const handleSettingPermissionModal = (values) => {
    handleCloseSettingPermissionModal();
  };
  // Role Delete Modal
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
  const [inputDataForRoleDeleteModal, setInputDataForRoleDeleteModal] = useState(-1);
  const handleCloseRoleDeleteModal = () => {
    setShowRoleDeleteModal(false)
    setInputDataForRoleDeleteModal(-1);
  };
  const handleShowRoleDeleteModal = (data) => {
    setInputDataForRoleDeleteModal(data);
    setShowRoleDeleteModal(true);
  };
  const handleDeleteRoleDeleteModal = (values) => {
    if (inputDataForRoleDeleteModal !== -1) { dispatch(deleteRole({ id: inputDataForRoleDeleteModal })); }
    handleCloseRoleDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <RoleAddModal
          show={showRoleAddModal}
          handleClose={handleCloseRoleAddModal}
          handleAdd={handleAddRoleAddModal}
        />
        <RoleUpdateModal
          show={showRoleUpdateModal}
          handleClose={handleCloseRoleUpdateModal}
          handleUpdate={handleUpdateRoleUpdateModal}
          inputData={inputDataForRoleUpdateModal}
        />
        <RolePermissionModal
          show={showSettingPermissionModal}
          handleClose={handleCloseSettingPermissionModal}
          handleUpdate={handleSettingPermissionModal}
          inputData={inputDataForSettingPermissionModal}
        />
        <DeleteModal
          show={showRoleDeleteModal}
          handleClose={handleCloseRoleDeleteModal}
          handleDelete={handleDeleteRoleDeleteModal}
          title="Rolü Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForRoleDeleteModal} id numaralı rolü silmek istediğinize emin misiniz?`}
          deleteIcon={<TbLayoutGridRemove className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Roller' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Roles;