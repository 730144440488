// General
import React, { useCallback, useEffect, useState } from 'react';
// Storage
import { useDispatch } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TbPrinter, } from "react-icons/tb";
import { CiCalendar } from "react-icons/ci";
//Modals
import ReminderAddModal from '../modals/reminders/ReminderAddModal';
import ReminderUpdateModal from '../modals/reminders/ReminderUpdateModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';

function Reminder() {
  // Storage
  const dispatch = useDispatch();
  // Context Import

  //Filtering
  const [filterStartDate, setFilterStartDate] = useState(new Date().toLocaleDateString());
  const [filterEndDate, setFilterEndDate] = useState(new Date().toLocaleDateString());
  const filters = ['id', 'message', 'date', 'remainingTime', 'repeatTime', 'employee', 'performerOfThetransaction', 'status'];
  const handleSetDates = (startDate, endDate) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }

  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const data = [
    { id: 1001, message: 'mesaj 1', date: '12.02.2024', time: '15.00', remainingTime: '20', repeatTime: '10', employee: 'Emir', performerOfThetransaction: ['Emir', 'İsa'], status: 'Tamamlandı', }, { id: 1002, message: 'mesaj 2', date: '12.02.2024', time: '15.00', remainingTime: '20', repeatTime: '10', employee: 'İsa', performerOfThetransaction: ['İsa', 'Furkan'], status: 'Aktif', },];
  useEffect(() => {
    const toTable = data.map((item) => {
      const id = item.id;
      const message = item.message
      const date = item.date;
      const remainingTime = item.remainingTime;
      const repeatTime = item.repeatTime;
      const employee = item.employee;
      const performerOfThetransaction = item.performerOfThetransaction;
      const status = item.status;
      return { id: id, message: message, date: date, remainingTime: remainingTime, repeatTime: repeatTime, employee: employee, performerOfThetransaction: performerOfThetransaction, status: status, };
    });
    setDataToTable(toTable);
  }, [filterStartDate, filterEndDate]);
  // Table Content
  const rowsPerPage = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'message', name: 'MESAJ' }, { value: 'date', name: 'TARİH' }, { value: 'remainingTime', name: 'KALAN ZAMAN' }, { value: 'repeatTime', name: 'TEKRAR SÜRESİ' }, { value: 'employee', name: 'PERSONEL' }, { value: 'performerOfThetransaction', name: 'İŞLEMİ YAPAN' }, { value: 'status', name: 'DURUM' }];
  const handleSelectedFunction = (id) => {
    handleShowReminderUpdateModal(data.find(dt => dt.id === id));
  }
  const tableButtons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button onClick={(e) => { e.stopPropagation(); handleShowReminderUpdateModal(data.find(dt => dt.id === rowData.id)); }} className="button-with-icon btn btn-primary mx-1"><CiCalendar className='button-icon' />Güncelle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowReminderDeleteModal(rowData.id); }} className="button-with-icon btn btn-danger mx-1"><CiCalendar className='button-icon' />Sil</button>
      </div >
    );
  };
  const table = () => {
    return (
      <Table
        data={dataToTable}
        rows={rowsPerPage}
        columns={cols}
        filternames={filters}
        select={handleSelectedFunction}
        dates={handleSetDates}
        buttons={tableButtons}
      />
    );
  }
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowReminderAddModal(); }} className="dropdown-item"><CiCalendar style={{ fontSize: '1.5em' }} /> Hatırlatıcı Ekle</button>
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rowsPerPage, data: data, cols: cols, pdftitle: "Hatırlatıcılar" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Reminder Add Modal
  const [showReminderAddModal, setShowReminderAddModal] = useState(false);
  const handleCloseReminderAddModal = () => { setShowReminderAddModal(false) };
  const handleShowReminderAddModal = () => { setShowReminderAddModal(true) };
  const handleAddReminderAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Hatırlatıcı Eklendi!', delay: 6000 }));
    handleCloseReminderAddModal();
  };
  // Reminder Update Modal
  const [showReminderUpdateModal, setShowReminderUpdateModal] = useState(false);
  const [inputDataForReminderUpdateModal, setInputDataForReminderUpdateModal] = useState({});
  const handleCloseReminderUpdateModal = () => {
    setShowReminderUpdateModal(false)
    setInputDataForReminderUpdateModal({});
  };
  const handleShowReminderUpdateModal = (data) => {
    setInputDataForReminderUpdateModal(data);
    setShowReminderUpdateModal(true);
  };
  const handleUpdateReminderUpdateModal = (values) => {
    console.log('Hatırlarıcı güncellendi: ', inputDataForReminderUpdateModal, ' --> ', values);
    dispatch(addToast({ background: 'primary', icon: 'packageUpdate', message: 'Hatırlatıcı Güncellendi!', delay: 6000 }));
    handleCloseReminderUpdateModal();
  };
  // Reminder Delete Modal
  const [showReminderDeleteModal, setShowReminderDeleteModal] = useState(false);
  const [inputDataForReminderDeleteModal, setInputDataForReminderDeleteModal] = useState(-1);
  const handleCloseReminderDeleteModal = () => {
    setShowReminderDeleteModal(false)
    setInputDataForReminderDeleteModal(-1);
  };
  const handleShowReminderDeleteModal = (data) => {
    setInputDataForReminderDeleteModal(data);
    setShowReminderDeleteModal(true);
  };
  const handleDeleteReminderDeleteModal = (values) => {
    console.log(values, ' id numaralı Personel silindi.');
    dispatch(addToast({ background: 'danger', icon: 'packageDelete', message: 'Hatırlatıcı Silindi!', delay: 6000 }));
    handleCloseReminderDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <ReminderAddModal
          show={showReminderAddModal}
          handleClose={handleCloseReminderAddModal}
          handleAdd={handleAddReminderAddModal}>
        </ReminderAddModal>
        <ReminderUpdateModal
          show={showReminderUpdateModal}
          handleClose={handleCloseReminderUpdateModal}
          handleUpdate={handleUpdateReminderUpdateModal}
          inputData={inputDataForReminderUpdateModal}>
        </ReminderUpdateModal>
        <DeleteModal
          show={showReminderDeleteModal}
          handleClose={handleCloseReminderDeleteModal}
          handleDelete={handleDeleteReminderDeleteModal}
          title="Hatırlatıcı"
          message={inputDataForReminderDeleteModal + " " + "numaralı hatırlatıcıyı silmek istediğinize eminmisiniz"}
          deleteIcon={<CiCalendar className='button-icon' />}
        >
        </DeleteModal>
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='Hatırlatıcı' Operations={operations} Table={table} Modals={modals} />
  );
}

export default Reminder;