// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  getAllVehicles,
  getVehicleById,
  addNewVehicle,
  updateVehicle,
  deleteVehicle,
  resetAddStatus,
  resetUpdateStatus,
  resetDeleteStatus
} from '../../../storages/slices/backend/VehicleSlice';
// Icons
import { IoAddCircle, IoCheckmarkCircle, IoRemoveCircle } from "react-icons/io5";
import { TbPrinter } from "react-icons/tb";
// Modals
import VehicleAddModal from "../modals/vehicles/VehicleAddModal";
import DeleteModal from "../modals/DeleteModal";
import VehicleUpdateModal from "../modals/vehicles/VehicleUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Brands() {
  // Storage Import
  const dispatch = useDispatch();
  const { vehicles, vehicle, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.vehicle);
  // Data & Api
  useEffect(() => {
    dispatch(getAllVehicles());
  }, [dispatch]);
  useEffect(() => {
    console.log(addStatus, ' - ', updateStatus, ' - ', deleteStatus);
    if (addStatus === "fulfilled") {
      handleCloseVehicleAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllVehicles());
      dispatch(addToast({ background: 'success', icon: 'patchAdd', message: 'Yeni Araç Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseVehicleUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllVehicles());
      dispatch(addToast({ background: 'primary', icon: 'patchUpdate', message: 'Araç Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseVehicleDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllVehicles());
      dispatch(addToast({ background: 'danger', icon: 'patchDelete', message: 'Araç Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForVehicleUpdateModal(vehicle);
        setShowVehicleUpdateModal(true);
        setDataRequester('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 })); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'plate', name: 'PLAKA' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'plate', 'description'];
  const select = (id) => {
    handleShowVehicleUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowVehicleUpdateModal(rowData.id); }}><IoCheckmarkCircle className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowVehicleDeleteModal(rowData.id); }}><IoRemoveCircle className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={vehicles}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowVehicleAddModal(); }}>
          <IoAddCircle className='button-icon' />
          Yeni Araç Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: vehicles, cols: cols, pdftitle: "Markalar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Vehicle Add Modal
  const [showVehicleAddModal, setShowVehicleAddModal] = useState(false);
  const handleCloseVehicleAddModal = () => { setShowVehicleAddModal(false) };
  const handleShowVehicleAddModal = () => { setShowVehicleAddModal(true) };
  const handleAddVehicleAddModal = (values) => {
    dispatch(addNewVehicle({ newvehicle: values }));
    handleCloseVehicleAddModal();
  };
  // Vehicle Update Modal
  const [showVehicleUpdateModal, setShowVehicleUpdateModal] = useState(false);
  const [inputDataForVehicleUpdateModal, setInputDataForVehicleUpdateModal] = useState({});
  const handleCloseVehicleUpdateModal = () => {
    setShowVehicleUpdateModal(false)
    setInputDataForVehicleUpdateModal({});
  };
  const handleShowVehicleUpdateModal = (id) => {
    dispatch(getVehicleById({ id: id }));
    setDataRequester('update');
  };
  const handleUpdateVehicleUpdateModal = (values) => {
    dispatch(updateVehicle({ vehicle: values }));
    handleCloseVehicleUpdateModal();
  };
  // Vehicle Delete Modal
  const [showVehicleDeleteModal, setShowVehicleDeleteModal] = useState(false);
  const [inputDataForVehicleDeleteModal, setInputDataForVehicleDeleteModal] = useState(-1);
  const handleCloseVehicleDeleteModal = () => {
    setShowVehicleDeleteModal(false)
    setInputDataForVehicleDeleteModal(-1);
  };
  const handleShowVehicleDeleteModal = (id) => {
    setInputDataForVehicleDeleteModal(id);
    setShowVehicleDeleteModal(true);
  };
  const handleDeleteVehicleDeleteModal = () => {
    dispatch(deleteVehicle({ id: inputDataForVehicleDeleteModal }))
    handleCloseVehicleDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <VehicleAddModal
          show={showVehicleAddModal}
          handleClose={handleCloseVehicleAddModal}
          handleAdd={handleAddVehicleAddModal}
        />
        <VehicleUpdateModal
          show={showVehicleUpdateModal}
          handleClose={handleCloseVehicleUpdateModal}
          handleUpdate={handleUpdateVehicleUpdateModal}
          inputData={inputDataForVehicleUpdateModal}
        />
        <DeleteModal
          show={showVehicleDeleteModal}
          handleClose={handleCloseVehicleDeleteModal}
          handleDelete={handleDeleteVehicleDeleteModal}
          title="Aracı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForVehicleDeleteModal} id numaralı aracı silmek istediğinize emin misiniz?`}
          deleteIcon={<IoRemoveCircle className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Araçlar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Brands;