// General
import React from 'react'
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { TbUserPlus, TbArrowBackUpDouble } from "react-icons/tb";

function WarehouseSupplierAddModal({ show, handleClose, handleAdd }) {
  // Context
  const { validationSchema_WarehouseSupplier } = useValidations();
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Yeni Tedarikçi Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_WarehouseSupplier} initialValues={{ supplier: '' }} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='WarehouseSupplierAddModal_supplier'
                      name='supplier'
                      label='Tedarikçi Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.supplier && !!errors.supplier}
                      helperText={touched.supplier && errors.supplier}
                      onFocus={() => { setFieldTouched('supplier', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><TbUserPlus className='button-icon' />Tedarikçi Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}


export default WarehouseSupplierAddModal