import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// Api
import axios from 'axios';
// PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// Font 
import { font, fontbold } from '../../assets/fonts/PTSerifRegular';

// async thunk
export const getOfferPDF = createAsyncThunk('print/getOfferPDF', async () => {
  const PDF_API_HEADER = {
    "Content-Type": 'application/json',
    "X-API-KEY": "fe70MTQ4NzE6MTQ5Mzk6NmhnVGlvZlA0WURhVzhYdA="
  }
  const pdfdata = {
    template_id: '89977b234c3cd2e4',
    data: {
      date: "2021-09-30",
      due_date: "2021-09-30",
      currency: "TRY",
      discount: 0,
      tax: 0,
      company_name: "İstanbul Teknik Servis",
      email: "ist@its.com.tr",
      client: "Müşteri Adı",
      client_address: "Müşteri Adresi",
      client_address2: "Şehir / İlçe",
      tel: "5555555555",
      company_address: "Nine Hatun Mah. 163. SK. Özcan Apt, No: 2 A, 34220 Esenler/İstanbul",
      items: [
        {
          description: "Buzdolabı",
          quantity: 1,
          unit_price: 50,
          total: 50,
        },
        {
          description: "Buzdolabı Kapı Kolu",
          quantity: 1,
          unit_price: 50,
          total: 50,
        }
      ],
      gross_total: 100
    }
  };
  try {
    const response = await axios.post("https://api.craftmypdf.com/v1/create", pdfdata, { headers: PDF_API_HEADER });
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
})
// Slice
const printSlice = createSlice({
  name: 'print',
  initialState: {
    pdfData: null,
    status: 'idle',
    error: null,
    filtervalue: '',
    filterpageindex: 0,
  },
  reducers: {
    exportPdf: (state, action) => {
      const { rowsPerPage, data, cols, pdftitle } = action.payload;
      const filtereddata = state.filtervalue;
      const pageindexvalue = state.filterpageindex;
      const startindex = pageindexvalue * rowsPerPage;
      const endindex = (startindex) + rowsPerPage;
      const doc = new jsPDF();
      doc.addFileToVFS('PTSerif-Regular.ttf', font.trim());
      doc.addFont('PTSerif-Regular.ttf', 'PTSerif', 'normal');
      doc.addFileToVFS('PTSerif-Bold.ttf', fontbold.trim());
      doc.addFont('PTSerif-Bold.ttf', 'PTSerif', 'bold');
      doc.setFont('PTSerif', 'normal');
      doc.setFontSize(14);
      doc.text(pdftitle, doc.internal.pageSize.getWidth() / 2, 15, { align: "center" });
      doc.setFontSize(10);
      doc.text(`Tarih: ${new Date().toLocaleDateString()}`, 14, 20);
      const currentData = filtereddata ? data.filter(item => Object.values(item).some(val => String(val).toLowerCase().includes(filtereddata.toLowerCase())))
        .slice(startindex, endindex)
        : data.slice(startindex, endindex);
      const statusIndex = cols.findIndex(col => col.name === 'DURUM');
      const costIndex = cols.findIndex(col => col.name === 'TUTAR');
      doc.autoTable({
        head: [cols.map(col => col.name)],
        body: currentData.map(item => cols.map(col => item[col.value])),
        styles: {
          font: "PTSerif",
          valign: 'middle',
        },
        didParseCell: function (data) {
          if (data.column.index === statusIndex || data.column.index === costIndex) {
            data.cell.styles.font = 'PTSerif';
            data.cell.styles.fontStyle = 'bold';
            const statusText = data.row.raw[statusIndex];
            if (statusText !== undefined) {
              console.log(statusText);
              if (statusText.toString().includes("Gelen Ödeme")) {
                if (data.column.index === costIndex) {
                  data.cell.text = `+${data.cell.text}`
                }
                data.cell.styles.textColor = "green";
              } else if (statusText.toString().includes("Giden Ödeme")) {
                if (data.column.index === costIndex) {
                  data.cell.text = `-${data.cell.text}`
                }
                data.cell.styles.textColor = "red";
              }
            }
          }
          else {
            data.cell.styles.font = 'PTSerif';
            data.cell.styles.fontStyle = 'normal';
          }
        },
        margin: { top: 25 },
      });
      doc.save(pdftitle);
    },
    setDtFilterValue: (state, action) => {
      state.filtervalue = action.payload;
    },
    setFilterPageIndex: (state, action) => {
      state.filterpageindex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferPDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOfferPDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pdfData = action.payload;
      })
      .addCase(getOfferPDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
})
export const { exportPdf, setDtFilterValue, setFilterPageIndex } = printSlice.actions;
export default printSlice.reducer;

