// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  getAllBrands,
  getBrandById,
  addNewBrand,
  updateBrand,
  deleteBrand,
  resetAddStatus,
  resetUpdateStatus,
  resetDeleteStatus
} from '../../../storages/slices/backend/BrandSlice';
// Icons
import { BsFillPatchPlusFill, BsFillPatchCheckFill, BsFillPatchMinusFill } from "react-icons/bs";
import { TbPrinter } from "react-icons/tb";
// Modals
import BrandAddModal from '../modals/brands/BrandAddModal';
import BrandUpdateModal from '../modals/brands/BrandUpdateModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Brands() {
  // Storage Import
  const dispatch = useDispatch();
  const { brands, brand, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.brand);
  // Data & Api
  useEffect(() => {
    dispatch(getAllBrands());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseBrandAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllBrands());
      dispatch(addToast({ background: 'success', icon: 'patchAdd', message: 'Yeni Marka Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseBrandUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllBrands());
      dispatch(addToast({ background: 'primary', icon: 'patchUpdate', message: 'Marka Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseBrandDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllBrands());
      dispatch(addToast({ background: 'danger', icon: 'patchDelete', message: 'Marka Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForBrandUpdateModal(brand);
        setShowBrandUpdateModal(true);
        setDataRequester('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 })); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'MARKA' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => {
    handleShowBrandUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowBrandUpdateModal(rowData.id); }}><BsFillPatchCheckFill className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowBrandDeleteModal(rowData.id); }}><BsFillPatchMinusFill className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={brands}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowBrandAddModal(); }}>
          <BsFillPatchPlusFill className='button-icon' />
          Yeni Marka Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: brands, cols: cols, pdftitle: "Markalar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Brand Add Modal
  const [showBrandAddModal, setShowBrandAddModal] = useState(false);
  const handleCloseBrandAddModal = () => { setShowBrandAddModal(false) };
  const handleShowBrandAddModal = () => { setShowBrandAddModal(true) };
  const handleAddBrandAddModal = (values) => {
    dispatch(addNewBrand({ newbrand: values }));
    handleCloseBrandAddModal();
  }
  // Brand Update Modal
  const [showBrandUpdateModal, setShowBrandUpdateModal] = useState(false);
  const [inputDataForBrandUpdateModal, setInputDataForBrandUpdateModal] = useState({});
  const handleCloseBrandUpdateModal = () => { setShowBrandUpdateModal(false); setInputDataForBrandUpdateModal({}); }
  const handleShowBrandUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getBrandById({ id: id }));
  }
  const handleUpdateBrandUpdateModal = (values) => {
    dispatch(updateBrand({ brand: values }));
    handleCloseBrandUpdateModal();
  }
  // Brand Delete Modal
  const [showBrandDeleteModal, setShowBrandDeleteModal] = useState(false);
  const [inputDataForBrandDeleteModal, setInputDataForBrandDeleteModal] = useState(-1);
  const handleCloseBrandDeleteModal = () => { setShowBrandDeleteModal(false); setInputDataForBrandDeleteModal(-1); }
  const handleShowBrandDeleteModal = (id) => { setInputDataForBrandDeleteModal(id); setShowBrandDeleteModal(true); }
  const handleDeleteBrandDeleteModal = () => {
    if (inputDataForBrandDeleteModal !== -1) { dispatch(deleteBrand({ id: inputDataForBrandDeleteModal })); }
    handleCloseBrandDeleteModal();
  }
  // Return Modals
  const modals = () => {
    return (
      <>
        <BrandAddModal
          show={showBrandAddModal}
          handleClose={handleCloseBrandAddModal}
          handleAdd={handleAddBrandAddModal}
        />
        <BrandUpdateModal
          show={showBrandUpdateModal}
          handleClose={handleCloseBrandUpdateModal}
          handleUpdate={handleUpdateBrandUpdateModal}
          inputData={inputDataForBrandUpdateModal}
        />
        <DeleteModal
          show={showBrandDeleteModal}
          handleClose={handleCloseBrandDeleteModal}
          handleDelete={handleDeleteBrandDeleteModal}
          title="Marka'yı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForBrandDeleteModal} id numaralı markayı silmek istediğinize emin misiniz?`}
          deleteIcon={<BsFillPatchMinusFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Markalar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Brands;