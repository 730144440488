// General
import React, { useEffect, useState } from 'react';
// Styles
import { Tabs, Tab } from 'react-bootstrap';
import { Autocomplete, TextField, Chip } from '@mui/material';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TiDelete } from "react-icons/ti";
import { TbPrinter } from "react-icons/tb";
// Components
import PieChart from '../components/PieChart';
import Table from '../components/Table';
import SwitchButton from '../components/SwitchButton';

function Statistics() {
  // Context Import
  const { stringMoney, openNewPage } = useFunctions();
  // Filtering Variables
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const [showChart, setShowChart] = useState(false);
  // Temp Data
  // Service Data
  const brandServiceStats = [{ brand: 'Arçelik', total: 4 }, { brand: 'Vestel', total: 3 }, { brand: 'Beko', total: 3 }, { brand: 'Siemens', total: 2 }, { brand: 'Profilo', total: 1 }, { brand: 'Bosch', total: 1 }];
  const deviceServiceStats = [{ device: 'Çamaşır Makinası', total: 3 }, { device: 'Televizyon', total: 5 }, { device: 'Buz Dolabı', total: 2 }, { device: 'Fırın', total: 2 }, { device: 'Ocak', total: 2 }];
  const sourceServiceStats = [{ source: '444 3808', total: 6 }, { source: '444 2356', total: 4 }, { source: '444 5808', total: 4 }];
  const operatorServiceStats = [{ operator: 'İsa Talha Mengi', total: 8 }, { operator: 'Furkan Erhan', total: 6 }];
  const tableNameTranslator = {
    brand: 'Marka',
    device: 'Cihaz',
    source: 'Kaynak',
    operator: 'Operatör'
  };
  const serviceDataNames = ['Marka Tablosu', 'Cihaz Tablosu', 'Kaynak Tablosu', 'Operatör Tablosu'];
  const [selectedServiceDatas, setSelectedServiceDatas] = useState([]);
  const [selectedServiceDataName, setSelectedServiceDataName] = useState('Marka Tablosu');
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const findServiceData = (name) => {
    if (name === 'Marka Tablosu') {
      return brandServiceStats;
    } else if (name === 'Cihaz Tablosu') {
      return deviceServiceStats;
    } else if (name === 'Kaynak Tablosu') {
      return sourceServiceStats;
    } else if (name === 'Operatör Tablosu') {
      return operatorServiceStats;
    } else {
      return brandServiceStats;
    }
  }
  useEffect(() => {
    setSelectedServiceData(findServiceData(selectedServiceDataName));
  }, [selectedServiceDataName]);
  // Employee Data
  const usersStats = [
    { user: 'İsa Talha Mengi', appointed: 10, completed: 9, complainant: 0, canceled: 1, expected: 0, payment: 5000, offers: 3000 },
    { user: 'Furkan', appointed: 8, completed: 8, complainant: 0, canceled: 0, expected: 0, payment: 4000, offers: 2500 }
  ];
  const userStatsColumns = [{ value: 'user', name: 'TEKNİSYEN' }, { value: 'appointed', name: 'ATANAN SERVİS' }, { value: 'completed', name: 'TAMAMLANAN SERVİS' }, { value: 'complainant', name: 'ŞİKAYETÇİ SERVİS' }, { value: 'canceled', name: 'İPTAL SERVİS' }, { value: 'expected', name: 'HABER VERECEK' }, { value: 'payment', name: 'ALINAN ÜCRET' }, { value: 'offers', name: 'VERİLEN TEKLİFLER' }]
  const usersCharts = ['appointed', 'completed', 'complainant', 'canceled', 'expected', 'payment', 'offers'];
  const usersChartsTranslations = { appointed: 'Atanan Servis', completed: 'Tamamlanan Servis', complainant: 'Şikayetçi Servis', canceled: 'İptal Servis', expected: 'Haber Verecek', payment: 'Alınan Ücret', offers: 'Verilen Teklifler' };
  const usersChartsCurrencies = { appointed: 'Adet', completed: 'Adet', complainant: 'Adet', canceled: 'Adet', expected: 'Adet', payment: 'TL', offers: 'TL' }
  const [selectedUsersCharts, setSelectedUsersCharts] = useState([]);
  // Opertor Data
  const operatorStats = [{ user: 'İsa Talha Mengi', total: 10 }, { user: 'Furkan Erhan', total: 8 }];
  // Checkout Data
  const incomes = [{ method: 'Nakit', payment: 4000 }, { method: 'EFT / Havale', payment: 3000 }, { method: 'Kredi Kartı', payment: 6000 }];
  const outcomes = [{ operation: 'Depo Stok', payment: 3000 }, { operation: 'Servis Parça', payment: 1000 }, { operation: 'Araç Bakım', payment: 0 }, { operation: 'Kira', payment: 0 }, { operation: 'Diğer / Muhtelif', payment: 1000 }];
  const [selectedCheckoutTableName, setSelectedCheckoutTableName] = useState('Gelirler Tablosu');
  const [selectedCheckoutTable, setSelectedCheckoutTable] = useState([]);
  useEffect(() => {
    if (selectedCheckoutTableName === 'Gelirler Tablosu') {
      setSelectedCheckoutTable(incomes);
    } else {
      setSelectedCheckoutTable(outcomes);
    }
  }, [selectedCheckoutTableName])
  // Status Data
  const statusStats = [{ operation: 'Servisi Sonlandır', total: 10 }, { operation: 'Haber Verecek', total: 8 }, { operation: 'Müşteri İptal Etti', total: 8 }, { operation: 'Bayiye Gönder', total: 8 }, { operation: 'Yeni Servisler', total: 6 }];
  // Stage Data
  const stageStats = [{ operation: 'Servisi Sonlandır', total: 10 }, { operation: 'Haber Verecek', total: 8 }, { operation: 'Müşteri İptal Etti', total: 8 }, { operation: 'Bayiye Gönder', total: 8 }, { operation: 'Yeni Servisler', total: 6 }];
  // Warehouse Data
  const warehouseStats = [{ user: 'İsa Talha Mengi', total: 10 }, { user: 'Furkan Erhan', total: 8 }];
  // District Data
  const districtsStats = [{ district: 'Beylikdüzü', total: 10 }, { district: 'Zeytinburnu', total: 8 }];
  // HTML
  return (
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mt-4'>
            <div className='card-header'>
              <div className='row'>
                <div className='col-12 col-md-10'>
                  <div className='d-flex align-items-center h-100'>
                    <h5 className="card-title mb-0">İstatistikler</h5>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <button className='button-with-icom btn btn-primary w-100'><TbPrinter className='button-icon' />Detaylı İstatistik</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <Tabs defaultActiveKey="service-statistics" fill>
                <Tab eventKey="service-statistics" title='Servis İst.'>
                  {showChart ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='d-flex align-items-center h-100'>
                              <div className='card-title p-0 m-0'>Servis İstatistik Grafikleri</div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <Autocomplete
                              id='selectedServiceDatas'
                              name='selectedServiceDatas'
                              multiple
                              limitTags={2}
                              options={serviceDataNames}
                              size='small'
                              disableCloseOnSelect
                              getOptionLabel={(option) => option}
                              value={selectedServiceDatas || []}
                              onChange={(event, newValue) => { setSelectedServiceDatas(newValue); }}
                              renderTags={(selected, getTagProps) =>
                                selected.map((option, index) => {
                                  const { key, ...tagProps } = getTagProps({ index });
                                  return (
                                    <Chip
                                      key={option}
                                      label={option}
                                      size='small'
                                      {...tagProps}
                                      deleteIcon={
                                        <TiDelete
                                          type='button'
                                          onMouseDown={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            const newValues = selectedServiceDatas.filter((i) => i !== option);
                                            setSelectedServiceDatas(newValues);
                                          }}
                                        />
                                      }
                                    />
                                  );
                                })
                              }
                              renderInput={(params) => <TextField {...params} label="Gösterilecek Veriler" />}
                            />
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row g-2'>
                          {selectedServiceDatas.length === 0 ? (
                            <>
                              {serviceDataNames.map((chart, index) => {
                                const chartdata = findServiceData(chart);
                                return (
                                  <div key={index} className='col-12 col-md-6'>
                                    <h5>{chart}</h5>
                                    <PieChart data={chartdata.map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                                  </div>
                                )
                              })}
                            </>
                          ) : selectedServiceDatas.length === 1 ? (
                            <div className='col-12'>
                              <h5>{selectedServiceDatas[0]}</h5>
                              <PieChart data={findServiceData(selectedServiceDatas[0]).map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                            </div>
                          ) : selectedServiceDatas.length > 1 && (
                            <>
                              {selectedServiceDatas.map((chart, index) => {
                                const chartdata = findServiceData(chart);
                                return (
                                  <div key={index} className='col-12 col-md-6'>
                                    <h5>{chart}</h5>
                                    <PieChart data={chartdata.map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='d-flex align-items-center h-100'>
                              <div className='card-title p-0 m-0'>Servis İstatistik Grafikleri</div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <Autocomplete
                              value={selectedServiceDataName}
                              onChange={(event, newValue) => { newValue ? setSelectedServiceDataName(newValue) : setSelectedServiceDataName('Marka Tablosu') }}
                              variant='outlined'
                              size='small'
                              options={serviceDataNames}
                              renderInput={(params) => <TextField {...params} label="Gösterilecek Veri" />}
                            />
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={selectedServiceData}
                          rows={10}
                          columns={selectedServiceData.length > 0 ? [{ value: Object.keys(selectedServiceData[0])[0], name: tableNameTranslator[Object.keys(selectedServiceData[0])[0]] }, { value: 'total', name: 'Toplam' }] : []}
                          filternames={selectedServiceData.length > 0 ? [Object.keys(selectedServiceData[0])[0], 'total'] : []}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="employee-statistics" title='Teknisyen İst.'>
                  {showChart ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='d-flex align-items-center h-100'>
                              <div className='card-title p-0 m-0'>Teknisyen İstatistik Grafiği</div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <Autocomplete
                              id='selectedUsersCharts'
                              name='selectedUsersCharts'
                              multiple
                              limitTags={2}
                              options={usersCharts}
                              size='small'
                              disableCloseOnSelect
                              getOptionLabel={(option) => usersChartsTranslations[option] || option}
                              value={selectedUsersCharts || []}
                              onChange={(event, newValue) => { setSelectedUsersCharts(newValue); }}
                              renderTags={(selected, getTagProps) =>
                                selected.map((option, index) => {
                                  const { key, ...tagProps } = getTagProps({ index });
                                  return (
                                    <Chip
                                      key={option}
                                      label={usersChartsTranslations[option] || option}
                                      size='small'
                                      {...tagProps}
                                      deleteIcon={
                                        <TiDelete
                                          type='button'
                                          onMouseDown={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            const newValues = selectedUsersCharts.filter((i) => i !== option);
                                            setSelectedUsersCharts(newValues);
                                          }}
                                        />
                                      }
                                    />
                                  );
                                })
                              }
                              renderInput={(params) => <TextField {...params} label="Gösterilecek Veriler" />}
                            />

                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row g-2'>
                          {selectedUsersCharts.length === 0 ? (
                            <>
                              {usersCharts.map((chart, index) => (
                                <div key={index} className='col-12 col-md-6'>
                                  <h5>{usersChartsTranslations[chart]}</h5>
                                  <PieChart data={usersStats.map(item => ({ name: item.user, value: item[chart] }))} unit={usersChartsCurrencies[chart]} />
                                </div>
                              ))}
                            </>
                          ) : selectedUsersCharts.length === 1 ? (
                            <div className='col-12'>
                              <h5>{usersChartsTranslations[selectedUsersCharts[0]]}</h5>
                              <PieChart data={usersStats.map(item => ({ name: item.user, value: item[selectedUsersCharts[0]] }))} unit={usersChartsCurrencies[selectedUsersCharts[0]]} />
                            </div>
                          ) : selectedUsersCharts.length > 1 && (
                            <>
                              {selectedUsersCharts.map((chart, index) => (
                                <div key={index} className='col-12 col-md-6'>
                                  <h5>{usersChartsTranslations[chart]}</h5>
                                  <PieChart data={usersStats.map(item => ({ name: item.user, value: item[chart] }))} unit={usersChartsCurrencies[chart]} />
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='d-flex align-items-center h-100'>
                              <div className='card-title p-0 m-0'>Teknisyen İstatistik Tablosu</div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={usersStats.map(item => ({ ...item, payment: stringMoney(item.payment, 'TL'), offers: stringMoney(item.offers, 'TL') }))}
                          rows={10}
                          columns={userStatsColumns}
                          filternames={['user']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="operator-statistics" title='Operatör İst.'>
                  {showChart === true ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Operatör İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <PieChart data={operatorStats.map(item => ({ name: item.user, value: item.total }))} unit='Adet' />
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Operatör İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={operatorStats}
                          rows={10}
                          columns={[{ value: 'user', name: 'OPERATÖR' }, { value: 'total', name: 'TOPLAM' }]}
                          filternames={['user']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="checkout-statistics" title='Kasa İst.'>
                  {showChart === true ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Kasa İstatistik Grafikleri</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-6'>
                            <h4>Gelirler</h4>
                            <PieChart data={incomes.map(item => ({ name: item.method, value: item.payment }))} unit='TL' />
                          </div>
                          <div className='col-12 col-md-6'>
                            <h4>Giderler</h4>
                            <PieChart data={outcomes.map(item => ({ name: item.operation, value: item.payment }))} unit='TL' />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='d-flex align-items-center h-100'>
                              <div className='card-title p-0 m-0'>Kasa İstatistik Tabloları</div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <Autocomplete
                              value={selectedCheckoutTableName}
                              onChange={(event, newValue) => { newValue ? setSelectedCheckoutTableName(newValue) : setSelectedCheckoutTableName('Gelirler Tablosu') }}
                              variant='outlined'
                              size='small'
                              options={['Gelirler Tablosu', 'Giderler Tablosu']}
                              renderInput={(params) => <TextField {...params} label="Gösterilecek Veri" />}
                            />
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={selectedCheckoutTable}
                          rows={10}
                          columns={selectedCheckoutTableName === 'Gelirler Tablosu' ? [{ value: 'method', name: 'GELİR' }, { value: 'payment', name: 'TUTAR' }] : [{ value: 'operation', name: 'İŞLEM' }, { value: 'payment', name: 'TUTAR' }]}
                          filternames={selectedCheckoutTableName === 'Gelirler Tablosu' ? ['method'] : ['operation']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="status-statistics" title='Durum İst.'>
                  {showChart ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Durum İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <PieChart data={statusStats.map(item => ({ name: item.operation, value: item.total }))} unit='Adet' />
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Durum İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={statusStats}
                          rows={10}
                          columns={[{ value: 'operation', name: 'İŞLEM' }, { value: 'total', name: 'TOPLAM' }]}
                          filternames={['operation']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                          buttons={(rowData) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-secondary' onClick={() => openNewPage('servisler', { search: rowData.operation || '' })}>Servisleri Göster</button>
                              </div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="stage-statistics" title='Aşama İst.'>
                  {showChart ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Aşama İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <PieChart data={stageStats.map(item => ({ name: item.operation, value: item.total }))} unit='Adet' />
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Aşama İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={stageStats}
                          rows={10}
                          columns={[{ value: 'operation', name: 'İŞLEM' }, { value: 'total', name: 'TOPLAM' }]}
                          filternames={['operation']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                          buttons={(rowData) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-secondary' onClick={() => openNewPage('servisler', { search: rowData.operation || '' })}>Servisleri Göster</button>
                              </div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="warehouse-statistics" title='Depo İst.'>
                  {showChart === true ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Depo İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <PieChart data={warehouseStats.map(item => ({ name: item.user, value: item.total }))} unit='Adet' />
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>Depo İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={warehouseStats}
                          rows={10}
                          columns={[{ value: 'user', name: 'PERSONEL' }, { value: 'total', name: 'TOPLAM' }]}
                          filternames={['user']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                          buttons={(rowData) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-secondary' onClick={() => openNewPage('servisler', { search: rowData.user || '' })}>Servisleri Göster</button>
                              </div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="district-statistics" title='İlçe İst.'>
                  {showChart === true ? (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>İlçe İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <PieChart data={districtsStats.map(item => ({ name: item.district, value: item.total }))} unit='Adet' />
                      </div>
                    </div>
                  ) : (
                    <div className='card bg-light m-2'>
                      <div className='card-header p-2 m-0'>
                        <div className='row g-2'>
                          <div className='col-12 col-md-4'>
                            <div className='card-title align-middle p-0 m-0'>İlçe İstatistik Grafiği</div>
                          </div>
                          <div className='col-12 col-md-6'>
                          </div>
                          <div className='col-12 col-md-2'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                              <SwitchButton checked={showChart} change={() => setShowChart(!showChart)} labelOn='Grafik' labelOff='Tablo' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Table
                          data={districtsStats}
                          rows={10}
                          columns={[{ value: 'district', name: 'İLÇE' }, { value: 'total', name: 'TOPLAM' }]}
                          filternames={['district']}
                          dates={(startDate, endDate) => { setStartDate(startDate); setEndDate(endDate); }}
                          paginatorColor='bg-light'
                          headerAlign='center'
                          buttons={(rowData) => {
                            return (
                              <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-secondary' onClick={() => openNewPage('servisler', { search: rowData.district || '' })}>Servisleri Göster</button>
                              </div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;