// General
import React, { useCallback, useEffect, useState } from 'react';
// Storage
import { useDispatch } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TbPrinter, TbUserPlus, } from "react-icons/tb";
import { MdOutlineWarehouse } from "react-icons/md";
import { LuArrowRightToLine } from "react-icons/lu";
import { RiFolderSettingsLine, RiFolderInfoLine } from "react-icons/ri";
import { FiFolderMinus } from "react-icons/fi";
import { AiOutlineProduct } from "react-icons/ai";
//Modal
import WarehouseAddModal from '../modals/warehouse/WarehouseAddModal';
import WarehouseUpdateModal from '../modals/warehouse/WarehouseUpdateModal';
import DeleteModal from '../modals/DeleteModal';
import WarehouseSupplierAddModal from '../modals/warehouse/WarehouseSupplierAddModal';
import WarehouseStoreAddModal from '../modals/warehouse/WarehouseStoreAddModal';
import WarehouseShelfAddModal from '../modals/warehouse/WarehouseShelfAddModal';
import WarehouseDetailModal from '../modals/warehouse/WarehouseDetailModal';
// Component
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';

function Warehouse() {
  // Storage
  const dispatch = useDispatch();
  // Context Import

  // Filtering
  const [filterStartDate, setFilterStartDate] = useState(new Date().toLocaleDateString());
  const [filterEndDate, setFilterEndDate] = useState(new Date().toLocaleDateString());
  const filters = ['id', 'warehouseName', 'shelfName', 'Date', 'productName', 'barcode', 'price', 'piece', 'brand', 'device'];
  const handleSetDates = (startDate, endDate) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const data = [
    { id: 1001, warehouseName: 'Teknik Servis', shelfName: 'Raf 1', Date: '12.07.2024', productName: 'Buzdolabı', barcode: '1020039596', price: '10.000 TL', piece: '2', brand: 'Vestel', device: 'Mobil', productCode: '1020120212', supplier: 'Emir ', purchasePrice: '200000', salePrice: '400000', dolarPrice: '1000', explanation: 'Ürün yeni' }, { id: 1002, warehouseName: 'Teknik Servis', shelfName: 'Raf 2', Date: '12.05.2024', productName: 'Fırın', barcode: '5020039592', price: '15.000 TL', piece: '3', brand: 'Vestel', device: 'Mobil', productCode: '1020120212', supplier: 'Emir', purchasePrice: '200000', salePrice: '300000', dolarPrice: '100000', explanation: 'Ürün yeni' }, { id: 1003, warehouseName: 'Teknik Servis', shelfName: 'Raf 3', Date: '12.01.2024', productName: 'Fırın', barcode: '5020039592', price: '15.000 TL', piece: '3', brand: 'Vestel', device: 'Mobil', productCode: '1203123454', supplier: 'Emir', purchasePrice: '200000', salePrice: '400000', dolarPrice: '3000', explanation: 'Ürün yeni' }];
  useEffect(() => {
    const toTable = data.map((item) => {
      const id = item.id;
      const warehouseName = item.warehouseName;
      const shelfName = item.shelfName;
      const Date = item.Date;
      const productName = item.productName;
      const barcode = item.barcode;
      const price = item.price;
      const piece = item.piece;
      const brand = item.brand;
      const device = item.device;
      return { id: id, warehouseName: warehouseName, shelfName: shelfName, Date: Date, productName: productName, barcode: barcode, price: price, piece: piece, brand: brand, device: device };
    });
    setDataToTable(toTable);
  }, [filterStartDate, filterEndDate]);
  // Table Content
  const rowsPerPage = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'warehouseName', name: 'DEPO ADI' }, { value: 'shelfName', name: 'RAF ADI' }, { value: 'Date', name: 'TARİH' }, { value: 'productName', name: 'ÜRÜN ADI' }, { value: 'barcode', name: 'BARKOD' }, { value: 'price', name: 'FİYAT' }, { value: 'piece', name: 'ADET' }, { value: 'brand', name: 'MARKA' }, { value: 'device', name: 'CİHAZ' }];
  const handleSelectedFunction = (id) => {
    handleShowWarehouseUpdateModal(data.find(d => d.id === id));
  }
  const tableButtons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowWarehouseUpdateModal(data.find(d => d.id === rowData.id)); }}><RiFolderSettingsLine className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-warning mx-1" onClick={(e) => { e.stopPropagation(); handleShowWarehouseDetailsModal(data.find(d => d.id === rowData.id)); }}><RiFolderInfoLine className='button-icon' />Detay</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowWarehouseDeleteModal(rowData.id); }}><FiFolderMinus className='button-icon' />Sil</button>
      </div >
    );
  };
  const table = () => {
    return (
      <Table
        data={dataToTable}
        rows={rowsPerPage}
        columns={cols}
        filternames={filters}
        select={handleSelectedFunction}
        dates={handleSetDates}
        buttons={tableButtons}
      />
    );
  }
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowWarehouseAddModal(); }} className="dropdown-item"><AiOutlineProduct style={{ fontSize: '1.5em' }} /> Ürün Ekle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowWarehouseSupplierAddModal(); }} className="dropdown-item"><TbUserPlus style={{ fontSize: '1.5em' }} /> Tedarikçi Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); handleShowWarehouseNameAddModal(); }} className="dropdown-item"><MdOutlineWarehouse style={{ fontSize: '1.5em' }} /> Depo Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); handleShowWarehouseShelfAddModal(); }} className="dropdown-item"><LuArrowRightToLine style={{ fontSize: '1.5em' }} /> Raf Ekle</button>
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rowsPerPage, data: data, cols: cols, pdftitle: "Depolar" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Warehouse Add Modal
  const [showWarehouseAddModal, setShowWarehouseAddModal] = useState(false);
  const handleCloseWarehouseAddModal = () => { setShowWarehouseAddModal(false) };
  const handleShowWarehouseAddModal = () => { setShowWarehouseAddModal(true) };
  const handleAddWarehouseAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Depo Eklendi!', delay: 6000 }));
    handleCloseWarehouseAddModal();
  };
  // Warehouse_Supplier Add Modal
  const [showWarehouseSupplierAddModal, setShowWarehouseSupplierAddModal] = useState(false);
  const handleCloseWarehouseSupplierAddModal = () => { setShowWarehouseSupplierAddModal(false) };
  const handleShowWarehouseSupplierAddModal = () => { setShowWarehouseSupplierAddModal(true) };
  const handleAddWarehouseSupplierAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Tedarikçi Eklendi!', delay: 6000 }));
    handleCloseWarehouseSupplierAddModal();
  };
  // Warehouse_StoreName Add Modal
  const [showWarehouseNameAddModal, setShowWarehouseNameAddModal] = useState(false);
  const handleCloseWarehouseNameAddModal = () => { setShowWarehouseNameAddModal(false) };
  const handleShowWarehouseNameAddModal = () => { setShowWarehouseNameAddModal(true) };
  const handleAddWarehouseNameAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Depo Eklendi!', delay: 6000 }));
    handleCloseWarehouseNameAddModal();
  };
  // Warehouse_Shelf Add Modal
  const [showWarehouseShelfAddModal, setShowWarehouseShelfAddModal] = useState(false);
  const handleCloseWarehouseShelfAddModal = () => { setShowWarehouseShelfAddModal(false) };
  const handleShowWarehouseShelfAddModal = () => { setShowWarehouseShelfAddModal(true) };
  const handleAddWarehouseShelfAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Raf Eklendi!', delay: 6000 }));
    handleCloseWarehouseShelfAddModal();
  };
  // Warehouse Update Modal
  const [showWarehouseUpdateModal, setShowWarehouseUpdateModal] = useState(false);
  const [inputDataForWarehouseUpdateModal, setInputDataForWarehouseUpdateModal] = useState({});
  const handleCloseWarehouseUpdateModal = () => {
    setShowWarehouseUpdateModal(false)
    setInputDataForWarehouseUpdateModal({});
  };
  const handleShowWarehouseUpdateModal = (data) => {
    setInputDataForWarehouseUpdateModal(data);
    setShowWarehouseUpdateModal(true);
  };
  const handleUpdateWarehouseUpdateModal = (values) => {
    console.log('Warehouse güncellendi: ', inputDataForWarehouseUpdateModal, ' --> ', values);
    dispatch(addToast({ background: 'primary', icon: 'packageUpdate', message: 'Depo Güncellendi!', delay: 6000 }));
    handleCloseWarehouseUpdateModal();
  };
  // Warehouse Details Modal
  const [showWarehouseDetailsModal, setShowWarehouseDetailsModal] = useState(false);
  const [inputDataForWarehouseDetailsModal, setInputDataForWarehouseDetailsModal] = useState({});
  const handleCloseWarehouseDetailsModal = () => {
    setShowWarehouseDetailsModal(false)
    setInputDataForWarehouseDetailsModal({ id: -1, customerId: -1 });
  };
  const handleShowWarehouseDetailsModal = (data) => {
    setInputDataForWarehouseDetailsModal(data);
    setShowWarehouseDetailsModal(true);
  };
  // Warehouse Delete Modal
  const [showWarehouseDeleteModal, setShowWarehouseDeleteModal] = useState(false);
  const [inputDataForWarehouseDeleteModal, setInputDataForWarehouseDeleteModal] = useState(-1);
  const handleCloseWarehouseDeleteModal = () => {
    setShowWarehouseDeleteModal(false)
    setInputDataForWarehouseDeleteModal(-1);
  };
  const handleShowWarehouseDeleteModal = (data) => {
    setInputDataForWarehouseDeleteModal(data);
    setShowWarehouseDeleteModal(true);
  };
  const handleDeleteWarehouseDeleteModal = (values) => {
    console.log(values, ' id numaralı Personel silindi.');
    dispatch(addToast({ background: 'danger', icon: 'packageDelete', message: 'Depo Silindi!', delay: 6000 }));
    handleCloseWarehouseDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <WarehouseSupplierAddModal
          show={showWarehouseSupplierAddModal}
          handleClose={handleCloseWarehouseSupplierAddModal}
          handleAdd={handleAddWarehouseSupplierAddModal}>
        </WarehouseSupplierAddModal>
        <WarehouseShelfAddModal
          show={showWarehouseShelfAddModal}
          handleClose={handleCloseWarehouseShelfAddModal}
          handleAdd={handleAddWarehouseShelfAddModal}>
        </WarehouseShelfAddModal>
        <WarehouseStoreAddModal
          show={showWarehouseNameAddModal}
          handleClose={handleCloseWarehouseNameAddModal}
          handleAdd={handleAddWarehouseNameAddModal}>
        </WarehouseStoreAddModal>
        <WarehouseAddModal
          show={showWarehouseAddModal}
          handleClose={handleCloseWarehouseAddModal}
          handleAdd={handleAddWarehouseAddModal}>
        </WarehouseAddModal>
        <WarehouseUpdateModal
          show={showWarehouseUpdateModal}
          handleClose={handleCloseWarehouseUpdateModal}
          handleUpdate={handleUpdateWarehouseUpdateModal}
          inputData={inputDataForWarehouseUpdateModal}>
        </WarehouseUpdateModal>
        <WarehouseDetailModal
          show={showWarehouseDetailsModal}
          handleClose={handleCloseWarehouseDetailsModal}
          inputData={inputDataForWarehouseDetailsModal}>
        </WarehouseDetailModal>
        <DeleteModal
          show={showWarehouseDeleteModal}
          handleClose={handleCloseWarehouseDeleteModal}
          handleDelete={handleDeleteWarehouseDeleteModal}
          title="Depo"
          message={inputDataForWarehouseDeleteModal + " " + "numaralı depoyu silmek istediğinize eminmisiniz"}
          deleteIcon={<AiOutlineProduct className='button-icon' />}
        >
        </DeleteModal>
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='Depo' Operations={operations} Table={table} Modals={modals} />
  );
}

export default Warehouse;