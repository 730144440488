// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { LuArrowRightToLine } from "react-icons/lu";

function WarehouseShelfAddModal({ show, handleClose, handleAdd }) {
  // Context Import
  const { validationSchema_WarehouseShelf } = useValidations();
  // Variables
  const warehousenames = ['Depo 1', 'Depo 2', 'Depo 3'];
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Yeni Raf Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_WarehouseShelf} initialValues={{ shelfName: '', warehouseName: 'Depo 1' }} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="WarehouseShelfAddModal_warehouseName"
                      name="warehouseName"
                      options={warehousenames.map((option) => option)}
                      value={values.warehouseName}
                      onChange={(event, newValue) => { setFieldValue('warehouseName', newValue); }}
                      onBlur={() => { setFieldTouched('warehouseName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Depo *" error={touched.warehouseName && !!errors.warehouseName}
                        helperText={touched.warehouseName && errors.warehouseName} onFocus={() => {
                          setFieldTouched('warehouseName', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='WarehouseShelfAddModal_shelfName'
                      name='shelfName'
                      label='Raf Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.shelfName && !!errors.shelfName}
                      helperText={touched.shelfName && errors.shelfName}
                      onFocus={() => { setFieldTouched('shelfName', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><LuArrowRightToLine className='button-icon' />Raf Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default WarehouseShelfAddModal