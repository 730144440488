// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Employees/';

// Async Thunks
export const getAllEmployees = createAsyncThunk('backend_getallemployees', async () => {
  try {
    const res = await axios.get(`${baseurl}GetAllEmployees`, { withCredentials: true });
    console.log(res);
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getAllEmployeesByDate = createAsyncThunk('backend_getallemployeesbydate', async ({ startdate, enddate }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllEmployeesByDate?start==${startdate}&end=${enddate}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getEmployeeById = createAsyncThunk('backend_getemployeebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetUserById?id=${id}`, { withCredentials: true });
    return { ...res.data?.data, date: res.data?.data.date.slice(0, 10) };
  } catch (error) { throw error.response.data || error; }
});
export const addNewEmployee = createAsyncThunk('backend_addnewemployee', async ({ newemployee }) => {
  try {
    const res = await axios.post(`${baseurl}AddEmployee`, newemployee, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateEmployee = createAsyncThunk('backend_updateemployee', async ({ employee }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateEmployee`, employee, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteEmployee = createAsyncThunk('backend_deleteemployee', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteEmployee?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    employees: [],
    employee: {},
    getAllStatus: null,
    getByDatesStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllEmployees.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.employees = action.payload; })
      .addCase(getAllEmployees.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllEmployeesByDate.pending, (state) => { state.getByDatesStatus = "pending"; })
      .addCase(getAllEmployeesByDate.fulfilled, (state, action) => { state.getByDatesStatus = "fulfilled"; state.employees = action.payload; })
      .addCase(getAllEmployeesByDate.rejected, (state, action) => { state.getByDatesStatus = "rejected"; state.error = action.error.message; })
      .addCase(getEmployeeById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getEmployeeById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.employee = action.payload; })
      .addCase(getEmployeeById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewEmployee.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewEmployee.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewEmployee.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateEmployee.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateEmployee.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateEmployee.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteEmployee.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteEmployee.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteEmployee.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetGetAllStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = employeeSlice.actions;
export default employeeSlice.reducer;