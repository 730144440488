// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Brands/';

// Async Thunks
export const getAllBrands = createAsyncThunk('backend_getallbrands', async () => {
  try {
    const res = await axios.get(`${baseurl}GetAllBrands`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getBrandById = createAsyncThunk('backend_getbrandbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetBrandById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewBrand = createAsyncThunk('backend_addnewbrand', async ({ newbrand }) => {
  try {
    const res = await axios.post(`${baseurl}AddBrand`, newbrand, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateBrand = createAsyncThunk('backend_updatebrand', async ({ brand }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateBrand`, brand, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteBrand = createAsyncThunk('backend_deletebrand', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteBrand?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    brands: [],
    brand: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBrands.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllBrands.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.brands = action.payload; })
      .addCase(getAllBrands.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getBrandById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getBrandById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.brand = action.payload; })
      .addCase(getBrandById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewBrand.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewBrand.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewBrand.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateBrand.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateBrand.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateBrand.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteBrand.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteBrand.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteBrand.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus } = brandSlice.actions;
export default brandSlice.reducer;