// General
import { createContext, useContext } from 'react';
import * as Yup from 'yup';

const ValidationsContext = createContext();

const ValidationsProvider = ({ children }) => {
  // Validations
  // Services
  const validationSchema_Service = Yup.object().shape({
    name: Yup.string().required('Lütfen bir müşteri adı giriniz!'),
    surname: Yup.string().required('Lütfen bir müşteri soyadı giriniz!'),
    type: Yup.string().required('Lütfen bir müşteri türü seçiniz!'),
    identityNo: Yup.string().when('type', { is: ((value) => value === 'Bireysel'), then: (schema) => schema.length(11, 'Kimlik numarası 11 haneli olmalı') }),
    taxNo: Yup.string().when('type', { is: ((value) => value === 'Kurumsal'), then: (schema) => schema.required('Lütfen vergi numarasını giriniz.').length(10, 'Vergi numarası 10 haneli olmalı') }),
    taxOffice: Yup.string().when('type', { is: ((value) => value === 'Kurumsal'), then: (schema) => schema.required('Lütfen vergi dairesini giriniz.') }),
    phone1: Yup.string().required('Lütfen bir telefon numarası giriniz!').length(10, 'Lütfen en az 10 rakamdan oluşan bir telefon numarası giriniz.'),
    phone2: Yup.string().length(10, 'Lütfen en az 10 rakamdan oluşan bir telefon numarası giriniz.'),
    addressCity: Yup.string().required('Lütfen müşteriye ait adres bilgisini eksiksiz doldurunuz!'),
    addressDistrict: Yup.string().required('Lütfen müşteriye ait adres bilgisini eksiksiz doldurunuz!'),
    addressText: Yup.string().required('Lütfen müşteriye ait adres bilgisini eksiksiz doldurunuz!'),
    availableDate: Yup.string().required('Lütfen müşterinin müsait olduğu bir gün seçiniz!'),
    availableStartTime: Yup.string().required('Lütfen müşterinin müsait olduğu bir saat aralığı seçiniz!'),
    availableEndTime: Yup.string().required('Lütfen müşterinin müsait olduğu bir saat aralığı seçiniz!'),
    brand: Yup.string().required('Lütfen bir cihaz markası seçiniz!'),
    device: Yup.string().required('Lütfen bir cihaz türü seçiniz!'),
    model: Yup.string().required('Lütfen cihaz modelini giriniz!'),
    problem: Yup.string().required('Lütfen cihaz problemini giriniz!'),
    source: Yup.string().required('Lütfen bir kaynak seçiniz!'),
  });
  const validationSchema_ServiceIncomingCalls = Yup.object().shape({
    source: Yup.string().required('Lütfen bir kaynak seçiniz!'),
    brand: Yup.string().required('Lütfen bir marka seçiniz!'),
    desc: Yup.string().required('Lütfen bir açıklama giriniz!'),
  });
  const validationSchema_ServiceNote = Yup.object().shape({
    note: Yup.string().required('Not boş bırakılamaz!'),
  });
  // Checkout
  const validationSchema_Checkout = Yup.object().shape({
    date: Yup.string().required('Lütfen bir tarih seçiniz!'),
    time: Yup.string().required('Lütfen bir saat seçiniz!'),
    direction: Yup.string().required('Lütfen bir ödeme yönü seçiniz!'),
    method: Yup.string().required('Lütfen bir ödeme şekli seçiniz!'),
    type: Yup.string().required('Lütfen bir ödeme türü seçiniz!'),
    status: Yup.string().required('Lütfen bir ödeme durumu seçiniz!'),
    amount: Yup.string().required('Lütfen bir tutar giriniz!'),
    currency: Yup.string().required('Lütfen bir birim seçiniz!'),
    supplier: Yup.string().when('type', { is: ((value) => value === 'Depo Stok'), then: (schema) => schema.required('Lütfen bir tedarikçi seçiniz.') }),
    employee: Yup.string().when('type', { is: ((value) => value === 'Servis Para' || value === 'Servis Parça' || value === 'Müşteri İade' || value === 'Maaş' || value === 'Prim'), then: (schema) => schema.required('Lütfen bir tedarikçi seçiniz.') }),
    serviceNo: Yup.string().when('type', { is: ((value) => value === 'Servis Para' || value === 'Servis Parça' || value === 'Müşteri İade'), then: (schema) => schema.required('Lütfen bir tedarikçi seçiniz.') }),
    brand: Yup.string().when('type', { is: ((value) => value === 'Servis Para' || value === 'Servis Parça' || value === 'Müşteri İade'), then: (schema) => schema.required('Lütfen bir tedarikçi seçiniz.') }),
    device: Yup.string().when('type', { is: ((value) => value === 'Servis Para' || value === 'Servis Parça' || value === 'Müşteri İade'), then: (schema) => schema.required('Lütfen bir tedarikçi seçiniz.') }),
  });
  // Customer
  const validationSchema_Customer = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s].*$/, 'Müşteri adı boşluk ile başlayamaz!')
      .min(3, 'Müşteri adı en az 3 karakterden oluşmalıdır!')
      .max(30, 'Müşteri adı en fazla 30 karakterden oluşabilir!'),
    phone1: Yup.string().required('Lütfen bir telefon giriniz!'),
    city: Yup.string().required('Lütfen bir il giriniz!'),
    district: Yup.string().required('Lütfen bir ilçe giriniz!'),
    address: Yup.string().required('Lütfen bir adres giriniz!'),
    customerType: Yup.string().required('Lütfen bir müşteri türü giriniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema.required('Lütfen bir kimlik numarası giriniz!')
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema.required('Lütfen bir vergi numarası giriniz!')
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema.required('Lütfen bir vergi dairesi giriniz!')
      }
      ),
  });
  // Employee
  const validationSchema_EmployeeAdd = Yup.object().shape({
    date: Yup.date().required('Lütfen bir tarih seçiniz!'),
    roleName: Yup.string().required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s].*$/, 'Personel adı boşluk ile başlayamaz!')
      .min(3, 'Personel adı en az 3 karakterden oluşmalıdır!')
      .max(30, 'Personel adı en fazla 30 karakterden oluşabilir!'),
    username: Yup.string()
      .required('Lütfen bir Personel kullanıcı adı giriniz!')
      .matches(/^[^\s].*$/, 'Personel kullanıcı adı boşluk ile başlayamaz!')
      .min(3, 'Personel kullanıcı adı en az 3 karakterden oluşmalıdır!')
      .max(30, 'Personel kullanıcı adı en fazla 30 karakterden oluşabilir!'),
    password: Yup.string()
      .required('Lütfen bir şifre giriniz.')
      .matches(/^\S+(\s*)*\s*$/, 'Şifre boşluk içermemeli!')
      .min(8, 'Şifre en az 8 karakter olmalıdır!')
      .max(20, 'Şifre en fazla 20 karakter olabilir!'),
    phone1: Yup.string().required('Lütfen bir telefon giriniz!'),
    city: Yup.string().required('Lütfen bir il giriniz!'),
    district: Yup.string().required('Lütfen bir ilçe giriniz!'),
    address: Yup.string().required('Lütfen bir adres giriniz!'),
    status: Yup.string().required('Lütfen çalışan türü seçiniz.'),
  });
  const validationSchema_EmployeeUpdate = Yup.object().shape({
    date: Yup.date().required('Lütfen bir tarih seçiniz!'),
    roleName: Yup.string().required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s].*$/, 'Personel adı boşluk ile başlayamaz!')
      .min(3, 'Personel adı en az 3 karakterden oluşmalıdır!')
      .max(30, 'Personel adı en fazla 30 karakterden oluşabilir!'),
    username: Yup.string()
      .required('Lütfen bir Personel kullanıcı adı giriniz!')
      .matches(/^[^\s].*$/, 'Personel kullanıcı adı boşluk ile başlayamaz!')
      .min(3, 'Personel kullanıcı adı en az 3 karakterden oluşmalıdır!')
      .max(30, 'Personel kullanıcı adı en fazla 30 karakterden oluşabilir!'),
    password: Yup.string()
      .matches(/^\S+(\s*)*\s*$/, 'Şifre boşluk içermemeli!')
      .min(8, 'Şifre en az 8 karakter olmalıdır!')
      .max(20, 'Şifre en fazla 20 karakter olabilir!'),
    phone1: Yup.string().required('Lütfen bir telefon giriniz!'),
    city: Yup.string().required('Lütfen bir il giriniz!'),
    district: Yup.string().required('Lütfen bir ilçe giriniz!'),
    address: Yup.string().required('Lütfen bir adres giriniz!'),
    status: Yup.string().required('Lütfen çalışan türü seçiniz.'),
  });
  // Warehouse
  const validationSchema_Warehouse = Yup.object().shape({
    brand: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .min(2, 'marka en az 2 karakter olmalıdır.')
      .max(25, 'marka en fazla 15 karakter olmalıdır.')
      .required('Lütfen marka giriniz'),
    device: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen cihaz giriniz')
      .min(2, 'cihaz en az 2 karakter olmalıdır.')
      .max(25, 'cihaz en fazla 15 karakter olmalıdır.'),
    warehouseName: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen depo adı giriniz')
      .min(2, 'depo en az 2 karakter olmalıdır.')
      .max(25, 'depo en fazla 15 karakter olmalıdır.'),
    shelfName: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen  raf adı giriniz.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(25, 'raf en fazla 15 karakter olmalıdır.'),
    barcode: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .required('Lütfen  barkod giriniz.'),
    productName: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen ürün adı giriniz')
      .min(2, 'Ürün en az 2 karakter olmalıdır.')
      .max(25, 'Ürün en fazla 15 karakter olmalıdır.'),
    supplier: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen tedarikçi giriniz')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ\s]+$/, 'Sadece harf içermelidir.')
      .min(2, 'Tedarikçi en az 2 karakter olmalıdır.')
      .max(25, 'Tedarikçi en fazla 15 karakter olmalıdır.'),
    piece: Yup.number()
      .required('Lütfen adet giriniz.'),
    purchasePrice: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[^0.,]/, '0, "," ve "." ile başlayamaz.')
      .matches(/^\d+([.,]\d+)*$/, ' "." ve "," sadece rakamlar arasında olabilir.')
      .required('Lütfen  alış fiyatı giriniz.'),
    salePrice: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[^0.,]/, '0, "," ve "." ile başlayamaz.')
      .matches(/^\d+([.,]\d+)*$/, ' "." ve "," sadece rakamlar arasında olabilir.')
      .required('Lütfen  satış fiyatı giriniz.'),
    dolarPrice: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[^0.,]/, '0, "," ve "." ile başlayamaz.')
      .matches(/^\d+([.,]\d+)*$/, ' "." ve "," sadece rakamlar arasında olabilir.')
      .required('Lütfen  dolar fiyatı giriniz.'),
    productCode: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .required('Lütfen  ürün kodu giriniz.'),
    explanation: Yup.string()
      .required('Lütfen bir adres giriniz.')
      .matches(/^\S.*$/, 'Açıklama boşluk ile başlayamaz.')
      .min(5, 'Açıklama en az 5 karakter olmalıdır.')
      .max(250, 'Açıklama en fazla 250 karakter olmalıdır.'),
  });
  const validationSchema_WarehouseSupplier = Yup.object().shape({
    name: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen tedarikçi giriniz')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ\s]+$/, 'Sadece harf içermelidir.')
      .min(2, 'Tedarikçi en az 2 karakter olmalıdır.')
      .max(25, 'Tedarikçi en fazla 25 karakter olmalıdır.'),
    status: Yup.string().required('Lütfen bir durum seçiniz'),
  });
  const validationSchema_WarehouseName = Yup.object().shape({
    name: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen depo adı giriniz')
      .min(2, 'depo en az 2 karakter olmalıdır.')
      .max(25, 'depo en fazla 25 karakter olmalıdır.'),
    status: Yup.string().required('Lütfen bir durum seçiniz'),

  });
  const validationSchema_WarehouseShelf = Yup.object().shape({
    warehousename: Yup.string().required('Lütfen bir depo seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir raf adı giriniz!')
      .matches(/^[^\s].*$/, 'Raf adı boşluk ile başlayamaz!')
      .min(2, 'Raf adı en az 3 karakterden oluşmalıdır!')
      .max(25, 'Raf adı en fazla 25 karakterden oluşabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(250, 'Açıklama en fazla 250 karakterden oluşabilir!'),

  });
  // Vehicles 
  const validationSchema_Vehicles = Yup.object().shape({
    plate: Yup.string().required('Lütfen bir araç plakası giriniz!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(250, 'Açıklama en fazla 250 karakterden oluşabilir!'),
  });
  // Roles
  const validationSchema_Roles = Yup.object().shape({
    name: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen  rol  giriniz.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(25, 'raf en fazla 25 karakter olmalıdır.'),
    description: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(250, 'raf en fazla 25 karakter olmalıdır.'),
  });
  // Reminder
  const validationSchema_Reminder = Yup.object().shape({
    time: Yup.string()
      .required('Hatırlatma saati zorunludur')
      .matches(/^([01]\d|2[0-3]).([0-5]\d)$/, 'Geçerli bir saat girin (HH:MM)'),
    date: Yup.date()
      .required('Lütfen hatırlatma tarih seçiniz')
      .min(new Date((new Date().setHours(0, 0, 0, 0))), 'Başlangıç tarihi bugünden önce olamaz'),
    performerOfThetransaction: Yup.array()
      .min(1, 'En az bir kişi seçilmelidir') // Minimum of one selection
      .required('Bu alan zorunludur'),
    remainingTime: Yup.string().required('Lütfen hatırlatma zamanı seçiniz.'),
    repeatTime: Yup.string().required('Lütfen tekrar süresi seçiniz.'),
    status: Yup.string().required('Lütfen bir durum seçiniz.'),
    message: Yup.string()
      .required('Lütfen bir adres giriniz.')
      .matches(/^\S.*$/, 'Adres boşluk ile başlayamaz.')
      .min(5, 'Adres en az 5 karakter olmalıdır.')
      .max(250, 'Adres en fazla 250 karakter olmalıdır.'),

  });
  // Operation Reports
  const validationSchema_OperationReports = Yup.object().shape({
    serviceID: Yup.string()
      .required('Service ID giriniz'),
    problem: Yup.string()
      .required('Lütfen  problem giriniz.')
      .matches(/^\S.*$/, 'problem boşluk ile başlayamaz.')
      .min(5, 'problem en az 5 karakter olmalıdır.')
      .max(250, 'problem en fazla 250 karakter olmalıdır.'),
    operations: Yup.string()
      .required('Lütfen yapılanları giriniz.')
      .matches(/^\S.*$/, 'yapılanlar boşluk ile başlayamaz.')
      .min(5, 'yapılanlar en az 5 karakter olmalıdır.')
      .max(250, 'yapılanlar en fazla 250 karakter olmalıdır.'),
    information: Yup.string()
      .required('Lütfen bilgileri giriniz.')
      .matches(/^\S.*$/, 'bilgiler boşluk ile başlayamaz.')
      .min(5, 'bilgiler en az 5 karakter olmalıdır.')
      .max(250, 'bilgiler en fazla 250 karakter olmalıdır.'),
    cost: Yup.string()
      .matches(/^[^0,.]/, '0, , ve . ile başlayamaz.')
      .matches(/^\d+([,.]\d+)*$/, ', ve . sadece rakamlar arasında olabilir.')
      .required('Bu alan gereklidir'),
  });
  // Offers
  const validationSchema_Offers = Yup.object().shape({
    customerName: Yup.string()
      .required('Bu Alan Zorunlu')
      .matches(/^\S.*$/, 'Boşluk Olmamalı')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ\s]+$/, 'Sadece harf içermelidir.')
      .min(2, 'Alan en az 2 karakter olmalıdır.')
      .max(25, 'Alan en fazla 25 karakter olmalıdır.'),
    customerPhone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz.')
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[1-9]/, 'Telefon numarası 0 ile başlayamaz.')
      .matches(/^\d{10}$/, 'XXX-XXX-XXXX formatında rakamlarla olmalı'),
    customerPhone2: Yup.string()
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[1-9]/, 'Telefon numarası 0 ile başlayamaz.')
      .matches(/^\d{10}$/, 'XXX-XXX-XXXX formatında rakamlarla olmalı'),
    customerType: Yup.string().required('Lütfen bir müşteri türü giriniz!'),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema.required('Lütfen bir vergi numarası giriniz!')
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema.required('Lütfen bir vergi dairesi giriniz!')
      }
      ),
    city: Yup.string().required('Lütfen bir il giriniz!'),
    district: Yup.string().required('Lütfen bir ilçe giriniz!'),
    customerAddress: Yup.string()
      .required('Lütfen bilgileri giriniz.')
      .matches(/^\S.*$/, 'Adres boşluk ile başlayamaz.')
      .min(5, 'Adres en az 5 karakter olmalıdır.')
      .max(250, 'Adres en fazla 250 karakter olmalıdır.'),
    date: Yup.date()
      .required('Lütfen teklif tarihi seçiniz'),
    title: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen teklif başlığı giriniz')
      .min(2, 'başlık en az 2 karakter olmalıdır.')
      .max(25, 'başlık en fazla 25 karakter olmalıdır.'),
    status: Yup.string()
      .required('Bu alan gereklidir'),
    totalText: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ.,\s]*$/, 'Sadece harf, nokta, virgül ve boşluk kullanılabilir.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]/, 'Boşluk olmamalı, Başlangıçta harf kullanılmalı.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+|[\s]+[A-Za-zçğıöşüÇĞİÖŞÜ]+)*\s*$/, 'Nokta veya virgülden sonra harf kullanılmalı')
      .matches(/^(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+)*)(?:\s+(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+))*\s*$/, 'Örnek kullanımlar: Kırkbin TL, Onbin TL')
      .min(2, 'tutar en az 2 karakter olmalıdır.')
      .max(25, 'tutar en fazla 25 karakter olmalıdır.')
      .required('Bu alan gereklidir'),
    currency: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .required('Bu alan gereklidir'),
    kdv: Yup.string()
      .required('Bu alan gereklidir'),
    explanation: Yup.string()
      .matches(/^\S.*$/, 'Açıklama boşluk ile başlayamaz.')
      .min(5, 'Açıklama en az 5 karakter olmalıdır.')
      .max(250, 'Açıklama en fazla 250 karakter olmalıdır.'),
    offerDetails: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
          .required('Lütfen ürün adı giriniz')
          .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ0-9\s]+$/, 'Sadece harf ve rakam içermelidir.')
          .min(2, 'ürün en az 2 karakter olmalıdır.')
          .max(25, 'ürün en fazla 25 karakter olmalıdır.'),
        quantity: Yup.number()
          .typeError('Ürün Miktarı sayısal bir değer olmalıdır')
          .min(0, 'Ürün miktarı 0 veya daha büyük olmalıdır')
          .required('Ürün Miktarı gereklidir'),
        price: Yup.number()
          .typeError('Ürün Fiyatı sayısal bir değer olmalıdır')
          .min(0, 'Ürün Fiyatı 0 veya daha büyük olmalıdır')
          .required('Ürün Fiyatı gereklidir'),
      })
    ),
  });
  const validationSchema_OffersWithCustomerData = Yup.object().shape({
    customerPhone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz.')
      .matches(/^\S+(\s*)$/, 'Boşluk Olmamalı')
      .matches(/^[1-9]/, 'Telefon numarası 0 ile başlayamaz.')
      .matches(/^\d{10}$/, 'XXX-XXX-XXXX formatında rakamlarla olmalı'),
    date: Yup.date()
      .required('Lütfen teklif tarihi seçiniz'),
    title: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen teklif başlığı giriniz')
      .min(2, 'başlık en az 2 karakter olmalıdır.')
      .max(25, 'başlık en fazla 25 karakter olmalıdır.'),
    status: Yup.string()
      .required('Bu alan gereklidir'),
    totalText: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ.,\s]*$/, 'Sadece harf, nokta, virgül ve boşluk kullanılabilir.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]/, 'Boşluk olmamalı, Başlangıçta harf kullanılmalı.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+|[\s]+[A-Za-zçğıöşüÇĞİÖŞÜ]+)*\s*$/, 'Nokta veya virgülden sonra harf kullanılmalı')
      .matches(/^(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+)*)(?:\s+(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+))*\s*$/, 'Örnek kullanımlar: Kırkbin TL, Onbin TL')
      .min(2, 'tutar en az 2 karakter olmalıdır.')
      .max(25, 'tutar en fazla 25 karakter olmalıdır.')
      .required('Bu alan gereklidir'),
    currency: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .required('Bu alan gereklidir'),
    kdv: Yup.string()
      .required('Bu alan gereklidir'),
    explanation: Yup.string()
      .matches(/^\S.*$/, 'Açıklama boşluk ile başlayamaz.')
      .min(5, 'Açıklama en az 5 karakter olmalıdır.')
      .max(250, 'Açıklama en fazla 250 karakter olmalıdır.'),
    offerDetails: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
          .required('Lütfen ürün adı giriniz')
          .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ0-9\s]+$/, 'Sadece harf ve rakam içermelidir.')
          .min(2, 'ürün en az 2 karakter olmalıdır.')
          .max(25, 'ürün en fazla 25 karakter olmalıdır.'),
        quantity: Yup.number()
          .typeError('Ürün Miktarı sayısal bir değer olmalıdır')
          .min(0, 'Ürün miktarı 0 veya daha büyük olmalıdır')
          .required('Ürün Miktarı gereklidir'),
        price: Yup.number()
          .typeError('Ürün Fiyatı sayısal bir değer olmalıdır')
          .min(0, 'Ürün Fiyatı 0 veya daha büyük olmalıdır')
          .required('Ürün Fiyatı gereklidir'),
      })
    ),
  });
  // Brand Info
  const validationSchema_Brand = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir marka adı giriniz!')
      .matches(/^[^\s].*$/, 'Marka adı boşluk ile başlayamaz!')
      .min(3, 'Marka adı en az 3 karakterden oluşmalıdır!')
      .max(25, 'Marka adı en fazla 25 karakterden oluşabilir!'),
    description: Yup.string()
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 2 karakterden oluşmalıdır!')
      .max(250, 'Açıklama en fazla 250 karakterden oluşabilir!'),
  });
  // Device Info
  const validationSchema_DeviceInfo = Yup.object().shape({
    name: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen  cihaz  giriniz.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(25, 'raf en fazla 25 karakter olmalıdır.'),
    description: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(250, 'raf en fazla 25 karakter olmalıdır.'),
  });
  // Dealer Info
  const validationSchema_DealerInfo = Yup.object().shape({
    name: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen  bayi  giriniz.')
      .min(2, 'raf en az 2 karakter olmalıdır.')
      .max(25, 'raf en fazla 25 karakter olmalıdır.'),
    status: Yup.string()
      .required('Lütfen durum seçiniz.')
  });
  // Invoices
  const validationSchema_Invoices = Yup.object().shape({
    serviceId: Yup.string().required('Lütfen Servis id seçiniz'),
    date: Yup.string().required('Lütfen bir tarih seçiniz!'),
    products: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required('Lütfen ürün adı giriniz').matches(/^[^\s].*$/, 'Başta boşluk olmamalı.').matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ0-9\s]+$/, 'Sadece harf ve rakam içermelidir.').min(2, 'ürün en az 2 karakter olmalıdır.').max(25, 'ürün en fazla 25 karakter olmalıdır.'),
        count: Yup.number().required('Ürün Miktarı gereklidir').typeError('Ürün Miktarı sayısal bir değer olmalıdır').min(0, 'Ürün miktarı 0 veya daha büyük olmalıdır'),
        price: Yup.number().required('Ürün Fiyatı gereklidir').typeError('Ürün Fiyatı sayısal bir değer olmalıdır').min(0, 'Ürün Fiyatı 0 veya daha büyük olmalıdır'),
      })
    ),

  });
  // Bulk Message
  const validationSchema_BulkMessages = Yup.object().shape({
    // startdate: Yup.date(),
    // enddate: Yup.date()
    //   .test('is-greater', 'Bitiş tarihi, başlangıç tarihinden sonra olmalıdır', function (value) {
    //     const { startdate } = this.parent;
    //     return value > startdate;
    //   })
    //   .notOneOf([Yup.ref('startdate')], 'Başlangıç tarihi ve bitiş tarihi aynı olamaz'),
    message: Yup.string()
      .matches(/^\S.*$/, 'Mesaj boşluk ile başlayamaz.')
      .min(5, 'Mesaj en az 5 karakter olmalıdır')
      .max(250, 'Mesaj en fazla 250 karakter olmalıdır')
      .required('Bu alan gerekli'),
  });
  // Export Validation Schemas
  const values = { validationSchema_Service, validationSchema_ServiceIncomingCalls, validationSchema_ServiceNote, validationSchema_Checkout, validationSchema_Customer, validationSchema_EmployeeAdd, validationSchema_EmployeeUpdate, validationSchema_Warehouse, validationSchema_WarehouseSupplier, validationSchema_WarehouseName, validationSchema_WarehouseShelf, validationSchema_Reminder, validationSchema_OperationReports, validationSchema_Offers, validationSchema_OffersWithCustomerData, validationSchema_BulkMessages, validationSchema_Invoices, validationSchema_Vehicles, validationSchema_Roles, validationSchema_Brand, validationSchema_DeviceInfo, validationSchema_DealerInfo }
  return <ValidationsContext.Provider value={values}>{children}</ValidationsContext.Provider>
}

const useValidations = () => useContext(ValidationsContext);

export { ValidationsProvider, useValidations };