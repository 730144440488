// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Dealers/';

// Async Thunks
export const getAllDealers = createAsyncThunk('backend_getalldealers', async () => {
  try {
    const res = await axios.get(`${baseurl}GetAllDealers`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});

export const getDealerById = createAsyncThunk('backend_getdealerbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetDealerById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewDealer = createAsyncThunk('backend_addnewdealer', async ({ newdealer }) => {
  try {
    const res = await axios.post(`${baseurl}AddDealer`, newdealer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateDealer = createAsyncThunk('backend_updatedealer', async ({ dealer }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateDealer`, dealer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteDealer = createAsyncThunk('backend_deletedealer', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteDealer?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const dealerSlice = createSlice({
  name: 'dealer',
  initialState: {
    dealers: [],
    dealer: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetGetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDealers.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllDealers.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.dealers = action.payload; })
      .addCase(getAllDealers.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getDealerById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getDealerById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.dealer = action.payload; })
      .addCase(getDealerById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewDealer.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewDealer.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewDealer.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateDealer.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateDealer.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateDealer.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteDealer.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteDealer.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteDealer.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetGetByIdStatus, resetError } = dealerSlice.actions;
export default dealerSlice.reducer;
