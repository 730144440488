// General
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// Context
import { useData } from '../../contexts/DataContext';
// Icons
import { FiHome, FiSettings, FiUser, FiUsers, FiLayers, FiCalendar, FiTag, FiMessageSquare } from "react-icons/fi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoStatsChartOutline, IoDocumentsOutline } from "react-icons/io5";
import { TbCashRegister, TbInvoice, TbSubtask } from "react-icons/tb";
import { MdChevronRight, MdOutlineOtherHouses } from "react-icons/md";
import { CiDeliveryTruck, CiUser } from "react-icons/ci";
import { IoLayersOutline } from "react-icons/io5";
import { LuArrowRightToLine } from "react-icons/lu";
import { BsHouseDoor, BsPlug } from "react-icons/bs";
import { TbWashMachine } from "react-icons/tb";

// Image
import ITSLogo from '../../assets/images/its.png';

function Sidebar() {
  // Context Import
  const { sidebar } = useData();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };
  // HTML
  return (
    <>
      {sidebar && (
        <div className="app-sidebar-menu">
          <div className="h-100 sidebarscrollbar">
            <div id="sidebar-menu">
              <div className="logo-box pt-4">
                <NavLink to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={ITSLogo} alt="" height="25" />
                  </span>
                  <span className="logo-lg">
                    <img src={ITSLogo} alt="" height="27" />
                  </span>
                </NavLink>
                <NavLink to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={ITSLogo} alt="" height="25" />
                  </span>
                  <span className="logo-lg">
                    <img src={ITSLogo} alt="" height="27" />
                  </span>
                </NavLink>
              </div>
              <ul id="side-menu">
                <li className="menu-title">MENÜ</li>
                <li>
                  <NavLink to="/anasayfa" className="tp-link">
                    <FiHome /><span>Anasayfa</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/servisler" className="tp-link">
                    <FiSettings /><span>Servisler</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/musteriler" className="tp-link">
                    <FiUser /><span>Müşteriler</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/personeller" className="tp-link">
                    <FiUsers /><span>Personeller</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/depo" className="tp-link">
                    <FiLayers /><span>Depo</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/teklifler" className="tp-link">
                    <FiTag /><span>Teklifler</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/hatirlatici" className="tp-link">
                    <FiCalendar /><span>Hatırlatıcı</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/anketler" className="tp-link">
                    <HiOutlineClipboardDocumentList /><span>Anketler</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/istatistikler" className="tp-link">
                    <IoStatsChartOutline /><span>İstatistikler</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/kasa" className="tp-link">
                    <TbCashRegister /><span>Kasa</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/fatura" className="tp-link">
                    <TbInvoice /><span>Fatura</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/toplumesaj" className="tp-link">
                    <FiMessageSquare /><span>Toplu SMS</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/islemraporları" className="tp-link">
                    <IoDocumentsOutline /><span>İşlem Raporları</span>
                  </NavLink>
                </li>
                <li>
                  <a href="#otherPage" onClick={toggleMenu} className="d-flex align-items-center">
                    <MdOutlineOtherHouses className="priorityicon me-1" />
                    <span>Diğer</span>
                    <MdChevronRight
                      className={`priorityicon menu-arrow ${isOpen ? 'rotate-90' : ''}`}
                    />
                  </a>
                  {isOpen && (
                    <div className="collapse show">
                      <ul className="nav-second-level px-3">
                        <li>
                          <NavLink to="/araclar" className="tp-link ">
                            <CiDeliveryTruck className=' fs-4 me-2 ms-1' />
                            <span>Araç Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/tedarikciler" className="tp-link ">
                            <CiUser className='fs-4 me-2 ms-1' />
                            <span>Tedarikçi Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/depolar" className="tp-link">
                            <IoLayersOutline className='fs-4 me-2 ms-1' />
                            <span>Depo Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/raflar" className="tp-link">
                            <LuArrowRightToLine className='fs-4 me-2 ms-1' />
                            <span>Raf Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/roller" className="tp-link">
                            <TbSubtask className='fs-4 me-2 ms-1' />
                            <span>Rol Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/markalar" className="tp-link">
                            <TbWashMachine className='fs-4 me-2 ms-1' />
                            <span>Marka Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/cihazlar" className="tp-link">
                            <BsPlug className='fs-4 me-2 ms-1' />
                            <span>Cihaz Bilgileri</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/bayiler" className="tp-link">
                            <BsHouseDoor className='fs-4 me-2 ms-1' />
                            <span >Bayi Bilgileri</span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;