// General
import React, { useEffect, useState } from 'react';
// Components
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';
// Context
import { useData } from '../../../contexts/DataContext';
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TbPrinter } from 'react-icons/tb';
import { RiFileAddFill, RiFileSettingsFill, RiFileReduceFill } from "react-icons/ri";
import { RiInboxUnarchiveFill } from "react-icons/ri";
// Modals
import InvoiceAddModal from '../modals/invoices/InvoicesAddModal';
import InvoiceUpdateModal from '../modals/invoices/InvoicesUpdateModal';
import DeleteModal from '../modals/DeleteModal';

function Invoices() {
  // Context Import
  const { today } = useData();
  const { stringMoney } = useFunctions();
  // Table
  const [filterStartDate, setFilterStartDate] = useState(today);
  const [filterEndDate, setFilterEndDate] = useState(today);
  const [dataToTable, setDataToTable] = useState([]);
  const rowsPerPage = 10;

  useEffect(() => {
    const data = []
    const toTable = data.map((item) => {
      const id = item.id;
      const number = item.invoiceNumber;
      const date = item.date;
      const customerData = null;
      const customerFullname = customerData?.name + " " + customerData?.surname;
      const customerPhone = customerData?.phone1;
      const cutomerAddress = customerData?.addressText;
      const cutomerAddress2 = customerData?.addressDistrict + '/' + customerData?.addressCity;
      const cutomerString = (
        <div className='d-flex flex-column justify-content-start align-items-start'>
          <p className='m-0 p-0'><b>{customerFullname}</b></p>
          <p className='m-0 p-0'>{customerPhone}</p>
          <p className='m-0 p-0 text-start'>{cutomerAddress}</p>
          <p className='m-0 p-0'>{cutomerAddress2}</p>
        </div>
      );
      const method = item.paymentMethod;
      const amount = stringMoney(item.amount, 'TL');
      return { id: id, number: number, date: date, customerFullname: customerFullname, customerPhone: customerPhone, cutomerAddress: cutomerAddress, cutomerAddress2: cutomerAddress2, cutomerString: cutomerString, method: method, amount: amount };
    });
    setDataToTable(toTable);
  }, [filterStartDate, filterEndDate]);
  const cols = [{ value: 'id', name: 'ID' }, { value: 'cutomerString', name: 'MÜŞTERİ' }, { value: 'number', name: 'FATURA NO' }, { value: 'date', name: 'TARİH' }, { value: 'method', name: 'ÖDEME ŞEKLİ' }, { value: 'amount', name: 'TUTAR' }];
  const filters = ['id', 'number', 'date', 'customerFullname', 'customerPhone', 'cutomerAddress', 'cutomerAddress2', 'method', 'amount']
  const handleSetDates = (startDate, endDate) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }
  const handleSelectedFunction = (id) => {

  }
  const tblbtn = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowInvoiceUpdateModal(null); }}><RiFileSettingsFill className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowInvoiceDeleteModal(rowData.id); }}><RiFileReduceFill className='button-icon' />Sil</button>
        <button className="button-with-icon btn btn-warning mx-1"><RiInboxUnarchiveFill className='button-icon' />E-Arşivle</button>
      </div >
    );
  };
  const table = () => {
    return (
      <Table
        data={dataToTable}
        rows={rowsPerPage}
        columns={cols}
        filternames={filters}
        select={handleSelectedFunction}
        dates={handleSetDates}
        buttons={tblbtn}
        bodyAlignBroker={['cutomerString']}
      />
    );
  }
  // Operations 
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowInvoiceAddModal(); }}><RiFileAddFill style={{ fontSize: '1.5em' }} /> Yeni Fatura Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); }} className="dropdown-item"><TbPrinter style={{ fontSize: '1.5em' }} /> Yazdır</button>
      </>
    );
  }
  // Modals
  // Invoice Add Modal
  const [showInvoiceAddModal, setShowInvoiceAddModal] = useState(false);
  const handleCloseInvoiceAddModal = () => { setShowInvoiceAddModal(false) };
  const handleShowInvoiceAddModal = () => { setShowInvoiceAddModal(true) };
  const handleAddInvoiceAddModal = (values) => {
    console.log('Fatura Eklendi: ', values);
    handleCloseInvoiceAddModal();
  };
  // Invoice Update Modal
  const [showInvoiceUpdateModal, setShowInvoiceUpdateModal] = useState(false);
  const [inputDataForInvoiceUpdateModal, setInputDataForInvoiceUpdateModal] = useState({});
  const handleCloseInvoiceUpdateModal = () => {
    setShowInvoiceUpdateModal(false);
    setInputDataForInvoiceUpdateModal({});
  };
  const handleShowInvoiceUpdateModal = (data) => {
    setInputDataForInvoiceUpdateModal(data);
    setShowInvoiceUpdateModal(true);
  };
  const handleUpdateInvoiceUpdateModal = (values) => {
    console.log('Fatura Güncellendi: ', inputDataForInvoiceUpdateModal, ' ---> ', values);
    handleCloseInvoiceUpdateModal();
  };
  // Invoice Delete Modal
  const [showInvoiceDeleteModal, setShowInvoiceDeleteModal] = useState(false);
  const [inputDataForInvoiceDeleteModal, setInputDataForInvoiceDeleteModal] = useState(-1);
  const handleCloseInvoiceDeleteModal = () => {
    setShowInvoiceDeleteModal(false);
    setInputDataForInvoiceDeleteModal(-1);
  };
  const handleShowInvoiceDeleteModal = (id) => {
    setInputDataForInvoiceDeleteModal(id);
    setShowInvoiceDeleteModal(true);
  };
  const handleDeleteInvoiceDeleteModal = (values) => {
    console.log('Fatura Silindi: ', values);
    handleCloseInvoiceAddModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <InvoiceAddModal
          show={showInvoiceAddModal}
          handleClose={handleCloseInvoiceAddModal}
          handleAdd={handleAddInvoiceAddModal}
        />
        <InvoiceUpdateModal
          show={showInvoiceUpdateModal}
          handleClose={handleCloseInvoiceUpdateModal}
          handleUpdate={handleUpdateInvoiceUpdateModal}
          inputData={inputDataForInvoiceUpdateModal}
        />
        <DeleteModal
          show={showInvoiceDeleteModal}
          handleClose={handleCloseInvoiceDeleteModal}
          handleDelete={handleDeleteInvoiceDeleteModal}
          title={"Faturayı Silmek İstiyor Musunuz?"}
          message={`${inputDataForInvoiceDeleteModal} id numaralı faturayı silmek istiyor musunuz?`}
          deleteIcon={<RiFileReduceFill />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='Faturalar' Operations={operations} Table={table} Modals={modals} />
  );
}

export default Invoices;