// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Cars/';

// Async Thunks
export const getAllVehicles = createAsyncThunk('backend_getallvehicles', async () => {
  try {
    const res = await axios.get(`${baseurl}GetAllCars`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getVehicleById = createAsyncThunk('backend_getvehiclebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetCarById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewVehicle = createAsyncThunk('backend_addnewvehicle', async ({ newvehicle }) => {
  try {
    console.log('Here!');
    const res = await axios.post(`${baseurl}AddCar`, newvehicle, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateVehicle = createAsyncThunk('backend_updatevehicle', async ({ vehicle }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateCar`, vehicle, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteVehicle = createAsyncThunk('backend_deletevehicle', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteCar?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    vehicles: [],
    vehicle: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicles.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllVehicles.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.vehicles = action.payload; })
      .addCase(getAllVehicles.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getVehicleById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getVehicleById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.vehicle = action.payload; })
      .addCase(getVehicleById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewVehicle.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewVehicle.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewVehicle.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateVehicle.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateVehicle.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateVehicle.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteVehicle.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteVehicle.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteVehicle.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus } = vehicleSlice.actions;
export default vehicleSlice.reducer;