// General
import { createContext, useContext, useState } from 'react';
// Icons
import { RiUserAddLine, RiUserSettingsFill, RiUserUnfollowFill } from "react-icons/ri";
import { LuFolderPlus, LuFolderCog2, LuFolderMinus, LuMailPlus, LuMailCheck, LuMailMinus, LuBookPlus, LuBookMarked, LuBookMinus, LuPackagePlus, LuPackageCheck, LuPackageMinus } from "react-icons/lu";
import { BsFillHouseAddFill, BsFillHouseGearFill, BsFillHouseDashFill, BsFillPatchPlusFill, BsFillPatchCheckFill, BsFillPatchMinusFill, BsClipboard2PlusFill, BsClipboard2CheckFill, BsClipboard2MinusFill } from "react-icons/bs";
import { TbDatabasePlus, TbDatabaseCog, TbDatabaseMinus, TbLayoutGridAdd, TbLayoutGrid, TbLayoutGridRemove, TbPhonePlus, TbPhoneCheck, TbPhoneX } from "react-icons/tb";
import { FaDoorOpen, FaDoorClosed, FaCalendarPlus, FaCalendarCheck, FaCalendarMinus } from "react-icons/fa";
import { FaPlugCirclePlus, FaPlugCircleCheck, FaPlugCircleMinus } from "react-icons/fa6";
import { MdError, MdBookmarkAdd, MdBookmarkAdded, MdBookmarkRemove } from "react-icons/md";
import { IoAddCircle, IoCheckmarkCircle, IoRemoveCircle } from "react-icons/io5";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  // DATA
  const [sidebar, setSidebar] = useState(true);
  const [myUser, setMyUser] = useState({ id: -1, name: "", username: "" });
  const [customers, setCustomers] = useState([
    { id: 1001, name: "Emir", surname: "Aksoydan", type: "Bireysel", identityNo: '12345678912', taxNo: '12121', taxOffice: '123123', phone1: "5301236547", phone2: '5380723398', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1002, name: "Batuhan", surname: "Umay", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236548", phone2: '', addressCity: "İstanbul", addressDistrict: "Zeytinburnu", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1003, name: "Muhammed Ali", surname: "Alarslan", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236549", phone2: '', addressCity: "Ankara", addressDistrict: "Mamak", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1004, name: "Mehmet", surname: "Erdoğdu", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236546", phone2: '', addressCity: "İstanbul", addressDistrict: "Zeytinburnu", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1005, name: "Mustafa", surname: "Tüfekçi", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236545", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1006, name: "İskender", surname: "Ak", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236544", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
    { id: 1007, name: "Soner", surname: "Gezgin", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236543", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active' },
  ]);
  const [users, setUsers] = useState([
    { id: 1001, name: "Tüyak Admin", username: "tuyak", phone1: "5348931183", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1002, name: "İsa Talha Mengi", username: "tuyak_itm", phone1: "5348931183", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1003, name: "Furkan Erhan", username: "tuyak_fe", phone1: "5388249401", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1004, name: "Deneme Çalışan 1", username: "deneme1", phone1: "5301234567", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1005, name: "Deneme Çalışan 2", username: "deneme2", phone1: "5301234568", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1006, name: "Deneme Çalışan 3", username: "deneme3", phone1: "5301234569", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1007, name: "Deneme Çalışan 4", username: "deneme4", phone1: "5301234566", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1008, name: "Deneme Çalışan 5", username: "deneme5", phone1: "5301234565", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1009, name: "Deneme Çalışan 6", username: "deneme6", phone1: "5301234564", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1010, name: "Deneme Çalışan 7", username: "deneme7", phone1: "5301234563", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 },
    { id: 1011, name: "Deneme Çalışan 8", username: "deneme8", phone1: "5301234562", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0 }
  ]);
  const [deviceinfodata, setDeviceInfoData] = useState([
    { id: 1, devicename: "Iphone 16", description: "Cihaz satışları bu yıl %50 oranında kar etti" },
    { id: 2, devicename: "Samsung Galaxy Note 10", description: "Cihaz Satışa Hazır" },
    { id: 3, devicename: "Xiaomi Pro", description: "Cihaz Satışa Hazır" },
  ]);
  const [brandinfodata, setBrandInfoData] = useState([
    { id: 1, brandname: "Apple", description: "Marka bu yıl %50 oranında kar etti" },
    { id: 2, brandname: "Samsung", description: "Marka Satışa Hazır" },
    { id: 3, brandname: "Xiami", description: "Marka Satışa Hazır" },
  ]);
  const [dealerinfodata, setDealerInfoData] = useState([
    { id: 1, dealername: "Bayi 1", status: "Aktif", serviceid: 100, device: 'Iphone 16', operation: 'Tamir Edildi' },
    { id: 2, dealername: "Bayi 2", status: "Aktif Değil", serviceid: 101, device: 'Samsung Galaxy', operation: 'Tamir Edildi' },
    { id: 3, dealername: "ITS Teknik Servis", status: "Aktif", serviceid: 102, device: 'Xiaomi', operation: 'Tamir Edildi' },
  ]);
  const [warehouseinfodata, setWarehouseInfoData] = useState([
    { id: 1, warehousename: "Depo 1", description: "Depo hazır" },
    { id: 2, warehousename: "Depo 2", description: "Depo hazır" },
    { id: 3, warehousename: "Depo 3", description: "Depo hazır" },
  ])
  const [vehicleinfodata, setVehicleInfoData] = useState([
    { id: 1, vehiclename: "34 KLM 5555 Volvo Emir Aksoydan", description: "Tır: 4×2, 6×2, 6×4​,Tüm akslar havalı süspansiyonludur,2-3 elektrik motoru I-Shift şanzıman," },
    { id: 2, vehiclename: "34 KLM 5657 Mercedes İsa Talha", description: "Araç sağlam" },
    { id: 3, vehiclename: "34 KLM 5658 MAN Furkan Erhan", description: "Araç sağlam" },
  ])
  const [supplierinfodata, setSupplierInfoData] = useState([
    { id: 1, suppliername: "Emir", description: "Tedarikçi mart ayında işe başladı, adres bilgisi : Beykikdüzü / İstanbul" },
    { id: 2, suppliername: "İsa", description: "Tedarikçi hazır" },
    { id: 3, suppliername: "Furkan", description: "Tedarikçi hazır" },
  ])
  const [roleinfodata, setRoleInfoData] = useState([
    { id: 1, rolename: "Yönetici", description: "Rol hazır", permission: ["Ürün Ekleme", "Teklif Ekleme", "Ürün Silme", "Müşteri Silme"] },
    { id: 2, rolename: "Teknisyen", description: "Rol hazır", permission: ["Ürün Ekleme", "Teklif Ekleme", "Ürün Silme",] },
    { id: 3, rolename: "Mühendis", description: "Rol hazır", permission: ["Ürün Ekleme", "Teklif Ekleme", "Ürün Silme",] },
  ])
  const [shelfinfodata, setShelfInfoData] = useState([
    { id: 1, warehousename: "Depo 1", shelfname: "Raf 1", description: "Raf hazır" },
    { id: 2, warehousename: "Depo 2", shelfname: "Raf 2", description: "Raf hazır" },
    { id: 3, warehousename: "Depo 3", shelfname: "Raf 3", description: "Raf hazır" },
  ])
  const today = new Date().toISOString().split("T")[0];
  const DefaultStatuses = ['Aktif', 'Aktif Değil'];
  const CustomerTypes = ['Bireysel', 'Kurumsal'];
  const CustomerStatuses = ['Aktif', 'Aktif Değil', 'İzinli'];
  const UserStatuses = ['Aktif', 'Aktif Değil', 'İzinli'];
  const OfferStatuses = ['Beklemede', 'Onay', 'Red'];
  const RecurrenceIntervalTypes = ['Sadece Bir Kere', 'Her Gün', 'Haftada Bir Bu Gün', 'Ayda Bir Bu Tarih', 'Yılda Bir Bu Tarih'];
  const ReminderTimeOptions = ['Zamanında', 'Onbeş Dakika Önce', 'Bir Saat Önce', 'Bir Gün Önce', 'İki Gün Önce'];
  const AnswerOptions = ['Evet', 'Hayır', 'Belirsiz'];
  const SurveyStatuses = ['Açık', 'İşlemde', 'Tamamlandı', 'Kapalı'];
  const sources = ['444 2356', '444 2720', '444 3808', '444 5808', 'GETFORM'];
  const warranties = [0, 6, 12];
  const cities = ['İstanbul', 'Kocaeli', 'Sakarya'];
  const serviceOperations = [
    'Atölyeye Aldır',
    'Bayiye Gönder',
    'Müşteri Cihazı Atölyeye Getirdi',
    'Müşteri İptal Etti',
    'Teknisyen Yönlendir',
    'Ürün Satışı Yapıldı',
    'Cihaz Atölyeye Alındı',
    'Cihaz Tamir Edilemiyor',
    'Fiyatta Anlaşılamadı',
    'Haber Verecek',
    'Müşteriye Ulaşılamadı',
    'Parça Takmak İçin Teknisyene Yönlendir',
    'Parça Talep Et',
    'Parçası Atölyeye Alındı',
    'Ürün Garantili Çıktı',
    'Yeniden Teknisyen Yönlendir',
    'Yerinde Bakım Yapıldı',
    'Fiyat Yükseltildi',
    'Nakliye Gönder',
    'Tahsilata Gönder',
    'Atölyede Tamir Ediliyor',
    'Nakliyede (Teslim Edilecek)',
    'Cihaz Teslim Edilemedi (Arızalı)',
    'Cihaz Teslim Edildi (Parça Takıldı)',
    'Cihaz Teslim Edildi',
    'Parça Teslim Et',
    'Parça Hazır',
    'Parça Siparişte',
    'Teslimata Hazır (Tamamlandı)',
    'Şikayetçi',
    'Müşteri Para İade Edilecek',
    'Müşteri Para İade Edildi',
    'Servisi Sonlandır'
  ];
  const witholdingRates = [
    { id: 601, name: 'Yapım işleri ile bu işlerle birlikte ifa edilen mühendislik-mimarlık ve etüt-proje hizmetleri', rate: 0.4 },
    { id: 602, name: 'Etüt, plan-proje, danışmanlık, denetim ve benzeri hizmetler', rate: 0.9 },
    { id: 603, name: 'Makine, teçhizat, demirbaş ve taşıtlara ait tadil, bakım ve onarım hizmetleri', rate: 0.7 },
    { id: 604, name: 'Yemek servis hizmeti', rate: 0.5 },
    { id: 605, name: 'Organizasyon hizmeti', rate: 0.5 },
    { id: 606, name: 'İşgücü temin hizmetleri', rate: 0.9 },
    { id: 607, name: 'Özel güvenlik hizmeti', rate: 0.9 },
    { id: 608, name: 'Yapı denetim hizmetleri', rate: 0.9 },
    { id: 609, name: 'Fason olarak yaptırılan tekstil ve konfeksiyon işleri, çanta ve ayakkabı dikim işleri ve bu işlere aracılık hizmetleri', rate: 0.7 },
    { id: 610, name: 'Turistik mağazalara verilen müşteri bulma / götürme hizmetleri', rate: 0.9 },
    { id: 611, name: 'Spor kulüplerinin yayın, reklâm ve isim hakkı gelirlerine konu işlemleri', rate: 0.9 },
    { id: 612, name: 'Temizlik hizmeti', rate: 0.9 },
    { id: 613, name: 'Çevre ve bahçe bakım hizmetleri', rate: 0.9 },
    { id: 614, name: 'Servis taşımacılığı hizmeti', rate: 0.5 },
    { id: 615, name: 'Her türlü baskı ve basım hizmetleri', rate: 0.7 },
    { id: 616, name: '5018 sayılı kanuna ekli cetvellerdeki idare, kurum ve kuruşlara yapılan diğer hizmetler', rate: 0.5 },
    { id: 617, name: 'Hurda metalden elde edilen külçe teslimleri', rate: 0.7 },
    { id: 618, name: 'Hurda metalden elde edilenler dışındaki bakır, çinko ve alüminyum külçe teslimleri', rate: 0.7 },
    { id: 619, name: 'Bakır, çinko ve alüminyum ürünlerinin teslimi', rate: 0.7 },
    { id: 620, name: 'İstisnadan vazgeçenlerin hurda ve atık teslimi', rate: 0.7 },
    { id: 621, name: 'Metal, plastik, lastik, kauçuk, kâğıt ve cam hurda ve atıklardan elde edilen hammadde teslimi', rate: 0.9 },
    { id: 622, name: 'Pamuk, tiftik, yün ve yapağı ile ham post ve deri teslimleri', rate: 0.9 },
    { id: 623, name: 'Ağaç ve orman ürünleri teslimi', rate: 0.5 },
    { id: 624, name: 'Yük taşımacılığı hizmeti', rate: 0.2 },
    { id: 625, name: 'Ticari reklam hizmetleri', rate: 0.3 },
    { id: 626, name: 'Diğer teslimler', rate: 0.2 },
    { id: 627, name: 'Demir-Çelik ürünlerinin teslimi', rate: 0.5 },
    { id: 801, name: 'Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri', rate: 1 },
    { id: 802, name: 'Etüt, Plan-Proje, Danışmanlık, Denetim ve Benzeri Hizmetler', rate: 1 },
    { id: 803, name: 'Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri', rate: 1 },
    { id: 804, name: 'Yemek Servis Hizmeti', rate: 1 },
    { id: 805, name: 'Organizasyon Hizmeti', rate: 1 },
    { id: 806, name: 'İşgücü Temin Hizmetleri', rate: 1 },
    { id: 807, name: 'Özel Güvenlik Hizmeti', rate: 1 },
    { id: 808, name: 'Yapı Denetim Hizmetleri', rate: 1 },
    { id: 809, name: 'Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri', rate: 1 },
    { id: 810, name: 'Turistik Mağazalara Verilen Müşteri Bulma/ Götürme Hizmetleri', rate: 1 },
    { id: 811, name: 'Spor Kulüplerinin Yayın, Reklâm ve İsim Hakkı Gelirlerine Konu İşlemleri', rate: 1 },
    { id: 812, name: 'Temizlik Hizmeti', rate: 1 },
    { id: 813, name: 'Çevre ve Bahçe Bakım Hizmetleri', rate: 1 },
    { id: 814, name: 'Servis Taşımacılığı Hizmeti', rate: 1 },
    { id: 815, name: 'Her Türlü Baskı ve Basım Hizmetleri	', rate: 1 },
    { id: 816, name: 'Hurda Metalden Elde Edilen Külçe Teslimleri', rate: 1 },
    { id: 817, name: 'Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi', rate: 1 },
    { id: 818, name: 'Bakır, Çinko, Alüminyum ve Kurşun Ürünlerinin Teslimi', rate: 1 },
    { id: 819, name: 'İstisnadan Vazgeçenlerin Hurda ve Atık Teslimi', rate: 1 },
    { id: 820, name: 'Metal, Plastik, Lastik, Kauçuk, Kâğıt ve Cam Hurda ve Atıklardan Elde Edilen Hammadde Teslimi', rate: 1 },
    { id: 821, name: 'Pamuk, Tiftik, Yün ve Yapağı İle Ham Post ve Deri Teslimleri', rate: 1 },
    { id: 822, name: 'Ağaç ve Orman Ürünleri Teslimi', rate: 1 },
    { id: 823, name: 'Yük Taşımacılığı Hizmeti', rate: 1 },
    { id: 824, name: 'Ticari Reklam Hizmetleri', rate: 1 },
    { id: 825, name: 'Demir-Çelik Ürünlerinin Teslimi', rate: 1 },
  ]
  const kdvRates = [
    { id: 301, name: 'Mal ihracatı', rate: 0 },
    { id: 302, name: 'Hizmet ihracatı', rate: 0 },
    { id: 303, name: 'Roaming hizmetleri', rate: 0 },
    { id: 304, name: 'Deniz, hava ve demiryolu taşıma araçlarının teslimi ve inşa, tadil, bakım ve onarımları', rate: 0 },
    { id: 305, name: 'Deniz ve hava taşıma araçları için liman ve hava meydanlarında yapılan hizmetler', rate: 0 },
    { id: 306, name: 'Petrol aramaları ve petrol boru hatlarının inşa ve modernizasyonuna ilişkin teslim ve hizmetler', rate: 0 },
    { id: 307, name: 'Maden aramaları, altın, gümüş veya platin madenlerine ilişkin işlemler', rate: 0 },
    { id: 308, name: 'Teşvikli yatırım mallarının teslimi', rate: 0.18 },
    { id: 309, name: 'Liman ve hava meydanlarının inşası, yenilenmesi ve genişletilmesi', rate: 0 },
    { id: 310, name: 'Ulusal güvenlik amaçlı teslim ve hizmetler', rate: 0 },
    { id: 311, name: 'Uluslararası taşımacılık', rate: 0 },
    { id: 312, name: 'Diplomatik organ ve misyonlara yapılan teslim ve hizmetler', rate: 0 },
    { id: 313, name: 'Uluslararası kuruluşlara yapılan teslim ve hizmetler', rate: 0 },
    { id: 314, name: 'Uluslararası anlaşmalar kapsamındaki istisnalar', rate: 0 },
    { id: 315, name: 'İhraç konusu eşyayı taşıyan araçlara motorin teslimi', rate: 0 },
    { id: 316, name: 'Serbest bölgelerdeki müşteriler için yapılan fason hizmetler', rate: 0.18 },
    { id: 317, name: 'Engellilere yönelik eğitim ve meslek araç gereçleri teslimi', rate: 0 },
    { id: 318, name: 'Yap-işlet-devret projeleri kapsamında teslim ve hizmetler', rate: 0 },
    { id: 319, name: 'Başbakanlık Merkez Teşkilatına yapılan araç teslimleri', rate: 0.18 },
    { id: 320, name: 'İSMEP kapsamında yapılan teslim ve hizmetler', rate: 0.18 },
    { id: 321, name: 'BM ve NATO’ya yapılan teslim ve hizmetler', rate: 0 },
    { id: 322, name: 'Bavul ticareti', rate: 0 },
    { id: 323, name: 'Ürün senetlerinin ticaret borsaları aracılığıyla ilk teslimi', rate: 0.18 },
    { id: 324, name: 'Kızılay’a yapılan teslim ve hizmetler', rate: 0 },
    { id: 325, name: 'Yem teslimleri', rate: 0.01 },
    { id: 326, name: 'Gübrelerin teslimi', rate: 0.01 },
    { id: 327, name: 'Gübre hammaddelerinin gübre üreticilerine teslimi', rate: 0.01 },
    { id: 328, name: 'Konut veya işyeri teslimleri', rate: 0.08 },
    { id: 330, name: 'OSB ve küçük sanayi siteleri inşası', rate: 0.08 },
    { id: 331, name: 'Ar-Ge faaliyetleri için makina-teçhizat teslimi', rate: 0.18 },
    { id: 332, name: 'İmalat sanayiine yönelik teşvikli teslimler', rate: 0.18 },
    { id: 333, name: 'Kamuya yapılan bağışların inşaat işlemleri', rate: 0.18 },
    { id: 334, name: 'Yabancılara verilen sağlık hizmetleri', rate: 0.08 },
    { id: 335, name: 'Basılı kitap ve süreli yayınların teslimi', rate: 0.01 },
    { id: 336, name: 'UEFA müsabakaları kapsamındaki teslimler', rate: 0 },
    { id: 350, name: 'Diğerleri', rate: 0.18 },
    { id: 351, name: 'İstisna olmayan diğer işlemler', rate: 0.18 },
  ]
  const toastIconTranslator = {
    // Genel
    add: <IoAddCircle style={{ fontSize: '1.5em' }} />,
    update: <IoCheckmarkCircle style={{ fontSize: '1.5em' }} />,
    delete: <IoRemoveCircle style={{ fontSize: '1.5em' }} />,
    error: <MdError style={{ fontSize: '1.5em' }} />,
    // Login
    login: <FaDoorOpen style={{ fontSize: '1.5em' }} />,
    logout: <FaDoorClosed style={{ fontSize: '1.5em' }} />,
    // Servisler
    clipboardAdd: <BsClipboard2PlusFill style={{ fontSize: '1.5em' }} />,
    clipboardUpdate: <BsClipboard2CheckFill style={{ fontSize: '1.5em' }} />,
    clipboardDelete: <BsClipboard2MinusFill style={{ fontSize: '1.5em' }} />,
    // Personeller & Müşteriler & Tedarikçiler
    userAdd: <RiUserAddLine />,
    userUpdate: <RiUserSettingsFill style={{ fontSize: '1.5em' }} />,
    userDelete: <RiUserUnfollowFill style={{ fontSize: '1.5em' }} />,
    // Depo
    packageAdd: <LuPackagePlus style={{ fontSize: '1.5em' }} />,
    packageUpdate: <LuPackageCheck style={{ fontSize: '1.5em' }} />,
    packageDelete: <LuPackageMinus style={{ fontSize: '1.5em' }} />,
    // Markalar
    patchAdd: <BsFillPatchPlusFill style={{ fontSize: '1.5em' }} />,
    patchUpdate: <BsFillPatchCheckFill style={{ fontSize: '1.5em' }} />,
    patchDelete: <BsFillPatchMinusFill style={{ fontSize: '1.5em' }} />,
    // Hatırlatıcı
    calendarAdd: <FaCalendarPlus style={{ fontSize: '1.5em' }} />,
    calendarUpdate: <FaCalendarCheck style={{ fontSize: '1.5em' }} />,
    calendarDelete: <FaCalendarMinus style={{ fontSize: '1.5em' }} />,
    // Kasa & İşlem Raporları
    folderAdd: <LuFolderPlus style={{ fontSize: '1.5em' }} />,
    folderUpdate: <LuFolderCog2 style={{ fontSize: '1.5em' }} />,
    folderDelete: <LuFolderMinus style={{ fontSize: '1.5em' }} />,
    // Fatura
    bookmarkAdd: <MdBookmarkAdd style={{ fontSize: '1.5em' }} />,
    bookmarkUpdate: <MdBookmarkAdded style={{ fontSize: '1.5em' }} />,
    bookmarkDelete: <MdBookmarkRemove style={{ fontSize: '1.5em' }} />,
    // Toplu Mesaj
    mailAdd: <LuMailPlus style={{ fontSize: '1.5em' }} />,
    mailUpdate: <LuMailCheck style={{ fontSize: '1.5em' }} />,
    mailDelete: <LuMailMinus style={{ fontSize: '1.5em' }} />,
    // Depolar & Bayiler
    houseAdd: <BsFillHouseAddFill style={{ fontSize: '1.5em' }} />,
    houseUpdate: <BsFillHouseGearFill style={{ fontSize: '1.5em' }} />,
    houseDelete: <BsFillHouseDashFill style={{ fontSize: '1.5em' }} />,
    // Raflar
    dataAdd: <TbDatabasePlus style={{ fontSize: '1.5em' }} />,
    dataUpdate: <TbDatabaseCog style={{ fontSize: '1.5em' }} />,
    dataDelete: <TbDatabaseMinus style={{ fontSize: '1.5em' }} />,
    // Roller
    layoutAdd: <TbLayoutGridAdd style={{ fontSize: '1.5em' }} />,
    layoutUpdate: <TbLayoutGrid style={{ fontSize: '1.5em' }} />,
    layoutDelete: <TbLayoutGridRemove style={{ fontSize: '1.5em' }} />,
    // Cihazlar
    plugAdd: <FaPlugCirclePlus style={{ fontSize: '1.5em' }} />,
    plugUpdate: <FaPlugCircleCheck style={{ fontSize: '1.5em' }} />,
    plugDelete: <FaPlugCircleMinus style={{ fontSize: '1.5em' }} />,
    // Diğerleri
    bookAdd: <LuBookPlus style={{ fontSize: '1.5em' }} />,
    bookUpdate: <LuBookMarked style={{ fontSize: '1.5em' }} />,
    bookDelete: <LuBookMinus style={{ fontSize: '1.5em' }} />,
    phoneAdd: <TbPhonePlus style={{ fontSize: '1.5em' }} />,
    phoneUpdate: <TbPhoneCheck style={{ fontSize: '1.5em' }} />,
    phoneDelete: <TbPhoneX style={{ fontSize: '1.5em' }} />,
  }
  const values = { sidebar, setSidebar, myUser, setMyUser, users, setUsers, customers, setCustomers, deviceinfodata, setDeviceInfoData, brandinfodata, setBrandInfoData, dealerinfodata, setDealerInfoData, warehouseinfodata, setWarehouseInfoData, vehicleinfodata, setVehicleInfoData, supplierinfodata, setSupplierInfoData, roleinfodata, setRoleInfoData, shelfinfodata, setShelfInfoData, today, DefaultStatuses, CustomerTypes, CustomerStatuses, UserStatuses, OfferStatuses, RecurrenceIntervalTypes, ReminderTimeOptions, AnswerOptions, SurveyStatuses, sources, warranties, cities, serviceOperations, witholdingRates, kdvRates, toastIconTranslator }
  return <DataContext.Provider value={values}>{children}</DataContext.Provider>
}

const useData = () => useContext(DataContext);

export { DataProvider, useData };