// General
import React, { useCallback, useEffect, useState } from 'react';
// Storage
import { useDispatch } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TbPrinter } from "react-icons/tb";
import { TiDocumentAdd } from "react-icons/ti";
import { RiFolderSettingsLine } from "react-icons/ri";
import { FiFolderMinus } from "react-icons/fi";
//Modals
import OperationReportsAddModal from '../modals/operationreports/OperationReportsAddModal';
import DeleteModal from '../modals/DeleteModal';
import OperationReportsUpdateModal from '../modals/operationreports/OperationReportsUpdateModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';

function OperationReports() {
  // Storage
  const dispatch = useDispatch();
  // Context Import
  // Filtering
  const [filterStartDate, setFilterStartDate] = useState(new Date().toLocaleDateString());
  const [filterEndDate, setFilterEndDate] = useState(new Date().toLocaleDateString());
  const filters = ['id', 'date', 'customerfullname', 'customerPhone', 'address1', 'address2', 'totalPrice', 'device'];
  const handleSetDates = (startDate, endDate) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const data = [
    { id: 1001, date: '12.02.2024', customerName: 'Emir', customerSurname: 'Aksoydan', customerPhone: '05380723398', customerAdres: 'Pınartepe Mahallesi', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', device: 'Mobil', totalPrice: '100.000 TL', serviceID: '123123', problem: 'Kablo problemi', operations: 'Kablo alındı', information: 'Problem Çözüldü', cost: '5000', },
    { id: 1002, date: '12.02.2024', customerName: 'Emir', customerSurname: 'Aksoydan', customerPhone: '05380723398', customerAdres: 'Pınartepe Mahallesi', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', device: 'Mobil', totalPrice: '100.000 TL', serviceID: '123123', problem: 'Kablo problemi', operations: 'Kablo alındı', information: 'Problem Çözüldü', cost: '5000', },
    { id: 1003, date: '12.02.2024', customerName: 'Emir', customerSurname: 'Aksoydan', customerPhone: '05380723398', customerAdres: 'Pınartepe Mahallesi', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', device: 'Mobil', totalPrice: '100.000 TL', serviceID: '123123', problem: 'Kablo problemi', operations: 'Kablo alındı', information: 'Problem Çözüldü', cost: '5000', }
  ];
  useEffect(() => {
    const toTable = data.map((item) => {
      const id = item.id;
      const date = item.date
      const customerfullname = item.customerName + " " + item.customerSurname;
      const customerPhone = item.customerPhone;
      const address1 = item.customerAdres
      const address2 = item.customerDistrict + '/' + item.customerCity;
      const device = item.device;
      const totalPrice = item.totalPrice;
      const customerInfo = (
        <div className='d-flex flex-column justify-content-start align-items-start'>
          <p className='m-0 p-0'>{customerfullname} - {customerPhone} </p>
          <p className='m-0 p-0'>{address1}</p>
          <p className='m-0 p-0'>{address2}</p>
        </div>
      )
      return { id: id, date: date, customerfullname: customerfullname, customerPhone: customerPhone, address1: address1, address2: address2, customerInfo: customerInfo, totalPrice: totalPrice, device: device };
    });
    setDataToTable(toTable);
  }, [filterStartDate, filterEndDate]);
  //PDF data
  const toTablePlainText = data.map((item) => {
    const customerfullname = item.customerName + " " + item.customerSurname;
    const address2 = item.customerDistrict + '/' + item.customerCity;
    const customerInfo = `${customerfullname} - ${item.customerPhone}\n${item.customerAdres}\n${address2}`;
    return {
      id: item.id,
      date: item.date,
      customerInfo: customerInfo,
      device: item.device,
      totalPrice: item.totalPrice,
    };
  });
  // Table Content
  const rowsPerPage = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customerInfo', name: 'MÜŞTERİ BİLGİLERİ' }, { value: 'device', name: 'CİHAZ' }, { value: 'totalPrice', name: 'GENEL TOPLAM' },];
  const handleSelectedFunction = (id) => {
    handleShowOperationReportsUpdateModal(data.find(dt => dt.id === id));
  }
  const tableButtons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button onClick={(e) => { e.stopPropagation(); handleShowOperationReportsUpdateModal(data.find(dt => dt.id === rowData.id)); }} className="button-with-icon btn btn-primary mx-1"><RiFolderSettingsLine className='button-icon' />Güncelle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowOperationReportsDeleteModal(rowData.id); }} className="button-with-icon btn btn-danger mx-1"><FiFolderMinus className='button-icon' />Sil</button>
      </div >
    );
  };
  const table = () => {
    return (
      <Table
        data={dataToTable}
        rows={rowsPerPage}
        columns={cols}
        filternames={filters}
        select={handleSelectedFunction}
        dates={handleSetDates}
        buttons={tableButtons}
        bodyAlignBroker={['customerInfo']}
      />
    );
  }
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowOperationReportsAddModal(); }} className="dropdown-item"><TiDocumentAdd style={{ fontSize: '1.5em' }} /> İşlem Raporu Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rowsPerPage, data: toTablePlainText, cols: cols, pdftitle: "İşlem Raporları" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // OperationReports Add Modal
  const [showOperationReportsAddModal, setShowOperationReportsAddModal] = useState(false);
  const handleCloseOperationReportsAddModal = () => { setShowOperationReportsAddModal(false) };
  const handleShowOperationReportsAddModal = () => { setShowOperationReportsAddModal(true) };
  const handleAddOperationReportsAddModal = (values) => {
    console.log(values);
    dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'İşlem Raporu Eklendi!', delay: 6000 }));
    handleCloseOperationReportsAddModal();
  };
  // OperationReports Update Modal
  const [showOperationReportsUpdateModal, setShowOperationReportsUpdateModal] = useState(false);
  const [inputDataForOperationReportsUpdateModal, setInputDataForOperationReportsUpdateModal] = useState({});
  const handleCloseOperationReportsUpdateModal = () => {
    setShowOperationReportsUpdateModal(false)
    setInputDataForOperationReportsUpdateModal({});
  };
  const handleShowOperationReportsUpdateModal = (data) => {
    setInputDataForOperationReportsUpdateModal(data);
    setShowOperationReportsUpdateModal(true);
  };
  const handleUpdateOperationReportsUpdateModal = (values) => {
    console.log('işlem raporu güncellendi: ', inputDataForOperationReportsUpdateModal, ' --> ', values);
    dispatch(addToast({ background: 'primary', icon: 'packageUpdate', message: 'İşlem Raporu Güncellendi!', delay: 6000 }));
    handleCloseOperationReportsUpdateModal();
  };
  // OperationReports Delete Modal
  const [showOperationReportsDeleteModal, setShowOperationReportsDeleteModal] = useState(false);
  const [inputDataForOperationReportsDeleteModal, setInputDataForOperationReportsDeleteModal] = useState(-1);
  const handleCloseOperationReportsDeleteModal = () => {
    setShowOperationReportsDeleteModal(false)
    setInputDataForOperationReportsDeleteModal(-1);
  };
  const handleShowOperationReportsDeleteModal = (data) => {
    setInputDataForOperationReportsDeleteModal(data);
    setShowOperationReportsDeleteModal(true);
  };
  const handleDeleteOperationReportsDeleteModal = (values) => {
    console.log(values, ' id numaralı işlem raporu silindi.');
    dispatch(addToast({ background: 'danger', icon: 'packageDelete', message: 'İşlem Raporu Silindi!', delay: 6000 }));
    handleCloseOperationReportsDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <OperationReportsAddModal
          show={showOperationReportsAddModal}
          handleClose={handleCloseOperationReportsAddModal}
          handleAdd={handleAddOperationReportsAddModal}>
        </OperationReportsAddModal>
        <OperationReportsUpdateModal
          show={showOperationReportsUpdateModal}
          handleClose={handleCloseOperationReportsUpdateModal}
          handleUpdate={handleUpdateOperationReportsUpdateModal}
          inputData={inputDataForOperationReportsUpdateModal}>
        </OperationReportsUpdateModal>
        <DeleteModal
          show={showOperationReportsDeleteModal}
          handleClose={handleCloseOperationReportsDeleteModal}
          handleDelete={handleDeleteOperationReportsDeleteModal}
          title="İşlem Raporu"
          message={inputDataForOperationReportsDeleteModal + " " + "numaralı işlem raporunu silmek istediğinize eminmisiniz"}
          deleteIcon={<RiFolderSettingsLine className='button-icon' />}
        >
        </DeleteModal>
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='İşlem Raporları' Operations={operations} Table={table} Modals={modals} />
  );
}

export default OperationReports;