// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  getAllDealers,
  getDealerById,
  addNewDealer,
  updateDealer,
  deleteDealer,
  resetAddStatus,
  resetUpdateStatus,
  resetDeleteStatus,
  resetGetByIdStatus,
  resetError
} from '../../../storages/slices/backend/DealerSlice';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext'
// Icons
import { BsFillHouseAddFill, BsFillHouseGearFill, BsFillHouseDashFill } from "react-icons/bs";
import { TbPrinter } from "react-icons/tb";
// Modals
import DealerAddModal from "../modals/dealers/DealerAddModal";
import DealerUpdateModal from "../modals/dealers/DealerUpdateModal";
import DealerDetailModal from '../modals/dealers/DealerDetailModal';
import DeleteModal from "../modals/DeleteModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Dealers() {
  // Storage Import
  const dispatch = useDispatch();
  const { dealers, dealer, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.dealer);
  // Context Import
  const { wordCombination } = useFunctions();
  // Data & Api
  useEffect(() => {
    dispatch(getAllDealers());
    console.log(dealers);
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDealerAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllDealers());
      dispatch(addToast({ background: 'success', icon: 'houseAdd', message: 'Yeni Bayi Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseDealerUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllDealers());
      dispatch(addToast({ background: 'primary', icon: 'houseUpdate', message: 'Bayi Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleDeleteDealerDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllDealers());
      dispatch(addToast({ background: 'danger', icon: 'houseDelete', message: 'Bayi Silindi!', delay: 6000 }));
    }
    if (getByIdStatus === 'fulfilled') {
      setInputDataForDealerUpdateModal(dealer);
      setShowDealerUpdateModal(true);
      dispatch(resetGetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, getByIdStatus, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'BAYİ ADI' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const select = (id) => {
    handleShowDealerUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowDealerUpdateModal(rowData.id); }}><BsFillHouseGearFill className='button-icon' />Güncelle</button>
        {/* <button className="button-with-icon btn btn-warning mx-1" onClick={(e) => { e.stopPropagation(); handleUpdateDealerDetailModal(findDataById(dealerinfodata, rowData.id)); }}><CiShop className='button-icon' />Detay</button> */}
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowDealerDeleteModal(rowData.id); }}><BsFillHouseDashFill className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dealers}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowDealerAddModal(); }}><BsFillHouseAddFill className='button-icon' /> Yeni Bayi Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dealers, cols: cols, pdftitle: "Bayiler" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Dealer Add Modal
  const [showDealerAddModal, setShowDealerAddModal] = useState(false);
  const handleCloseDealerAddModal = () => { setShowDealerAddModal(false) };
  const handleShowDealerAddModal = () => { setShowDealerAddModal(true) };
  const handleAddDealerAddModal = (values) => {
    dispatch(addNewDealer({ newdealer: { ...values, status: wordCombination(values.status) } }));
    handleCloseDealerAddModal();
  };
  // Dealer Update Modal
  const [showDealerUpdateModal, setShowDealerUpdateModal] = useState(false);
  const [inputDataForDealerUpdateModal, setInputDataForDealerUpdateModal] = useState({});
  const handleCloseDealerUpdateModal = () => {
    setShowDealerUpdateModal(false)
    setInputDataForDealerUpdateModal({});
  };
  const handleShowDealerUpdateModal = (id) => {
    dispatch(getDealerById({ id: id }));
  }
  const handleUpdateDealerUpdateModal = (values) => {
    console.log('Bayi güncellendi: ', inputDataForDealerUpdateModal, ' --> ', values);
    dispatch(updateDealer({ dealer: { ...values, status: wordCombination(values.status) } }));
    handleCloseDealerUpdateModal();
  };
  // Dealer Details Modal
  const [showDealerDetailModal, setShowDealerDetailModal] = useState(false);
  const [inputDataForDetailUpdateModal, setInputDataForDetailUpdateModal] = useState({});
  const handleCloseDealerDetailModal = () => {
    setShowDealerDetailModal(false)
    setInputDataForDetailUpdateModal({});
  };
  const handleUpdateDealerDetailModal = (data) => {
    setInputDataForDetailUpdateModal(data);
    setShowDealerDetailModal(true);
  };
  // Dealer Delete Modal
  const [showDealerDeleteModal, setShowDealerDeleteModal] = useState(false);
  const [inputDataForDealerDeleteModal, setInputDataForDealerDeleteModal] = useState(-1);
  const handleCloseDealerDeleteModal = () => {
    setShowDealerDeleteModal(false)
    setInputDataForDealerDeleteModal(-1);
  };
  const handleShowDealerDeleteModal = (id) => {
    setInputDataForDealerDeleteModal(id);
    setShowDealerDeleteModal(true);
  };
  const handleDeleteDealerDeleteModal = (values) => {
    console.log(values, ' id numaralı Bayi silindi.');
    if (inputDataForDealerDeleteModal !== -1) { dispatch(deleteDealer({ id: inputDataForDealerDeleteModal })); }
    handleCloseDealerDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <DealerAddModal
          show={showDealerAddModal}
          handleClose={handleCloseDealerAddModal}
          handleAdd={handleAddDealerAddModal}
        />
        <DealerUpdateModal
          show={showDealerUpdateModal}
          handleClose={handleCloseDealerUpdateModal}
          handleUpdate={handleUpdateDealerUpdateModal}
          inputData={inputDataForDealerUpdateModal}
        />
        <DealerDetailModal
          show={showDealerDetailModal}
          handleClose={handleCloseDealerDetailModal}
          inputData={inputDataForDetailUpdateModal}
        />
        <DeleteModal
          show={showDealerDeleteModal}
          handleClose={handleCloseDealerDeleteModal}
          handleDelete={handleDeleteDealerDeleteModal}
          title="Bayiyi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForDealerDeleteModal} id numaralı bayiyi silmek istediğinize emin misiniz?`}
          deleteIcon={<BsFillHouseDashFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Bayiler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Dealers;