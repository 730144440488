// General
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { CiCalendar } from "react-icons/ci";
import { TbArrowBackUpDouble } from "react-icons/tb";
import { TiDelete } from 'react-icons/ti';

function ReminderAddModal({ show, handleClose, handleAdd }) {
  // Context Import
  const { validationSchema_Reminder } = useValidations();
  // Variables
  const performerOfThetransactions = ['Emir', 'İsa', 'Furkan'];
  const remainingTimes = ['15 dakika önce', 'Zamanında Hatırlat', '1 saat önce', '1 gün önce', '2 gün önce',];
  const repeatTimes = ['Sadece bir kez', 'Her gün bu saatte tekrarla', 'Her haftanın bu günü tekrarla', 'Her ayın bu tarihinde tekrarla', 'Her yılın bu tarihinde tekrarla',];
  const stats = ['Aktif', 'Tamamlandı', 'İptal',];
  const [searchStartDate, setSearchStartDate] = useState(new Date().toLocaleDateString());
  // Formik Variables
  const initialValues = { message: '', date: '', time: '', remainingTime: '', repeatTime: '', performerOfThetransaction: ['Emir'], status: '', };

  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title> Hatırlatıcı Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_Reminder} initialValues={initialValues} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ReminderAddModal_date'
                      name='date'
                      label='Hatırlatma tarihi *'
                      type="date"
                      value={values.date = searchStartDate}
                      onChange={(e) => { setFieldValue('date', e.target.value); setSearchStartDate(e.target.value); }}
                      InputLabelProps={{ shrink: true, }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                      onFocus={() => { setFieldTouched('date', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ReminderAddModal_time'
                      name='time'
                      label='Hatırlatma saati *'
                      type="time"
                      InputLabelProps={{ shrink: true, }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.time && !!errors.time}
                      helperText={touched.time && errors.time}
                      onFocus={() => { setFieldTouched('time', true); }}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={Autocomplete}
                      id="ReminderAddModal_remainingTime"
                      name="remainingTime"
                      options={remainingTimes.map((option) => option)}
                      value={values.remainingTime}
                      onChange={(event, newValue) => { setFieldValue('remainingTime', newValue); }}
                      onBlur={() => { setFieldTouched('remainingTime', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params}
                        label="Hatırlatma Zamanı *" error={touched.remainingTime && !!errors.remainingTime}
                        helperText={touched.remainingTime && errors.remainingTime} onFocus={() => {
                          setFieldTouched('remainingTime', true);
                        }} />}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={Autocomplete}
                      id="ReminderAddModal_repeatTime"
                      name="repeatTime"
                      options={repeatTimes.map((option) => option)}
                      value={values.repeatTime}
                      onChange={(event, newValue) => { setFieldValue('repeatTime', newValue); }}
                      onBlur={() => { setFieldTouched('repeatTime', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params}
                        label="Tekrar Süresi *" error={touched.repeatTime && !!errors.repeatTime}
                        helperText={touched.repeatTime && errors.repeatTime} onFocus={() => {
                          setFieldTouched('repeatTime', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 ">
                    <Field
                      as={Autocomplete}
                      id="ReminderAddModal_status"
                      name="status"
                      options={stats.map((option) => option)}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params}
                        label="Durum *" error={touched.status && !!errors.status}
                        helperText={touched.status && errors.status} onFocus={() => {
                          setFieldTouched('status', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 ">
                    <Autocomplete
                      id='ReminderAddModal_performerOfThetransaction'
                      name='performerOfThetransaction'
                      options={performerOfThetransactions.map(item => item)}
                      value={values.performerOfThetransaction || []}
                      onChange={(event, newValue) => { setFieldValue('performerOfThetransaction', newValue) }}
                      size='small'
                      fullWidth
                      multiple
                      limitTags={1}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = values.performerOfThetransaction.filter((i) => i !== option);
                                    setFieldValue("performerOfThetransaction", newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Hatırlatılacak  Kişiler"
                        error={touched.performerOfThetransaction && !!errors.performerOfThetransaction}
                        helperText={touched.performerOfThetransaction && errors.performerOfThetransaction} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ReminderAddModal_message'
                      name='message'
                      label="Mesaj *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.message && !!errors.message}
                      helperText={touched.message && errors.message}
                      onFocus={() => { setFieldTouched('message', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><CiCalendar className='button-icon' />Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default ReminderAddModal