// General
import React from 'react';
// Styles
import { Table } from 'react-bootstrap';
// Charts
import BarChart from '../components/BarChart';

function Dashboard() {
  // Chart Data
  const data_x = [145, 96, 108, 140, 130, 150, 170, 138, 114, 128, 138];
  const data_y = ["01/01/2024", "02/01/2024", "03/01/2024", "04/01/2024", "05/01/2024", "06/01/2024", "07/01/2024", "08/01/2024", "09/01/2024", "10/01/2024", "11/01/2024"];
  // Temp Tables Data
  const colors = ["danger", "danger", "warning", "warning", "primary", "info", "secondary", "success"];
  const numbers = [8, 7, 6, 5, 4, 3, 2, 1];
  // HTML
  return (
    <div className='container-xxl '>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Anasayfa</h4>
        </div>
      </div>
      <div className='row g-3'>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Müşteri</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">132.000 Kişi</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Çalışan</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">50 Kişi</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Servis</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">5000 Adet</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Kasa</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">172.000 ₺</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-12'>
          <div className='card m-0'>
            <div className='card-body'>
              <h6><b>Son 3 gün gidilen servis sayısı</b></h6>
              <Table responsive striped className="w-100 text-center mb-0">
                <thead>
                  <tr>
                    <th>Gidilen Tarih</th>
                    <th>Gidilen Servis Sayısı</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>20.10.2024 <b>(Bugün)</b> </td><td>20</td></tr>
                  <tr><td>19.10.2024 <b>(Dün)</b></td><td>30</td></tr>
                  <tr><td>18.10.2024 <b>(Önceki Gün)</b></td><td>50</td></tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-12'>
          <div className="card m-0">
            <div className="card-body">
              <h6><b>Aylık Servis Sayısı</b></h6>
              <BarChart data_x={data_x} data_y={data_y} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card m-0">
            <div className="card-body">
              <h6><b>En Çok Kullanılan Parçalar</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>Parça Adı</th>
                    <th colSpan="2">Adet</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {numbers.map((num) => {
                    return (
                      <tr key={num}>
                        <td>Geçici Veri {num}</td>
                        <td>{num}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[num - 1]}`} style={{ width: `${((num / 36) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card m-0">
            <div className="card-body">
              <h6><b>En Çok Ziyaret Edilen İlçeler</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>İlçe Adı</th>
                    <th colSpan="2">Ziyaret Miktarı</th>
                  </tr>
                </thead>
                <tbody>
                  {numbers.map((num) => {
                    return (
                      <tr key={num}>
                        <td>İlçe {num}</td>
                        <td>{num}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[num - 1]}`} style={{ width: `${((num / 36) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;