// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  addNewWarehouse,
  deleteWarehouse,
  getAllWarehouses,
  getWarehouseById,
  resetAddStatus,
  resetDeleteStatus,
  resetError,
  resetgetByIdStatus,
  resetUpdateStatus,
  updateWarehouse
} from '../../../storages/slices/backend/WarehousesSlice';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext'
// Icons
import { LuPackagePlus, LuPackageCheck, LuPackageMinus } from "react-icons/lu";
import { TbPrinter } from "react-icons/tb";
// Modals
import WarehousesAddModal from "../modals/warehouses/WarehousesAddModal"
import DeleteModal from "../modals/DeleteModal";
import WarehousesUpdateModal from "../modals/warehouses/WarehousesUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Warehouses() {
  // Storages
  const dispatch = useDispatch();
  const { warehouses, warehouse, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.warehouses);
  // Context Import
  const { wordCombination } = useFunctions();
  // Data & Api
  useEffect(() => {
    dispatch(getAllWarehouses());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseWarehousesAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllWarehouses());
      dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Yeni Depo Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseWarehousesUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllWarehouses());
      dispatch(addToast({ background: 'primary', icon: 'packageUpdate', message: 'Depo Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseWarehousesDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllWarehouses());
      dispatch(addToast({ background: 'danger', icon: 'packageDelete', message: 'Depo Silindi!', delay: 6000 }));
    }
    if (getByIdStatus === 'fulfilled') {
      setInputDataForWarehousesUpdateModal(warehouse);
      setShowWarehousesUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, getByIdStatus, dispatch]);
  useEffect(() => {
    if (error) {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'DEPO ADI' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const select = (id) => {
    handleShowWarehousesUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowWarehousesUpdateModal(rowData.id) }}><LuPackageCheck className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowWarehousesDeleteModal(rowData.id); }}><LuPackageMinus className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={warehouses}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowWarehousesAddModal(); }}><LuPackagePlus style={{ fontSize: '1.5em' }} /> Yeni Depo Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: warehouses, cols: cols, pdftitle: "Depolar" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Warehouse Add Modal
  const [showWarehousesAddModal, setShowWarehousesAddModal] = useState(false);
  const handleCloseWarehousesAddModal = () => { setShowWarehousesAddModal(false) };
  const handleShowWarehousesAddModal = () => { setShowWarehousesAddModal(true) };
  const handleAddWarehousesAddModal = (values) => {
    console.log(values);
    dispatch(addNewWarehouse({ newwarehouse: { ...values, status: wordCombination(values.status) } }));
    handleCloseWarehousesAddModal();
  };
  // Warehouse Update Modal
  const [showWarehousesUpdateModal, setShowWarehousesUpdateModal] = useState(false);
  const [inputDataForWarehousesUpdateModal, setInputDataForWarehousesUpdateModal] = useState({});
  const handleCloseWarehousesUpdateModal = () => {
    setShowWarehousesUpdateModal(false)
    setInputDataForWarehousesUpdateModal({});
  };
  const handleShowWarehousesUpdateModal = (id) => {
    dispatch(getWarehouseById({ id: id }));
  };
  const handleUpdateWarehousesUpdateModal = (values) => {
    console.log('Depo güncellendi: ', inputDataForWarehousesUpdateModal, ' --> ', values);
    dispatch(updateWarehouse({ warehouse: { ...values, status: wordCombination(values.status) } }));
    handleCloseWarehousesUpdateModal();
  };
  // Warehouse Delete Modal
  const [showWarehousesDeleteModal, setShowWarehousesDeleteModal] = useState(false);
  const [inputDataForWarehousesDeleteModal, setInputDataForWarehousesDeleteModal] = useState(-1);
  const handleCloseWarehousesDeleteModal = () => {
    setShowWarehousesDeleteModal(false)
    setInputDataForWarehousesDeleteModal(-1);
  };
  const handleShowWarehousesDeleteModal = (id) => {
    setInputDataForWarehousesDeleteModal(id);
    setShowWarehousesDeleteModal(true);
  };
  const handleDeleteWarehousesDeleteModal = (values) => {
    console.log(values, ' id numaralı Depo silindi.');
    if (inputDataForWarehousesDeleteModal !== -1) { dispatch(deleteWarehouse({ id: inputDataForWarehousesDeleteModal })); }
    handleCloseWarehousesDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <WarehousesAddModal
          show={showWarehousesAddModal}
          handleClose={handleCloseWarehousesAddModal}
          handleAdd={handleAddWarehousesAddModal}
        />
        <WarehousesUpdateModal
          show={showWarehousesUpdateModal}
          handleClose={handleCloseWarehousesUpdateModal}
          handleUpdate={handleUpdateWarehousesUpdateModal}
          inputData={inputDataForWarehousesUpdateModal}
        />
        <DeleteModal
          show={showWarehousesDeleteModal}
          handleClose={handleCloseWarehousesDeleteModal}
          handleDelete={handleDeleteWarehousesDeleteModal}
          title="Depoyu Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForWarehousesDeleteModal} id numaralı depoyu silmek istediğinize emin misiniz?`}
          deleteIcon={<LuPackageMinus className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Depolar' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Warehouses;