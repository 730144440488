// General
import { configureStore } from '@reduxjs/toolkit';
// Storages
import printReducer from './slices/PrintSlice';
import toastReducer from './slices/ToastSlice';
import districtReducer from './slices/DistrictSlice';
import loginReducer from './slices/LoginSlice';
import customerReducer from './slices/backend/CustomerSlice';
import employeeReducer from './slices/backend/EmployeeSlice';
import vehicleReducer from './slices/backend/VehicleSlice';
import shelfReducer from './slices/backend/ShelfSlice';
import brandReducer from './slices/backend/BrandSlice';
import deviceReducer from './slices/backend/DeviceSlice';
import dealerReducer from './slices/backend/DealerSlice';
import supplierReducer from './slices/backend/SupplierSlice';
import warehousesReducer from './slices/backend/WarehousesSlice';
import roleReducer from './slices/backend/RoleSlice';
import offerReducer from './slices/backend/OfferSlice';




export const store = configureStore({
  reducer: {
    print: printReducer,
    toast: toastReducer,
    district: districtReducer,
    login: loginReducer,
    customer: customerReducer,
    employee: employeeReducer,
    vehicle: vehicleReducer,
    shelf: shelfReducer,
    brand: brandReducer,
    device: deviceReducer,
    dealer: dealerReducer,
    supplier: supplierReducer,
    warehouses: warehousesReducer,
    role: roleReducer,
    offer: offerReducer,
  },
})