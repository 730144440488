// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllEmployees, getEmployeeById, addNewEmployee, updateEmployee, deleteEmployee, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storages/slices/backend/EmployeeSlice';
// Icons
import { TbPrinter } from "react-icons/tb";
import { RiUserAddLine, RiUserSettingsFill, RiUserUnfollowFill } from "react-icons/ri";
// Modals
import EmployeesAddModal from "../modals/employees/EmployeesAddModal";
import DeleteModal from "../modals/DeleteModal";
import EmployeesUpdateModal from "../modals/employees/EmployeesUpdateModal";
// Components
import StandartTablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Employees() {
  // Storage Import
  const dispatch = useDispatch();
  const { employees, employee, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.employee);
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatus === "fulfilled") {
      const data = employees.map((data) => {
        const id = data.id;
        const name = data.name;
        const phone = data.phone1;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const addressString = `${address}\n${district}/${city}`;
        const addressDiv = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        return { id, name, phone, address, district, city, addressString, addressDiv };
      });
      setDataToTable(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressDiv, searchAddress: item.addressString })));
      setDataToPrint(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressString })));
    }
  }, [getAllStatus, employees]);
  useEffect(() => {
    dispatch(getAllEmployees());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseEmployeeAddModal();
      dispatch(getAllEmployees());
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Yeni Personel Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseEmployeeUpdateModal();
      dispatch(getAllEmployees());
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'userUpdate', message: 'Personel Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseEmployeeDeleteModal();
      dispatch(getAllEmployees());
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'userDelete', message: 'Personel Bilgileri Silindi!', delay: 6000 }));
    }
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForEmployeeUpdateModal(employee);
        setShowEmployeeUpdateModal(true);
        setDataRequester('');
      } else if (dataRequester === 'delete') {
        setInputDataForEmployeeDeleteModal({ id: employee.id, name: employee.name });
        setShowEmployeeDeleteModal(true);
        setDataRequester('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error) {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'MÜŞTERİ' }, { value: 'phone', name: 'TELEFON' }, { value: 'address', name: 'ADRES' }]; //
  const filters = ['id', 'name', 'phone', 'searchAddress']; //
  const select = (id) => { handleShowEmployeeUpdateModal(id); }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowEmployeeUpdateModal(rowData.id); }}>
          <RiUserSettingsFill className='button-icon' />
          Güncelle
        </button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowEmployeeDeleteModal(rowData.id); }}>
          <RiUserUnfollowFill className='button-icon' />
          Sil
        </button>
      </div>
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
          bodyAlignBroker={['address']}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, dataToTable]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowEmployeeAddModal(); }}>
          <RiUserAddLine className='button-icon' />
          Yeni Müşteri Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPrint, cols: cols, pdftitle: "Müşteriler" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Employee Add Modal
  const [showEmployeeAddModal, setShowEmployeeAddModal] = useState(false);
  const handleCloseEmployeeAddModal = () => { setShowEmployeeAddModal(false) };
  const handleShowEmployeeAddModal = () => { setShowEmployeeAddModal(true) };
  const handleAddEmployeeAddModal = (values) => {
    dispatch(addNewEmployee({ newemployee: values }));
    handleCloseEmployeeAddModal();
  };
  // Employee Update Modal
  const [showEmployeeUpdateModal, setShowEmployeeUpdateModal] = useState(false);
  const [inputDataForEmployeeUpdateModal, setInputDataForEmployeeUpdateModal] = useState({});
  const handleCloseEmployeeUpdateModal = () => {
    setShowEmployeeUpdateModal(false)
    setInputDataForEmployeeUpdateModal({});
  };
  const handleShowEmployeeUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getEmployeeById({ id: id }));
  };
  const handleUpdateEmployeeUpdateModal = (values) => {
    dispatch(updateEmployee({ employee: values }));
    handleCloseEmployeeUpdateModal();
  };
  // Employee Delete Modal
  const [showEmployeeDeleteModal, setShowEmployeeDeleteModal] = useState(false);
  const [inputDataForEmployeeDeleteModal, setInputDataForEmployeeDeleteModal] = useState({ id: -1, name: '' });
  const handleCloseEmployeeDeleteModal = () => {
    setShowEmployeeDeleteModal(false);
    setInputDataForEmployeeDeleteModal(-1);
  };
  const handleShowEmployeeDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getEmployeeById({ id: id }));
  };
  const handleDeleteEmployeeDeleteModal = () => {
    dispatch(deleteEmployee({ id: inputDataForEmployeeDeleteModal.id }));
    handleCloseEmployeeDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <EmployeesAddModal
          show={showEmployeeAddModal}
          handleClose={handleCloseEmployeeAddModal}
          handleAdd={handleAddEmployeeAddModal}
        />
        <EmployeesUpdateModal
          show={showEmployeeUpdateModal}
          handleClose={handleCloseEmployeeUpdateModal}
          handleUpdate={handleUpdateEmployeeUpdateModal}
          inputData={inputDataForEmployeeUpdateModal}
        />
        <DeleteModal
          show={showEmployeeDeleteModal}
          handleClose={handleCloseEmployeeDeleteModal}
          handleDelete={handleDeleteEmployeeDeleteModal}
          title="Personeli Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForEmployeeDeleteModal.name} adlı personeli silmek istediğinize emin misiniz?`}
          deleteIcon={<RiUserUnfollowFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage
      title='Personeller'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Employees;