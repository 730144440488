// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { TbSubtask } from "react-icons/tb";
import { TiDelete } from 'react-icons/ti';


function RolePermissionModal({ show, handleClose, handleUpdate, inputData }) {
  // Variables
  const permissions = ["Ürün Ekleme", "Ürün Güncelleme", "Ürün Silme", "Müşteri Silme", "Müşteri Ekleme", "Müşteri Güncelleme"];
  //Formik Values
  const initialValues = {
    id: inputData.id,
    name: inputData.rolename,
    description: inputData.description,
    permission: inputData.permission || []
  };
  // HTML
  return (
    <div>
      <Modal size='sm' show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header className='py-2'>
          <Modal.Title>İzinler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-1  rounded-3'>
                  <div className='col-12 col-md-12'>
                    <Autocomplete
                      id='RolePermissionModal_permission'
                      name='permission'
                      options={permissions.map(item => item)}
                      value={values.permission || []}
                      onChange={(event, newValue) => { setFieldValue('permission', newValue) }}
                      size='small'
                      fullWidth
                      multiple
                      limitTags={1}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = values.permission.filter((i) => i !== option);
                                    setFieldValue("permission", newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="İzinler" />}
                    />
                  </div>
                  <div className="row g-2  px-0 mx-0 mt-1 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><TbSubtask className='button-icon' />Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default RolePermissionModal