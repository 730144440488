// General
import React, { useCallback, useEffect, useState } from 'react';
// Icons
import { HiMiniChevronDown } from 'react-icons/hi2';
import { TbBook2 } from 'react-icons/tb';

function TablePage({ title, Operations, Table, Modals }) {
  // Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);
  // HTML
  return (
    <div className='card mt-3'>
      <div className='card-header m-0 p-1'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-7'>
            <div className='row h-100 m-0 p-2'>
              <div className='d-flex align-items-center'>
                <div className='card-title m-0 p-0'>{title}</div>
              </div>
            </div>
          </div>
          <div className='col-12 ps-3 col-md-5 '>
            <div className='row d-flex justify-content-end m-1'>
              <div className='col-12 col-md-5 ps-0 '>
                <div className="dropdown">
                  <button className="button-with-icon btn btn-success operationsdropdown  dropdown-toggle w-100" onClick={toggleOperationsDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <TbBook2 className='button-icon' />
                    İşlemler
                    <HiMiniChevronDown style={{ fontSize: '1.25em' }} />
                  </button>
                  {operationsDropdown && (
                    <ul className="dropdown-menu operationsdropdown w-100 show">
                      <Operations />
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <Table />
        <Modals />
      </div>
    </div>
  );
}

export default TablePage;