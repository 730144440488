// General
import { createContext, useContext, useEffect, useState } from 'react';
// Storage
import { useDispatch } from 'react-redux';
import { addToast } from '../storages/slices/ToastSlice';
// Context
import { useData } from './DataContext';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { users, setMyUser } = useData();
  const [username, setUsername] = useState("X");
  const [password, setPassword] = useState("X");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (username === "" && password === "") {
      setMyUser({ id: -1, name: "", email: "" });
      setIsLoggedIn(false);
      dispatch(addToast({ background: 'danger', icon: 'logout', message: 'Çıkış Yaptınız!', delay: 2000 }))
    }
    else {
      const user = users.find(user => user.username === username && user.password === password);
      if (user) {
        setIsLoggedIn(true);
        setMyUser({ id: user.id, name: user.name, username: user.username });
        dispatch(addToast({ background: 'success', icon: 'login', message: 'Başarı ile giriş Yaptınız!', delay: 2000 }))
      } else {
        setIsLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password]);
  useEffect(() => {
    if (isLoggedIn) {

    }
  }, [isLoggedIn]);
  const values = { setUsername, setPassword, isLoggedIn };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };