// General
import React, { useState, useEffect } from 'react';
// Styles
import Chart from 'react-apexcharts';
import randomColor from 'randomcolor';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';

const PieChart = ({ data = [], unit = "" }) => {
  const colors = ["#4169e1", "#ff8247", "#66cd00", "#6959cd", "#ffd700", "#ff3030", "#ff4500", "#ff1493", "#008080"];
  const [chartColors, setChartColors] = useState([]);
  useEffect(() => {
    if (colors === null || colors?.lenght < data?.lenght) {
      setChartColors(data.map(() => randomColor()));
    } else {
      setChartColors(colors);
    }
  }, [data]);
  const { stringMoney } = useFunctions();
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  useEffect(() => {
    setChartOptions({
      chart: { type: 'pie' },
      labels: totalValue === 0 ? ['Yeterli Veri Yok'] : data.map((item) => item.name),
      colors: totalValue === 0 ? ['#8b8989'] : chartColors.slice(0, data.lenght),
      legend: { show: true },
      tooltip: { y: { formatter: (val) => `${(unit === 'TL' || unit === 'USD' || unit === 'EURO') ? stringMoney(val, unit) : `${totalValue === 0 ? val.toFixed(0) : val} ${totalValue !== 0 ? unit : ''}`}` } }
    });
    setChartSeries(totalValue === 0 ? [0.000001] : data.map((item) => item.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);
  return (
    <Chart options={chartOptions} series={chartSeries} type="pie" height={400} />
  );
};

export default PieChart;