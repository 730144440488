// General
import React, { useEffect, useState } from 'react';
// Icons
import { TbFolderPlus, TbPrinter, TbFolderCheck, TbFolderStar, TbFolderCog, TbFolderX } from "react-icons/tb";
import { MdCurrencyLira } from 'react-icons/md';
import { HiOutlineDocumentReport } from "react-icons/hi";
import alarm from '../../../assets/images/gif/alarm.gif';
// Storages
import { useDispatch } from 'react-redux';
import { exportPdf } from '../../../storages/slices/PrintSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Modals
import ServiceAddModal from '../modals/services/ServiceAddModal';
import ServicePlannerModal from '../modals/services/ServicePlannerModal';
import ServiceIncomingCallsModal from '../modals/services/ServiceIncomingCallsModal';
import BonusCalculatorModal from '../modals/services/BonusCalculatorModal';
import ServiceReportsModal from '../modals/services/ServiceReportsModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';

function Services() {
  // Storage Import
  const dispatch = useDispatch();
  // Context Import
  const { today } = useData();
  // Filtering
  const [startdate, setStartdate] = useState(today);
  const [enddate, setEnddate] = useState(today);
  // Data & Api
  const dataToTable = [];
  // Table Content
  const columns = [{ value: 'id', name: 'ID' }, { value: 'customerText', name: 'MÜŞTERİ' }, { value: 'deviceText', name: 'CİHAZ' }, { value: 'date', name: 'TARİH' }, { value: 'deviceNote', name: 'NOT' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'customerName', 'customerPhone', 'cutomerAddressCity', 'cutomerAddressDistrict', 'cutomerAddressText', 'deviceBrand', 'deviceType', 'deviceModel', 'deviceProblem', 'deviceNote', 'date', 'status'];
  const select = (id) => {
    console.log(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); }}>
          <TbFolderCog className='button-icon' />
          Güncelle
        </button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowServiceDeleteModal(); }}>
          <TbFolderX className='button-icon' />
          Sil
        </button>
      </div>
    );
  };
  const rowcolor = (rowData) => {
    return rowData.status === 'Yeni Servisler' ? 'row-new-service' : rowData.status === 'Parça Hazır' ? 'row-part-ready' : rowData.status === 'Teslimata Hazır (Tamamlandı)' && 'row-delivery-ready';
  };
  const table = () => {
    return (
      <Table
        data={dataToTable}
        rows={10}
        columns={columns}
        filternames={filters}
        select={select}
        dates={(startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }}
        buttons={buttons}
        colors={rowcolor}
        bodyAlignBroker={['customerText']}
      />
    );
  }
  // Operations
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowServiceAddModal(); }}>
          <TbFolderPlus style={{ fontSize: '1.5em' }} />
          Yeni Servis Ekle
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServicePlannerModal(); }}>
          <TbFolderCheck style={{ fontSize: '1.5em' }} />
          Servis Planla
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServiceIncomingCallsModal(); }}>
          <TbFolderStar style={{ fontSize: '1.5em' }} />
          Gelen Çağrılar
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowBonusCalculatorModal(); }}>
          <MdCurrencyLira style={{ fontSize: '1.5em' }} />
          Prim Hesapla
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServiceReportsModal(); }}>
          <HiOutlineDocumentReport style={{ fontSize: '1.5em' }} />
          Raporlar
        </button>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); dispatch(exportPdf({ rowsPerPage: 10, data: [], cols: [], pdftitle: "Servisler" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Service Add Modal
  const [showServiceAddModal, setShowServiceAddModal] = useState(false);
  const handleCloseServiceAddModal = () => { setShowServiceAddModal(false) };
  const handleShowServiceAddModal = () => { setShowServiceAddModal(true) };
  const handleAddServiceAddModal = (values) => {
    if (values.style === 'onlyService') {
      console.log('Servis Eklendi: ', values.newService);
    } else {
      console.log('Müşteri Eklendi: ', values.newCustomer);
      console.log('Servis Eklendi: ', values.newService);
    }
    handleCloseServiceAddModal();
  };
  // Service Planner Modal
  const [showServicePlannerModal, setShowServicePlannerModal] = useState(false);
  const handleCloseServicePlannerModal = () => { setShowServicePlannerModal(false) }
  const handleShowServicePlannerModal = () => { setShowServicePlannerModal(true) }
  // Service Incoming Calls Modal
  const [showServiceIncomingCallsModal, setShowServiceIncomingCallsModal] = useState(false);
  const handleCloseServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(false) };
  const handleShowServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(true) };
  const handleSaveServiceIncomingCallsModal = (values) => {
    console.log(values);
    handleCloseServiceIncomingCallsModal();
  }
  // Bonus Calculator Modal
  const [showBonusCalculatorModal, setShowBonusCalculatorModal] = useState(false);
  const handleCloseBonusCalculatorModal = () => { setShowBonusCalculatorModal(false); }
  const handleShowBonusCalculatorModal = () => { setShowBonusCalculatorModal(true); }
  // Service Reports Modal
  const [showServiceReportsModal, setShowServiceReportsModal] = useState(false);
  const handleCloseServiceReportsModal = () => { setShowServiceReportsModal(false); }
  const handleShowServiceReportsModal = () => { setShowServiceReportsModal(true); }
  // Service Delete Modal
  const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
  const [inputDataForServiceDeleteModal, setInputDataForServiceDeleteModal] = useState(-1);
  const handleCloseServiceDeleteModal = () => {
    setShowServiceDeleteModal(false)
    setInputDataForServiceDeleteModal(-1);
  };
  const handleShowServiceDeleteModal = (data) => {
    setInputDataForServiceDeleteModal(data);
    setShowServiceDeleteModal(true);
  };
  const handleDeleteServiceDeleteModal = (values) => {
    console.log(values, ' id numaralı servis silindi.');
    handleCloseServiceDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <ServiceAddModal
          show={showServiceAddModal}
          handleClose={handleCloseServiceAddModal}
          handleAdd={handleAddServiceAddModal}
        />
        <ServicePlannerModal
          show={showServicePlannerModal}
          handleClose={handleCloseServicePlannerModal}
        />
        <ServiceIncomingCallsModal
          show={showServiceIncomingCallsModal}
          handleClose={handleCloseServiceIncomingCallsModal}
          handleSave={handleSaveServiceIncomingCallsModal}
        />
        <BonusCalculatorModal
          show={showBonusCalculatorModal}
          handleClose={handleCloseBonusCalculatorModal}
        />
        <ServiceReportsModal
          show={showServiceReportsModal}
          handleClose={handleCloseServiceReportsModal}
        />
        <DeleteModal
          show={showServiceDeleteModal}
          handleClose={handleCloseServiceDeleteModal}
          handleDelete={handleDeleteServiceDeleteModal}
          title={"Servisi Silmek İstiyor Musunuz?"}
          message={`${inputDataForServiceDeleteModal} id numaralı servisi silmek istiyor musunuz?`}
          deleteIcon={<TbFolderX className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='Servisler' Operations={operations} Table={table} Modals={modals} />
  );
}

export default Services;