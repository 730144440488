// General
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllOffers, getOfferById, getOfferPDFById, getAllOffersByDate, addNewOffer, updateOffer, deleteOffer, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetGetAllStatusByDate, resetError } from '../../../storages/slices/backend/OfferSlice';
// Icons
import { IoAddCircle, IoCheckmarkCircle, IoRemoveCircle } from "react-icons/io5";
import { TbPrinter, } from "react-icons/tb";
import { BsFileEarmarkPdf } from "react-icons/bs";
//Modals
import OffersAddModal from '../modals/offers/OffersAddModal';
import DeleteModal from '../modals/DeleteModal';
import OffersUpdateModal from '../modals/offers/OffersUpdateModal';
import LoadingModal from '../modals/LoadingModal';
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Offers() {
  // Storage
  const dispatch = useDispatch();
  const { offers, offer, getAllStatus, getByIdStatus, getAllStatusByDate, addStatus, updateStatus, deleteStatus, error, pdfLoading } = useSelector((state) => state.offer);
  //Variables
  const [loadingPDF, setLoadingPDF] = useState(false);
  // Filtering
  const [filterStartDate, setFilterStartDate] = useState(new Date().toLocaleDateString());
  const [filterEndDate, setFilterEndDate] = useState(new Date().toLocaleDateString());
  const handleSetDates = (startDate, endDate) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatus === "fulfilled") {
      const data = offers.map((data) => {
        const id = data.id;
        const date = data.date
        const customerName = data.customerName;
        const phone = data.customerPhone1;
        const customerAddress = data.customerAddress;
        const district = data.district;
        const city = data.city;
        const total = data.total;
        const status = data.status;
        const addressString = `${customerName} - ${phone}\n${customerAddress}\n${district} / ${city}`;
        const customerInfo = (
          <div className='d-flex flex-column text-start justify-content-start align-items-start'>
            <p className='m-0 p-0'>{customerName} - {phone} </p>
            <p className='m-0 p-0'>{customerAddress}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        )
        const statusstyle = (
          <p className='m-0 p-0  fw-bold'>{status}</p>
        )
        return { id, date, customerName, phone, customerAddress, district, city, addressString, customerInfo, total, status, statusstyle };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customerInfo: item.customerInfo, total: item.total, statusstyle: item.statusstyle, searchAddress: item.addressString, searchStatus: item.status })));
      setDataToPrint(data.map(item => ({ id: item.id, date: item.date, addressString: item.addressString, total: item.total, statusstyle: item.status })));
    }
  }, [getAllStatus, offers, filterStartDate, filterEndDate]);
  useEffect(() => {
    dispatch(getAllOffers());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseOffersAddModal();
      dispatch(getAllOffers());
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Teklif Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseOffersUpdateModal();
      dispatch(getAllOffers());
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'update', message: 'Teklif Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseOffersDeleteModal();
      dispatch(getAllOffers());
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'delete', message: 'Teklif Bilgileri Silindi!', delay: 6000 }));
    }
    if (getByIdStatus === 'fulfilled') {
      setInputDataForOffersUpdateModal(offer);
      setShowOffersUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    if (pdfLoading) {
      setLoadingPDF(true);
    }
    else {
      setLoadingPDF(false);
    }
  }, [addStatus, updateStatus, deleteStatus, getByIdStatus, pdfLoading, dispatch]);
  useEffect(() => {
    if (error) {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customerInfo', name: 'MÜŞTERİ BİLGİLERİ' }, { value: 'total', name: 'GENEL TOPLAM' }, { value: 'statusstyle', name: 'DURUM' }];
  const filters = ['id', 'date', 'searchAddress', 'total', 'searchStatus'];
  const select = (id) => {
    handleShowOffersUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button onClick={(e) => { e.stopPropagation(); dispatch(getOfferPDFById({ id: rowData.id })); }} className="button-with-icon btn btn-success mx-1"><BsFileEarmarkPdf className='button-icon' />PDF Ekle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowOffersUpdateModal(rowData.id); }} className="button-with-icon btn btn-primary mx-1"><IoCheckmarkCircle className='button-icon' />Güncelle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowOffersDeleteModal(rowData.id); }} className="button-with-icon btn btn-danger mx-1"><IoRemoveCircle className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
          bodyAlignBroker={['customerInfo']}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, dataToTable]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowOffersAddModal(); }} className="dropdown-item"><IoAddCircle style={{ fontSize: '1.5em' }} /> Teklif Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPrint, cols: cols, pdftitle: "Teklifler" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Offers Add Modal
  const [showOffersAddModal, setShowOffersAddModal] = useState(false);
  const handleCloseOffersAddModal = () => { setShowOffersAddModal(false) };
  const handleShowOffersAddModal = () => { setShowOffersAddModal(true) };
  const handleAddOffersAddModal = (values) => {
    console.log(values);
    dispatch(addNewOffer({ newoffer: values }));
    handleCloseOffersAddModal();
  };
  // Offers Update Modal
  const [showOffersUpdateModal, setShowOffersUpdateModal] = useState(false);
  const [inputDataForOffersUpdateModal, setInputDataForOffersUpdateModal] = useState({});
  const handleCloseOffersUpdateModal = () => {
    setShowOffersUpdateModal(false)
    setInputDataForOffersUpdateModal({});
  };
  const handleShowOffersUpdateModal = (id) => {
    dispatch(getOfferById({ id: id }));
  };
  const handleUpdateOffersUpdateModal = (values) => {
    console.log('Teklif güncellendi: ', inputDataForOffersUpdateModal, ' --> ', values);
    dispatch(updateOffer({ offer: values }));
    handleCloseOffersUpdateModal();
  };
  // Offers Delete Modal
  const [showOffersDeleteModal, setShowOffersDeleteModal] = useState(false);
  const [inputDataForOffersDeleteModal, setInputDataForOffersDeleteModal] = useState(-1);
  const handleCloseOffersDeleteModal = () => {
    setShowOffersDeleteModal(false)
    setInputDataForOffersDeleteModal(-1);
  };
  const handleShowOffersDeleteModal = (id) => {
    setInputDataForOffersDeleteModal(id);
    setShowOffersDeleteModal(true);
  };
  const handleDeleteOffersDeleteModal = (values) => {
    console.log(values, ' id numaralı Teklif silindi.');
    if (inputDataForOffersDeleteModal !== -1) { dispatch(deleteOffer({ id: inputDataForOffersDeleteModal })); }
    handleCloseOffersDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <OffersAddModal
          show={showOffersAddModal}
          handleClose={handleCloseOffersAddModal}
          handleAdd={handleAddOffersAddModal}>
        </OffersAddModal>
        <OffersUpdateModal
          show={showOffersUpdateModal}
          handleClose={handleCloseOffersUpdateModal}
          handleUpdate={handleUpdateOffersUpdateModal}
          inputData={inputDataForOffersUpdateModal}>
        </OffersUpdateModal>
        <DeleteModal
          show={showOffersDeleteModal}
          handleClose={handleCloseOffersDeleteModal}
          handleDelete={handleDeleteOffersDeleteModal}
          title="Teklifi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForOffersDeleteModal} numaralı teklifi silmek istediğinize emin misiniz?`}
          deleteIcon={<IoRemoveCircle className='button-icon' />}
        >
        </DeleteModal>
        <LoadingModal show={loadingPDF} />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Teklifler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Offers;