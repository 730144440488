// General
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";
// Components
import Table from '../../components/Table';

function BonusCalculatorModal({ show, handleClose }) {
  // Context Import
  const { today } = useData();
  const { stringMoney } = useFunctions();
  // Temp Data
  const data = [];
  const types = [];
  const people = [];
  const statuses = [];
  // Filtering
  const [startdate, setStartdate] = useState(today);
  const [enddate, setEnddate] = useState(today);
  const [employeeType, setEmployeeType] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [status, setStatus] = useState('');
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>Prim Hesapla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row g-2'>
          <div className='col-12'>
            <div className='card bg-light'>
              <div className='card-body'>
                <div className='row g-2'>
                  <div className="col-12 col-md-2">
                    <TextField
                      label="Başlangıç tarihi"
                      type="date"
                      value={startdate}
                      onChange={(e) => setStartdate(e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <TextField
                      label="Bitiş tarihi"
                      type="date"
                      value={enddate}
                      onChange={(e) => setEnddate(e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <Autocomplete
                      value={employeeType}
                      onChange={(event, newValue) => setEmployeeType(newValue)}
                      options={types}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Personel Türü" />}
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <Autocomplete
                      value={employeeName}
                      onChange={(event, newValue) => setEmployeeName(newValue)}
                      options={people}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Personel Adı" />}
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <Autocomplete
                      value={status}
                      onChange={(event, newValue) => setStatus(newValue)}
                      options={statuses}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Durum" />}
                    />
                  </div>
                  <div className=' col-12 col-md-2'>
                    <button className='button-with-icon btn btn-primary w-100'><TbDatabaseShare className='button-icon' />Veri Getir</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <Table
              data={data.map(item => ({ ...item, bonus: stringMoney(item.bonus, 'TL') }))}
              rows={5}
              columns={[{ value: 'id', name: 'ID' }, { value: 'name', name: 'PERSONEL ADI' }, { value: 'type', name: 'PERSONEL TÜRÜ' }, { value: 'date', name: 'TARİH' }, { value: 'bonus', name: 'PRİM' }, { value: 'status', name: 'DURUM' }]}
              headerAlign='center'
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BonusCalculatorModal;