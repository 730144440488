// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { RiFolderSettingsLine } from "react-icons/ri";

function OperationReportsUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { validationSchema_OperationReports } = useValidations();
  const { stringOnlyNumbers } = useFunctions();

  // Formik Variables
  const initialValues = {
    serviceID: inputData.serviceID || '',
    problem: inputData.problem || '',
    operations: inputData.operations || '',
    information: inputData.information || '',
    cost: inputData.cost || '',
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>İşlem raporunu güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_OperationReports} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_serviceID'
                      name='serviceID'
                      label='Servis İd *'
                      value={values.serviceID}
                      onChange={(e) => { setFieldValue('serviceID', stringOnlyNumbers(e.target.value)); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.serviceID && !!errors.serviceID}
                      helperText={touched.serviceID && errors.serviceID}
                      onFocus={() => { setFieldTouched('serviceID', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_problem'
                      name='problem'
                      label="Arıza *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.problem && !!errors.problem}
                      helperText={touched.problem && errors.problem}
                      onFocus={() => { setFieldTouched('problem', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_operations'
                      name='operations'
                      label="Yapılanlar *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.operations && !!errors.operations}
                      helperText={touched.operations && errors.operations}
                      onFocus={() => { setFieldTouched('operations', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_information'
                      name='information'
                      label="Bilgiler *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.information && !!errors.information}
                      helperText={touched.information && errors.information}
                      onFocus={() => { setFieldTouched('information', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_cost'
                      name='cost'
                      label='Maliyet *'
                      value={values.cost}
                      onChange={(e) => { setFieldValue('cost', stringOnlyNumbers(e.target.value)); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.cost && !!errors.cost}
                      helperText={touched.cost && errors.cost}
                      onFocus={() => { setFieldTouched('cost', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' ><RiFolderSettingsLine className='button-icon' />Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default OperationReportsUpdateModal