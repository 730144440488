// General
import React from 'react';
// styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Components
import CheckoutType from '../../components/CheckoutType';
// Icons
import { MdOutlineEdit } from 'react-icons/md';
import { TbArrowBackUpDouble } from 'react-icons/tb';

function CheckoutUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { validationSchema_Checkout } = useValidations();
  // Temp Variables
  const directions = ['Gelen Ödeme', 'Giden Ödeme'];
  const methods = ['Nakit', 'EFT / Havale', 'Kredi Kartı']
  const types = ['Depo Stok', 'Servis Para', 'Servis Parça', 'Maaş', 'Ofis', 'Kira', 'Prim', 'Google Reklam', 'Araç Bakım', 'Araç Yakıt', 'Mutfak / Yemek', 'Müşteri İade', 'Diğer / Muhtelif'];
  const statuses = ['Tamamlandı', 'Beklemede'];
  const currencies = ['TL', 'USD', 'EURO'];
  // Formik Variables
  const initialValues = {
    date: inputData.date || '',
    time: inputData.time || '',
    direction: inputData.direction || '',
    method: inputData.method || 'Nakit',
    type: inputData.type || '',
    status: inputData.status || 'Tamamlandı',
    amount: inputData.amount || '',
    currency: inputData.currency || 'TL',
    description: inputData.description || '',
    supplier: inputData.supplier || '',
    employee: inputData.employee || '',
    serviceId: inputData.serviceId || '',
    brand: inputData.brand || '',
    device: inputData.device || '',
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Yeni Kasa İşlemi Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validationSchema_Checkout} onSubmit={(values) => handleUpdate(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2'>
                <div className='col-12 col-md-8'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Ödeme Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='time'
                    name='time'
                    label='Saat'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.time}
                    onChange={(e) => setFieldValue('time', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.time && !!errors.time}
                    helperText={touched.time && errors.time}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="direction"
                    name="direction"
                    value={values.direction}
                    onChange={(event, newValue) => { setFieldValue('direction', newValue); }}
                    onBlur={() => { setFieldTouched('direction', true); }}
                    variant='outlined'
                    size='small'
                    options={directions}
                    renderInput={(params) => <TextField {...params} label="Ödeme Yönü" error={touched.direction && !!errors.direction} helperText={touched.direction && errors.direction} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="method"
                    name="method"
                    value={values.method}
                    onChange={(event, newValue) => { setFieldValue('method', newValue); }}
                    onBlur={() => { setFieldTouched('method', true); }}
                    variant='outlined'
                    size='small'
                    options={methods}
                    renderInput={(params) => <TextField {...params} label="Ödeme Şekli" error={touched.method && !!errors.method} helperText={touched.method && errors.method} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="type"
                    name="type"
                    value={values.type}
                    onChange={(event, newValue) => { setFieldValue('type', newValue); }}
                    onBlur={() => { setFieldTouched('type', true); }}
                    variant='outlined'
                    size='small'
                    options={types}
                    renderInput={(params) => <TextField {...params} label="Ödeme Türü" error={touched.type && !!errors.type} helperText={touched.type && errors.type} />}
                  />
                </div>
                <CheckoutType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} type={values.type} />
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                    onBlur={() => { setFieldTouched('status', true); }}
                    variant='outlined'
                    size='small'
                    options={statuses}
                    renderInput={(params) => <TextField {...params} label="Ödeme Durumu" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='amount'
                    name='amount'
                    label="Tutar"
                    value={values.amount}
                    onChange={(e) => setFieldValue('amount', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="currency"
                    name="currency"
                    value={values.currency}
                    onChange={(event, newValue) => { setFieldValue('currency', newValue); }}
                    onBlur={() => { setFieldTouched('currency', true); }}
                    variant='outlined'
                    size='small'
                    options={currencies}
                    renderInput={(params) => <TextField {...params} label="Birim" error={touched.currency && !!errors.currency} helperText={touched.currency && errors.currency} />}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Kaydetmeden Çık</button>
                </div>
                <div className='col-12 col-md-6'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><MdOutlineEdit className='button-icon' />İşlemi Güncelle</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default CheckoutUpdateModal;