// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';

// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { AiOutlineProduct } from "react-icons/ai";

function WarehouseUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { validationSchema_Warehouse } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  // Variables
  const suppliers = ['Emir', 'İsa', 'Furkan'];
  const brands = ['Apple', 'Samsung', 'Vestel',];
  const devices = ['Macbook M1', 'Samsung Galaxy', 'İphone 16'];
  const warehousenames = ['Depo 1', 'Depo 2', 'Depo 3'];
  const shelfnames = ['Raf 1', 'Raf 2', 'Raf 3'];
  // Formik Variables
  const initialValues = {
    brand: inputData.brand || '',
    device: inputData.device || '',
    warehouseName: inputData.warehouseName || '',
    shelfName: inputData.shelfName || '',
    barcode: inputData.barcode || '',
    productName: inputData.productName || '',
    supplier: inputData.supplier || '',
    piece: inputData.piece || '',
    purchasePrice: inputData.purchasePrice || '',
    salePrice: inputData.salePrice || '',
    dolarPrice: inputData.dolarPrice || '',
    productCode: inputData.productCode || '',
    explanation: inputData.explanation || ''
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title> Ürün Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_Warehouse} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_productName'
                      name='productName'
                      label='Ürün Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.productName && !!errors.productName}
                      helperText={touched.productName && errors.productName}
                      onFocus={() => { setFieldTouched('productName', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="WarehouseUpdateModal_supplier"
                      name="supplier"
                      options={suppliers.map((option) => option)}
                      value={values.supplier}
                      onChange={(event, newValue) => { setFieldValue('supplier', newValue); }}
                      onBlur={() => { setFieldTouched('supplier', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Tedarikçi *" error={touched.supplier && !!errors.supplier}
                        helperText={touched.supplier && errors.supplier} onFocus={() => {
                          setFieldTouched('supplier', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_productCode'
                      name='productCode'
                      label='Ürün Kodu *'
                      value={values.productCode}
                      onChange={(e) => setFieldValue("productCode", stringMaxLenght(stringOnlyNumbers(e.target.value)))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.productCode && !!errors.productCode}
                      helperText={touched.productCode && errors.productCode}
                      onFocus={() => { setFieldTouched('productCode', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_barcode'
                      name='barcode'
                      label='Barkod *'
                      value={values.barcode}
                      onChange={(e) => setFieldValue("barcode", stringMaxLenght(stringOnlyNumbers(e.target.value)))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.barcode && !!errors.barcode}
                      helperText={touched.barcode && errors.barcode}
                      onFocus={() => { setFieldTouched('barcode', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={Autocomplete}
                      id="WarehouseUpdateModal_brand"
                      name="brand"
                      options={brands.map((option) => option)}
                      value={values.brand}
                      onChange={(event, newValue) => { setFieldValue('brand', newValue); }}
                      onBlur={() => { setFieldTouched('brand', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Marka *" error={touched.brand && !!errors.brand}
                        helperText={touched.brand && errors.brand} onFocus={() => {
                          setFieldTouched('brand', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={Autocomplete}
                      id="WarehouseUpdateModal_device"
                      name="device"
                      options={devices.map((option) => option)}
                      value={values.device}
                      onChange={(event, newValue) => { setFieldValue('device', newValue); }}
                      onBlur={() => { setFieldTouched('device', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Cihaz *" error={touched.device && !!errors.device}
                        helperText={touched.device && errors.device} onFocus={() => {
                          setFieldTouched('device', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={Autocomplete}
                      id="WarehouseUpdateModal_warehouseName"
                      name="warehouseName"
                      options={warehousenames.map((option) => option)}
                      value={values.warehouseName}
                      onChange={(event, newValue) => { setFieldValue('warehouseName', newValue); }}
                      onBlur={() => { setFieldTouched('warehouseName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Depo *" error={touched.warehouseName && !!errors.warehouseName}
                        helperText={touched.warehouseName && errors.warehouseName} onFocus={() => {
                          setFieldTouched('warehouseName', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={Autocomplete}
                      id="WarehouseUpdateModal_shelfName"
                      name="shelfName"
                      options={shelfnames.map((option) => option)}
                      value={values.shelfName}
                      onChange={(event, newValue) => { setFieldValue('shelfName', newValue); }}
                      onBlur={() => { setFieldTouched('shelfName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Raf *" error={touched.shelfName && !!errors.shelfName}
                        helperText={touched.shelfName && errors.shelfName} onFocus={() => {
                          setFieldTouched('shelfName', true);
                        }} />}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_purchasePrice'
                      name='purchasePrice'
                      label='Alış fiyatı *'
                      onInput={(e) => {
                        let value = e.target.value.replace(/[^\d.,]/g, '');
                        e.target.value = value;
                      }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.purchasePrice && !!errors.purchasePrice}
                      helperText={touched.purchasePrice && errors.purchasePrice}
                      onFocus={() => { setFieldTouched('purchasePrice', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_salePrice'
                      name='salePrice'
                      label='Satış fiyatı *'
                      onInput={(e) => {
                        let value = e.target.value.replace(/[^\d.,]/g, '');
                        e.target.value = value;
                      }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.salePrice && !!errors.salePrice}
                      helperText={touched.salePrice && errors.salePrice}
                      onFocus={() => { setFieldTouched('salePrice', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_dolarPrice'
                      name='dolarPrice'
                      label='Dolar fiyatı *'
                      onInput={(e) => {
                        let value = e.target.value.replace(/[^\d.,]/g, '');
                        e.target.value = value;
                      }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.dolarPrice && !!errors.dolarPrice}
                      helperText={touched.dolarPrice && errors.dolarPrice}
                      onFocus={() => { setFieldTouched('dolarPrice', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_piece'
                      name='piece'
                      label='Adet *'
                      type='number'
                      value={values.piece}
                      onChange={(e) => setFieldValue("piece", stringMaxLenght(stringOnlyNumbers(e.target.value)))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.piece && !!errors.piece}
                      helperText={touched.piece && errors.piece}
                      onFocus={() => { setFieldTouched('piece', true); }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='WarehouseUpdateModal_explanation'
                      name='explanation'
                      label="Açıklama *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.explanation && !!errors.explanation}
                      helperText={touched.explanation && errors.explanation}
                      onFocus={() => { setFieldTouched('explanation', true); }}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' ><AiOutlineProduct className='button-icon' />Ürün Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default WarehouseUpdateModal