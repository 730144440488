// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";

function ServiceReportsModal({ show, handleClose }) {
  // Context Import
  const { today } = useData();
  const { openNewPage } = useFunctions();
  // Temp Data
  const people = ['Emir', 'İsa', 'Furkan'];
  const vehicles = ['34 ITM 34', '34 PLT 34', '34 RTE 34'];
  const devices = ['Buz Dolabı', 'Çamaşır Makinesi', 'Fırın'];
  const sources = ['444 2356', '444 2720'];
  const brands = ['Vestel', 'Arçelik', 'Bosch']
  const stages = ['Atölyeye Aldır', 'Bayiye Gönder', 'Müşteri Cihazı Atölyeye Getirdi', 'Müşteri İptal Etti', 'Teknisyen Yönlendir', 'Ürün Satışı Yapıldı', 'Cihaz Atölyeye Alındı', 'Cihaz Tamir Edilemiyor', 'Fiyatta Anlaşılamadı', 'Haber Verecek', 'Müşteriye Ulaşılamadı', 'Parça Takmak İçin Teknisyene Yönlendir', 'Parça Talep Et', 'Parçası Atölyeye Alındı', 'Ürün Garantili Çıktı', 'Yeniden Teknisyen Yönlendir', 'Yerinde Bakım Yapıldı', 'Fiyat Yükseltildi', 'Nakliye Gönder', 'Tahsilata Gönder', 'Atölyede Tamir Ediliyor', 'Nakliyede (Teslim Edilecek)', 'Cihaz Teslim Edilemedi (Arızalı)', 'Cihaz Teslim Edildi (Parça Takıldı)', 'Cihaz Teslim Edildi', 'Parça Teslim Et', 'Parça Hazır', 'Parça Siparişte', 'Teslimata Hazır (Tamamlandı)', 'Şikayetçi', 'Müşteri Para İade Edilecek', 'Müşteri Para İade Edildi', 'Servisi Sonlandır'];
  // HTML
  return (
    <Modal show={show} onHide={handleClose} className='modal'>
      <Modal.Header className='py-1' closeButton>
        <Modal.Title> Servis Raporları</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Operatör Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={0} initialValues={{ startdate: today, enddate: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Operatörler' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Operatör" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Teknisyen Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={1} initialValues={{ date: today, vehicle: '', employee: '', helperemployee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='vehicle'
                          name='vehicle'
                          value={values.vehicle}
                          onChange={(event, newValue) => setFieldValue('vehicle', newValue)}
                          onBlur={() => { setFieldTouched('vehicle', true); }}
                          variant='outlined'
                          size='small'
                          options={vehicles}
                          renderInput={(params) => <TextField {...params} label="Araç" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Teknisyenler' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Teknisyen" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='helperemployee'
                          name='helperemployee'
                          value={values.helperemployee === '' ? 'Tüm Yardımcı Teknisyenler' : values.helperemployee}
                          onChange={(event, newValue) => setFieldValue('helperemployee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('helperemployee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Yardımcı Teknisyen" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Atölyeye Alınanlar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={2} initialValues={{ startdate: today, enddate: today, employee: '', device: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Personeller' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='device'
                          name='device'
                          value={values.device === '' ? 'Tüm Cihazlar' : values.device}
                          onChange={(event, newValue) => setFieldValue('device', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('device', true); }}
                          variant='outlined'
                          size='small'
                          options={devices}
                          renderInput={(params) => <TextField {...params} label="Cihaz" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Atölye Ustası Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={3} initialValues={{ date: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Ustalar' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Atölye Ustası" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Atölyede Tamir Edilen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={4} initialValues={{ date: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Ustalar' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Atölye Ustası" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Nakliye Gönder</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={5} initialValues={{ date: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Personeller' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header> Nakliyede (Teslim Edilecek)</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={6} initialValues={{ date: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Personeller' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header> Cihaz Teslim Edildi</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={7} initialValues={{ date: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Personeller' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header> Parça Bekleyen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={8} initialValues={{ date: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarihten Beri Parça Bekleyen"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header> Ürün Satışı Yapıldı</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={9} initialValues={{ startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Konsinye Cihazlar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={10} initialValues={{ device: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12">
                        <Field
                          as={Autocomplete}
                          id='device'
                          name='device'
                          value={values.device === '' ? 'Tüm Konsinye Cihazlar' : values.device}
                          onChange={(event, newValue) => setFieldValue('device', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('device', true); }}
                          variant='outlined'
                          size='small'
                          options={devices}
                          renderInput={(params) => <TextField {...params} label="Konsinye Cihaz" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>Silinen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={11} initialValues={{ startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>Silinen Para Hareketleri</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={12} initialValues={{ startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>Gelen Çağrılar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={13} initialValues={{ startdate: today, enddate: today, employee: '', source: '', brand: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Personeller' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='source'
                          name='source'
                          value={values.source === '' ? 'Tüm Kaynaklar' : values.source}
                          onChange={(event, newValue) => setFieldValue('source', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('source', true); }}
                          variant='outlined'
                          size='small'
                          options={sources}
                          renderInput={(params) => <TextField {...params} label="Kaynak" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='brand'
                          name='brand'
                          value={values.brand === '' ? 'Tüm Markalar' : values.brand}
                          onChange={(event, newValue) => setFieldValue('brand', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('brand', true); }}
                          variant='outlined'
                          size='small'
                          options={brands}
                          renderInput={(params) => <TextField {...params} label="Marka" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>Aşama Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={14} initialValues={{ startdate: today, enddate: today, stage: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='stage'
                          name='stage'
                          value={values.stage === '' ? 'Tüm Aşamalar' : values.stage}
                          onChange={(event, newValue) => setFieldValue('stage', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('stage', true); }}
                          variant='outlined'
                          size='small'
                          options={stages}
                          renderInput={(params) => <TextField {...params} label="Aşama" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>Bayi Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={15} initialValues={{ startdate: today, enddate: today, employee: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee === '' ? 'Tüm Bayiler' : values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={people}
                          renderInput={(params) => <TextField {...params} label="Bayi" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>Acil Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={16} initialValues={{ startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' onClick={() => openNewPage('servisler', values)} className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
    </Modal >
  );
}

export default ServiceReportsModal;