// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Components
import CustomerType from '../../components/CustomerType';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbFolderPlus, TbUserEdit, TbUserCog } from "react-icons/tb";
import { TbArrowBackUpDouble } from 'react-icons/tb';

function ServiceAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  // Context Import
  const { today, cities, sources, warranties } = useData();
  const { validationSchema_Service } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  // Variables
  const [isCustomerFound, setIsCustomerFound] = useState(false);
  const [city, setCity] = useState('');
  useEffect(() => {
    dispatch(getDistricts({ apikey: "tuyak_furkanerhan", city: city }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);
  // Filtering
  const todayDate = new Date().toLocaleDateString();
  const todayTime = new Date().toLocaleTimeString();
  // Formik Variables
  const initalValues = {
    id: '',
    name: '',
    surname: '',
    type: 'Bireysel',
    identityNo: '',
    taxNo: '',
    taxOffice: '',
    phone1: '',
    phone2: '',
    addressCity: '',
    addressDistrict: '',
    addressText: '',
    availableDate: today,
    availableStartTime: '09:00',
    availableEndTime: '20:00',
    brand: '',
    device: '',
    model: '',
    problem: '',
    note: '',
    warranty: '',
    source: '',
    emergency: false
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl'>
      <Modal.Body>
        <Formik initialValues={initalValues} validationSchema={validationSchema_Service} onSubmit={(values) => handleAdd(values)} >
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2 d-flex flex-row justify-content-end'>
                <div className='col-12 col-md-12'>
                  <div className='card bg-light m-0 p-1'>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <Field
                              as={TextField}
                              id="todaysdate"
                              name="todaysdate"
                              label="Bugünün Tarihi"
                              value={(todayDate + ' ' + todayTime)}
                              slotProps={{ input: { readOnly: true } }}
                              variant="outlined"
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6'>
                        <div className='row g-2 d-flex justify-content-end'>
                          <div className='col-12 col-md-6'>
                            <Field
                              as={Autocomplete}
                              id="source"
                              name="source"
                              value={values.source}
                              onChange={(event, newValue) => { setFieldValue('source', newValue); }}
                              onBlur={() => { setFieldTouched('source', true); }}
                              variant='outlined'
                              size='small'
                              options={sources}
                              renderInput={(params) => <TextField {...params} label="Kaynak" error={touched.source && !!errors.source} helperText={touched.source && errors.source} />}
                            />
                          </div>
                          <div className='col-12 col-md-2'>
                            <Field
                              as={FormControlLabel}
                              id="isemergency"
                              name="isemergency"
                              control={<Checkbox />}
                              value={values.emergency}
                              onChange={e => setFieldValue('emergency', e.target.value)}
                              label="ACİL!"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-stretch'>
                  <div className='card bg-light m-0 flex-fill'>
                    <div className='card-header d-flex justify-content-between py-1'>
                      <div className='card-title my-1'>Müşteri Bilgileri</div>
                      <div>
                        {isCustomerFound && (
                          <button type='button' className='btn btn-outline-warning mx-1 p-1'><TbUserEdit style={{ fontSize: '1.5em' }} /></button>
                        )}
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="phone1"
                            name="phone1"
                            label="Birinci Telefon"
                            value={values.phone1}
                            onChange={(e) => {
                              setFieldValue("phone1", stringMaxLenght(stringOnlyNumbers(e.target.value), 10));
                              setIsCustomerFound(false);
                              // Get Data
                            }}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.phone1 && !!errors.phone1}
                            helperText={touched.phone1 && errors.phone1}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="phone2"
                            name="phone2"
                            label="İkinci Telefon"
                            value={values.phone2}
                            onChange={(e) => setFieldValue('phone2', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.phone2 && !!errors.phone2}
                            helperText={touched.phone2 && errors.phone2}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <Field
                            as={TextField}
                            id="name"
                            name="name"
                            label="Müşteri Adı"
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <Field
                            as={TextField}
                            id="surname"
                            name="surname"
                            label="Müşteri Soyadı"
                            value={values.surname}
                            onChange={(e) => setFieldValue('surname', e.target.value)}
                            variant="outlined"
                            size="small"
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            fullWidth
                            error={touched.surname && !!errors.surname}
                            helperText={touched.surname && errors.surname}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="type"
                            name="type"
                            value={values.type}
                            onChange={(event, newValue) => { setFieldValue('type', newValue); }}
                            onBlur={() => { setFieldTouched('type', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            options={['Bireysel', 'Kurumsal']}
                            renderInput={(params) => <TextField {...params} label="Müşteri Türü" error={touched.type && !!errors.type} helperText={touched.type && errors.type} />}
                          />
                        </div>
                        <CustomerType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} readOnly={isCustomerFound} />
                        <div className='col-12 col-md-6'>
                          <Field
                            as={Autocomplete}
                            id="addressCity"
                            name="addressCity"
                            value={values.addressCity}
                            onChange={(event, newValue) => { setCity(newValue); setFieldValue('addressCity', newValue); setFieldValue('addressDistrict', ''); }}
                            onBlur={() => { setFieldTouched('addressCity', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            options={cities}
                            renderInput={(params) => <TextField {...params} label="İl" error={touched.addressCity && !!errors.addressCity} helperText={touched.addressCity && errors.addressCity} />}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <Field
                            as={Autocomplete}
                            id="addressDistrict"
                            name="addressDistrict"
                            value={values.addressDistrict}
                            onChange={(event, newValue) => { setFieldValue('addressDistrict', newValue); }}
                            onBlur={() => { setFieldTouched('addressDistrict', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            disabled={values.addressCity === ''}
                            options={districts}
                            renderInput={(params) => <TextField {...params} label="İlçe" error={touched.addressDistrict && !!errors.addressDistrict} helperText={touched.addressDistrict && errors.addressDistrict} />}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="addressText"
                            name="addressText"
                            label="Adres"
                            value={values.addressText}
                            onChange={(e) => setFieldValue('addressText', e.target.value)}
                            multiline
                            minRows={3}
                            variant="outlined"
                            size="small"
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            fullWidth
                            error={touched.addressText && !!errors.addressText}
                            helperText={touched.addressText && errors.addressText}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-stretch'>
                  <div className='card bg-light m-0 flex-fill'>
                    <div className='card-header py-1'>
                      <div className='card-title my-1'>Cihaz Bilgileri</div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-6'>
                          <Field
                            as={Autocomplete}
                            id="brand"
                            name="brand"
                            value={values.brand}
                            onChange={(event, newValue) => { setFieldValue('brand', newValue); }}
                            onBlur={() => { setFieldTouched('brand', true); }}
                            variant='outlined'
                            size='small'
                            options={[]}
                            renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.brand && !!errors.brand} helperText={touched.brand && errors.brand} />}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <Field
                            as={Autocomplete}
                            id="device"
                            name="device"
                            value={values.device}
                            onChange={(event, newValue) => { setFieldValue('device', newValue); }}
                            onBlur={() => { setFieldTouched('device', true); }}
                            variant='outlined'
                            size='small'
                            options={[]}
                            renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.device && !!errors.device} helperText={touched.device && errors.device} />}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='model'
                            name='model'
                            label="Cihaz Modeli"
                            value={values.model}
                            onChange={(e) => setFieldValue('model', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.model && !!errors.model}
                            helperText={touched.model && errors.model}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='problem'
                            name='problem'
                            label="Cihaz Arızası"
                            value={values.problem}
                            onChange={(e) => setFieldValue('problem', e.target.value)}
                            slotProps={{ input: { resize: "none" } }}
                            multiline
                            minRows={2}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.problem && !!errors.problem}
                            helperText={touched.problem && errors.problem}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='note'
                            name='note'
                            label="Operator Notu"
                            value={values.note}
                            onChange={(e) => setFieldValue('note', e.target.value)}
                            slotProps={{ input: { resize: "none" } }}
                            multiline
                            minRows={2}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.note && !!errors.note}
                            helperText={touched.note && errors.note}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="warranty"
                            name="warranty"
                            value={values.warranty}
                            onChange={(event, newValue) => { setFieldValue('warranty', newValue); }}
                            onBlur={() => { setFieldTouched('warranty', true); }}
                            variant='outlined'
                            size='small'
                            options={warranties.map(item => item === 0 ? 'Garanti Yok' : `${item} Ay`)}
                            renderInput={(params) => <TextField {...params} label="Cihaz Garantisi" error={touched.warranty && !!errors.warranty} helperText={touched.warranty && errors.warranty} />}
                          />
                        </div>
                        <div className='col-6 col-md-6'>
                          <Field
                            as={TextField}
                            id='availableDate'
                            name='availableDate'
                            label='Müsait Zaman'
                            type="date"
                            variant='outlined'
                            size='small'
                            value={values.availableDate}
                            onChange={(e) => setFieldValue('availableDate', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.availableDate && !!errors.availableDate}
                            helperText={touched.availableDate && errors.availableDate}
                          />
                        </div>
                        <div className='col-6 col-md-3'>
                          <Field
                            as={TextField}
                            id='availableStartTime'
                            name='availableStartTime'
                            label='Başlangıç'
                            type="time"
                            variant='outlined'
                            size='small'
                            value={values.availableStartTime}
                            onChange={(e) => setFieldValue('availableStartTime', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.availableStartTime && !!errors.availableStartTime}
                            helperText={touched.availableStartTime && errors.availableStartTime}
                          />
                        </div>
                        <div className='col-6 col-md-3'>
                          <Field
                            as={TextField}
                            id='availableEndTime'
                            name='availableEndTime'
                            label='Bitiş'
                            type="time"
                            variant='outlined'
                            size='small'
                            value={values.availableEndTime}
                            onChange={(e) => setFieldValue('availableEndTime', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.availableEndTime && !!errors.availableEndTime}
                            helperText={touched.availableEndTime && errors.availableEndTime}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12'>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-3'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Kaydetmeden Çık</button>
                    </div>
                    <div className='col-12 col-md-3'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'><TbFolderPlus className='button-icon' />Servisi Ekle</button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ServiceAddModal;