// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import {
  getAllDevices,
  getDeviceById,
  addNewDevice,
  updateDevice,
  deleteDevice,
  resetAddStatus,
  resetUpdateStatus,
  resetDeleteStatus,
  resetgetByIdStatus
} from '../../../storages/slices/backend/DeviceSlice';
// Icons
import { FaPlugCirclePlus, FaPlugCircleCheck, FaPlugCircleMinus } from "react-icons/fa6";
import { TbPrinter } from "react-icons/tb";
// Modals
import DeviceAddModal from "../modals/devices/DeviceAddModal";
import DeviceUpdateModal from "../modals/devices/DeviceUpdateModal";
import DeleteModal from "../modals/DeleteModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Devices() {
  // Storage Import
  const dispatch = useDispatch();
  const { devices, device, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.device);
  // Data & Api
  useEffect(() => {
    dispatch(getAllDevices());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllDevices());
      dispatch(addToast({ background: 'success', icon: 'plugAdd', message: 'Yeni Cihaz Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseDeviceUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllDevices());
      dispatch(addToast({ background: 'primary', icon: 'plugUpdate', message: 'Cihaz Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleDeleteDeviceDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllDevices());
      dispatch(addToast({ background: 'danger', icon: 'plugDelete', message: 'Cihaz Silindi!', delay: 6000 }));
    }
    if (getByIdStatus === 'fulfilled') {
      setInputDataForDeviceUpdateModal(device);
      setShowDeviceUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, getByIdStatus, dispatch]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 })); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'CİHAZ ADI' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => {
    handleShowDeviceUpdateModal(id);
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowDeviceUpdateModal(rowData.id); }}><FaPlugCircleCheck className='button-icon' />Güncelle</button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowDeviceDeleteModal(rowData.id); }}><FaPlugCircleMinus className='button-icon' />Sil</button>
      </div >
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={devices}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowDeviceAddModal(); }}><FaPlugCirclePlus className='button-icon' /> Yeni Cihaz Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); dispatch(exportPdf({ rowsPerPage: rows, data: devices, cols: cols, pdftitle: "Cihazlar" })) }} className="dropdown-item"><TbPrinter className='button-icon' />  Yazdır</button >
      </>
    );
  }
  // Modals
  // Device Add Modal
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => { setShowDeviceAddModal(false) };
  const handleShowDeviceAddModal = () => { setShowDeviceAddModal(true) };
  const handleAddDeviceAddModal = (values) => {
    console.log(values);
    dispatch(addNewDevice({ newdevice: values }));
    handleCloseDeviceAddModal();
  };
  // Device Update Modal
  const [showDeviceUpdateModal, setShowDeviceUpdateModal] = useState(false);
  const [inputDataForDeviceUpdateModal, setInputDataForDeviceUpdateModal] = useState({});
  const handleCloseDeviceUpdateModal = () => {
    setShowDeviceUpdateModal(false)
    setInputDataForDeviceUpdateModal({});
  };
  const handleShowDeviceUpdateModal = (id) => {
    dispatch(getDeviceById({ id: id }));
  }
  const handleUpdateDeviceUpdateModal = (values) => {
    console.log('Cihaz güncellendi: ', inputDataForDeviceUpdateModal, ' --> ', values);
    dispatch(updateDevice({ device: values }));
    handleCloseDeviceUpdateModal();
  };
  // Device Delete Modal
  const [showDeviceDeleteModal, setShowDeviceDeleteModal] = useState(false);
  const [inputDataForDeviceDeleteModal, setInputDataForDeviceDeleteModal] = useState(-1);
  const handleCloseDeviceDeleteModal = () => {
    setShowDeviceDeleteModal(false)
    setInputDataForDeviceDeleteModal(-1);
  };
  const handleShowDeviceDeleteModal = (id) => {
    setInputDataForDeviceDeleteModal(id);
    setShowDeviceDeleteModal(true);
  };
  const handleDeleteDeviceDeleteModal = (values) => {
    console.log(values, ' id numaralı Cihaz silindi.');
    if (inputDataForDeviceDeleteModal !== -1) { dispatch(deleteDevice({ id: inputDataForDeviceDeleteModal })); }
    handleCloseDeviceDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <DeviceAddModal
          show={showDeviceAddModal}
          handleClose={handleCloseDeviceAddModal}
          handleAdd={handleAddDeviceAddModal}
        />
        <DeviceUpdateModal
          show={showDeviceUpdateModal}
          handleClose={handleCloseDeviceUpdateModal}
          handleUpdate={handleUpdateDeviceUpdateModal}
          inputData={inputDataForDeviceUpdateModal}
        />
        <DeleteModal
          show={showDeviceDeleteModal}
          handleClose={handleCloseDeviceDeleteModal}
          handleDelete={handleDeleteDeviceDeleteModal}
          title="Cihazı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForDeviceDeleteModal} id numaralı cihazı silmek istediğinize emin misiniz?`}
          deleteIcon={<FaPlugCircleMinus className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Cihazlar' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Devices;