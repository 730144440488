// General
import React, { useRef, useState } from 'react';
// Styles
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { TextField } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { setDtFilterValue, setFilterPageIndex } from '../../../storages/slices/PrintSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";

function Table({ data, rows, columns, filternames = null, select = null, dates = null, buttons = null, check = null, colors = null, headerAlign = 'left', paginatorColor = 'bg-white', bodyAlignBroker = [], searchfullwidth = false }) {
  // Storage Import
  const dispatch = useDispatch();
  const dtRef = useRef(null);
  const reduxFilterPageIndex = useSelector((state) => state.print.filterpageindex);
  // Context Import
  const { today } = useData();
  // Filtering
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [filters, setFilters] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [filterValue, setFilterValue] = useState('');
  const onFilterChange = (e) => {
    const value = e.target.value;
    setFilterValue(value);
    setFilters({
      ...filters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    });
    dispatch(setDtFilterValue(value))
  };
  const onPageChange = (e) => {
    dispatch(setFilterPageIndex(e.first / e.rows))
  }
  // HTML
  return (
    <>
      <div className='row g-2 mb-3'>
        {filternames && (
          <div className={`${searchfullwidth && !dates ? 'col-12 ' : 'col-12 col-md-6'}`}>
            <div className='row'>
              <div className={`${searchfullwidth && !dates ? 'col-12' : 'col-12 col-md-8'}`}>
                <TextField
                  label="Ara"
                  value={filterValue}
                  onChange={onFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
            </div>
          </div>
        )}
        {dates && (
          <div className='col-12 col-md-6'>
            <div className='row g-2'>
              <div className='col-12  col-md-4'>
                <TextField
                  label='Başlangıç Tarihi'
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  slotProps={{ input: { shrink: "true" } }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </div>
              <div className='col-12  col-md-4'>
                <TextField
                  label='Bitiş Tarihi'
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  slotProps={{ input: { shrink: "true" } }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </div>
              <div className='col-12  col-md-4'>
                <button type='button' className='button-with-icon btn btn-primary w-100 h-100' onClick={() => dates(startDate, endDate)}>
                  <TbDatabaseShare className='button-icon' />
                  Veri Getir
                </button>
              </div>
            </div>
          </div>
        )}
        <DataTable
          ref={dtRef}
          value={data}
          size='small'
          className={`${select ? 'activehover' : 'unactivehover'}`}
          stripedRows
          emptyMessage="Bu tabloda henüz veri yok!"
          rowClassName={colors ? colors : ``}
          showGridlines
          paginatorLeft
          paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
          paginator
          onPage={onPageChange}
          first={reduxFilterPageIndex * rows}
          paginatorClassName={paginatorColor}
          rows={rows}
          filters={filters}
          onSelectionChange={(e) => select && select((e.value.id))}
          selectionMode="single"
          globalFilterFields={filternames ? filternames : []}
        >
          {check && <Column align={headerAlign} body={check} bodyClassName="align-middle" />}
          {columns.map((column, index) => {
            return (
              <Column key={index} align={headerAlign} sortable field={column.value} header={column.name} bodyClassName={`align-middle fs-6 ${bodyAlignBroker.includes(column.value) ? 'text-left' : 'text-center'}`} />
            );
          })}
          {buttons && <Column align={headerAlign} body={buttons} bodyClassName="align-middle" />}
        </DataTable >
      </div>
    </>
  );
}

export default Table;