// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { LuArrowRightToLine } from "react-icons/lu";

function ShelveUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storage Import
  const { warehouses } = useSelector((state) => state.warehouses);
  // Context Import
  const { validationSchema_WarehouseShelf } = useValidations();
  // Formik Variables
  const initialValues = {
    id: inputData.id || 0,
    warehousename: inputData.warehousename || '',
    name: inputData.name || '',
    description: inputData.description || '',
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Raf Bilgilerini Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_WarehouseShelf} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)} >
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="ShelveAddModal_warehousename"
                      name="warehousename"
                      options={warehouses ? warehouses.map((item) => item.name) || [] : []}
                      value={values.warehousename}
                      onChange={(event, newValue) => { setFieldValue('warehousename', newValue); }}
                      onBlur={() => { setFieldTouched('warehousename', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Depo *" error={touched.warehousename && !!errors.warehousename} helperText={touched.warehousename && errors.warehousename} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ShelveAddModal_name'
                      name='name'
                      label='Raf Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ShelveAddModal_description'
                      name='description'
                      label="Açıklama"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' ><LuArrowRightToLine className='button-icon' />Raf Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShelveUpdateModal;