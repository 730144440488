// General
import React, { useCallback, useEffect, useState } from 'react';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbPrinter } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { HiMiniChevronDown } from 'react-icons/hi2';
import { TbBook2 } from 'react-icons/tb';
// Modals
import CheckoutAddModal from '../modals/checkout/CheckoutAddModal';
import CheckoutUpdateModal from '../modals/checkout/CheckoutUpdateModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import Table from '../components/Table';

function Checkout() {
  // Context Import
  const { stringMoney } = useFunctions();
  const { today } = useData();
  // Data
  const [startdate, setStartdate] = useState(today);
  const [enddate, setEnddate] = useState(today);
  const [data, setData] = useState([]);
  const [dataToTable, setDataToTable] = useState([]);
  useEffect(() => {
    const combined = [].map((data) => {
      const id = data.id;
      const date = data.date;
      const user = "";
      const type = data.type;
      const method = data.method;
      const direction = data.direction;
      const status = data.status;
      const amount = data.amount;
      const currency = data.currency;
      const description = data.description;
      const supplier = data.supplier;
      const employee = "";
      const serviceId = data.serviceId;
      const stockNo = "1002";
      const brand = "";
      const device = "";
      const finalamount = stringMoney(amount, currency);
      return { id: id, date: date, user: user, type: type, method: method, direction: direction, status: status, amount: amount, currency: currency, description: description, supplier: supplier, employee: employee, serviceId: serviceId, stockNo: stockNo, brand: brand, device: device, finalamount: finalamount };
    })
    setData(combined);
    setDataToTable(combined);
  }, []);
  // Table
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'user', name: 'PERSONEL' }, { value: 'description', name: 'AÇIKLAMA' }, { value: 'method', name: 'ŞEKLİ' }, { value: 'status', name: 'DURUM' }, { value: 'finalamount', name: 'TUTAR' }];
  const filternames = ['id', 'date', 'employee', 'type', 'description', 'method', 'direction', 'cost',];
  const buttons = (rowData) => {
    return (
      <div className='row g-2'>
        <div className='col-6 col-md-6'>
          <button className='button-with-icon btn btn-primary w-100' onClick={(e) => { e.stopPropagation(); handleShowCheckoutUpdateModal({}) }}><MdOutlineEdit className='button-icon' />Düzenle</button>
        </div>
        <div className='col-6 col-md-6'>
          <button className='button-with-icon btn btn-danger w-100' onClick={(e) => { e.stopPropagation(); handleShowCheckoutDeleteModal(rowData.id) }}><MdDeleteOutline className='button-icon' />Sil</button>
        </div>
      </div >
    );
  };
  // Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);
  // Modals
  // Checkout Add Modal
  const [showCheckoutAddModal, setShowCheckoutAddModal] = useState(false);
  const handleCloseCheckoutAddModal = () => { setShowCheckoutAddModal(false); }
  const handleShowCheckoutAddModal = () => { setShowCheckoutAddModal(true); }
  const handleAddCheckoutAddModal = (values) => {
    console.log('Yeni kasa işlemi eklendi: ', values);
    handleCloseCheckoutAddModal();
  }
  // Checkout Update Modal
  const [showCheckoutUpdateModal, setShowCheckoutUpdateModal] = useState(false);
  const [inputDataForCheckoutUpdateModal, setInputDataFoCheckoutUpdateModal] = useState({});
  const handleCloseCheckoutUpdateModal = () => {
    setShowCheckoutUpdateModal(false);
    setInputDataFoCheckoutUpdateModal({});
  }
  const handleShowCheckoutUpdateModal = (data) => {
    setInputDataFoCheckoutUpdateModal(data);
    setShowCheckoutUpdateModal(true);
  }
  const handleUpdateCheckoutUpdateModal = (values) => {
    console.log('Kasa İşlemleri Güncellendi: ', inputDataForCheckoutUpdateModal, ' ---> ', values);
    handleCloseCheckoutUpdateModal();
  }
  // Checkout Delete Modal
  const [showCheckoutDeleteModal, setShowCheckoutDeleteModal] = useState(false);
  const [inputDataForCheckoutDeleteModal, setInputDataFoCheckoutDeleteModal] = useState(-1);
  const handleCloseCheckoutDeleteModal = () => {
    setShowCheckoutDeleteModal(false);
    setInputDataFoCheckoutDeleteModal(-1);
  }
  const handleShowCheckoutDeleteModal = (data) => {
    setInputDataFoCheckoutDeleteModal(data);
    setShowCheckoutDeleteModal(true);
  }
  const handleDeleteCheckoutDeleteModal = (values) => {
    console.log(values, 'Id numaralı kasa işlemi silindi.');
    handleCloseCheckoutDeleteModal();
  }
  // HTML
  return (
    <div className='container'>
      <div className='card m-2'>
        <div className='card-header m-0 p-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-md-7'>
              <div className='row h-100 m-0 p-2'>
                <div className='d-flex align-items-center'>
                  <div className='card-title m-0 p-0'>Kasa</div>
                </div>
              </div>
            </div>
            <div className='col-12 ps-3 col-md-5 '>
              <div className='row d-flex justify-content-end m-1'>
                <div className='col-12 col-md-5 ps-0 '>
                  <div className="dropdown">
                    <button className="button-with-icon btn btn-success operationsdropdown  dropdown-toggle w-100" onClick={toggleOperationsDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <TbBook2 className='button-icon' />İşlemler<HiMiniChevronDown style={{ fontSize: '1.25em' }} />
                    </button>
                    {operationsDropdown && (
                      <ul className="dropdown-menu operationsdropdown w-100 show">
                        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowCheckoutAddModal() }}><IoMdAddCircleOutline style={{ fontSize: '1.5em' }} />  Kasa Hareketi Ekle</button >
                        <button onClick={(e) => { e.stopPropagation(); }} className="dropdown-item"><TbPrinter style={{ fontSize: '1.5em' }} />  Yazdır</button>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <Table
            data={dataToTable}
            rows={10}
            columns={cols}
            dates={(startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }}
            filternames={filternames}
            buttons={buttons}
          />
          <div className='row d-flex justify-content-end mb-2'>
            <div className='col-12 col-md-12'>
              <div className='row g-1'>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-success text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Gelen</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> +5.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-dark text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>TV Gider</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> 0 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-primary text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Genel Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> +2.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-danger text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Giden Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> -4.000,00 TL</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CheckoutAddModal
            show={showCheckoutAddModal}
            handleClose={handleCloseCheckoutAddModal}
            handleAdd={handleAddCheckoutAddModal}
          />
          <CheckoutUpdateModal
            show={showCheckoutUpdateModal}
            handleClose={handleCloseCheckoutUpdateModal}
            handleUpdate={handleUpdateCheckoutUpdateModal}
            inputData={inputDataForCheckoutUpdateModal}
          />
          <DeleteModal
            show={showCheckoutDeleteModal}
            handleClose={handleCloseCheckoutDeleteModal}
            handleDelete={handleDeleteCheckoutDeleteModal}
            title={"Kasa İşlemini Silmek İstiyor Musunuz?"}
            message={`${inputDataForCheckoutDeleteModal} id numaralı kasa işlemini silmek istiyor musunuz?`}
            deleteIcon={<MdDeleteOutline />}
          />
        </div>
      </div>
    </div>
  );
}

export default Checkout