// General
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import { NavLink } from 'react-bootstrap';
import Badge from '@mui/material/Badge';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../storages/slices/ToastSlice';
import { logout, resetLogoutStatus } from '../../storages/slices/LoginSlice';
// Contexts
import { useData } from '../../contexts/DataContext';
// Icons
import { FiMenu } from "react-icons/fi";
import { HiMiniChevronDown } from "react-icons/hi2";
import { FaDoorClosed } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
// Modals
import MessageModal from '../pages/modals/MessageModal';

function Header() {
  /// Storage Import
  const dispatch = useDispatch();
  const { userinfo } = useSelector((select) => select.login);
  const navigate = useNavigate();
  // Context Import
  const { sidebar, setSidebar } = useData();
  // Variables
  const dropdownpagesize = 4;
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (userinfo) { setUsername(userinfo.username) }
  }, [userinfo]);
  // Messages Dropdown
  const [messages, setMessages] = useState([]);
  const [messagesStartIndex, setMessagesStartIndex] = useState(0);
  const [messagesDropdown, setMesseagesDropdown] = useState(false);
  const toggleMesseagesDropdown = () => { setMesseagesDropdown(!messagesDropdown); };
  const handleClickOutsideMesseages = useCallback((event) => { if (!event.target.closest('.messagesdropdown')) { setMesseagesDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMesseages);
    return () => { document.removeEventListener('click', handleClickOutsideMesseages); };
  }, [handleClickOutsideMesseages]);
  // Alerts Dropdown
  const [alerts, setAlerts] = useState([]);
  const [alertsStartIndex, setAlertsStartIndex] = useState(0);
  const [alertsDropdown, setAlertsDropdown] = useState(false);
  const toggleAlertsDropdown = () => { setAlertsDropdown(!alertsDropdown); };
  const handleClickOutsideAlerts = useCallback((event) => { if (!event.target.closest('.alertsdropdown')) { setAlertsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideAlerts);
    return () => { document.removeEventListener('click', handleClickOutsideAlerts); };
  }, [handleClickOutsideAlerts]);
  // Profile Dropdown
  const [profileDropdown, setProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => { setProfileDropdown(!profileDropdown); };
  const handleClickOutsideProfile = useCallback((event) => { if (!event.target.closest('.profiledropdown')) { setProfileDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideProfile);
    return () => { document.removeEventListener('click', handleClickOutsideProfile); };
  }, [handleClickOutsideProfile]);
  // Functions
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetLogoutStatus());
    navigate("/girisyap");
    dispatch(addToast({ background: 'danger', icon: 'logout', message: 'Çıkış Yapıldı!', delay: 2000 }));
  }
  // Temp data
  useEffect(() => {
    setMessages([
      { id: 1001, title: "Hello World!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: false },
      { id: 1002, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: false },
      { id: 1003, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1004, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1005, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1006, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1007, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1008, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1009, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1010, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1011, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1012, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1013, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1014, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1015, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1016, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1017, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true },
      { id: 1018, title: "Hello World 2!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true }
    ])
  }, []);
  useEffect(() => {
    setAlerts([
      { id: 1001, title: "Alert!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: false },
      { id: 1002, title: "Danger!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: false },
      { id: 1003, title: "Missing Api's!", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", seen: true }
    ])
  }, []);
  // Modals
  // Message Modal
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [inputDataForMessageModal, setInputDataForMessageModal] = useState({});
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setInputDataForMessageModal({});
  }
  const handleShowMessageModal = (data) => {
    setInputDataForMessageModal(data);
    setShowMessageModal(true);
  }
  // HTML
  return (
    <div className="topbar-custom px-5" style={{ left: `${sidebar ? '260px' : '0px'}` }}>
      <div className="d-flex justify-content-between h-100">
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <button className="button-toggle-menu nav-link" onClick={() => setSidebar(!sidebar)}>
              <FiMenu className="align-middle fullscreen noti-icon" style={{ fontSize: '1.8em' }} />
            </button>
          </li>
        </ul>
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <div className='position-relative'>
              <NavLink className='messagesdropdown' onClick={toggleMesseagesDropdown}>
                <Badge badgeContent={messages.filter(item => item.seen === false).length} color="info">
                  <BiMessageDetail style={{ fontSize: '1.6em' }} />
                </Badge>
              </NavLink>
              {messagesDropdown && (
                <div className='card messagesdropdown position-absolute m-0 p-2' style={{ top: '100%', left: '0px', minWidth: '200px' }}>
                  <div className='row g-2'>
                    {messages.slice(messagesStartIndex, messagesStartIndex + dropdownpagesize).map((message, index) => {
                      return (
                        <div key={index} className='col-xs-12 col-md-12' style={{ cursor: 'pointer' }} onClick={() => { handleShowMessageModal(message); setMessages(messages.map(item => item.id === message.id ? { ...item, seen: true } : item)) }}>
                          <div className={`card bg-light m-0 p-0 ${message.seen ? 'text-muted' : 'text-black'}`}>
                            <div className='card-header m-0 p-1'>
                              <div className=' card-title m-0 p-0'>{message.title}</div>
                            </div>
                            <div className='card-body m-0 p-2'>
                              <p className='m-0 p-0'>{message.seen ? '' : 'Okunmamış '}Mesaj:</p>
                              <p className='m-0 p-0'>{message.text.slice(0, 20)}...</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='col-xs-3 col-md-3'><button className='btn btn-outline-info w-100 m-0 p-1' onClick={() => setMessagesStartIndex(messagesStartIndex - dropdownpagesize)} disabled={messagesStartIndex - dropdownpagesize < 0}>{'<'}</button></div>
                    <div className='col-xs-6 col-md-6'><div className='d-flex justify-content-center align-items-center'>{`${Math.ceil(messagesStartIndex / dropdownpagesize) + 1}/${Math.ceil(messages.length / dropdownpagesize)}`}</div></div>
                    <div className='col-xs-3 col-md-3'><button className='btn btn-outline-info w-100 m-0 p-1' onClick={() => setMessagesStartIndex(messagesStartIndex + dropdownpagesize)} disabled={messagesStartIndex + dropdownpagesize >= messages.length}>{'>'}</button></div>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li>
            <div className='position-relative'>
              <NavLink className='alertsdropdown' onClick={toggleAlertsDropdown}>
                <Badge badgeContent={alerts.filter(item => item.seen === false).length} color="warning">
                  <IoNotificationsOutline style={{ fontSize: '1.6em' }} />
                </Badge>
              </NavLink>
              {alertsDropdown && (
                <div className='card alertsdropdown position-absolute m-0 p-2' style={{ top: '100%', left: '0px', minWidth: '200px' }}>
                  <div className='row g-2'>
                    {alerts.slice(alertsStartIndex, alertsStartIndex + dropdownpagesize).map((alert, index) => {
                      return (
                        <div key={index} className='col-xs-12 col-md-12' style={{ cursor: 'pointer' }} onClick={() => { handleShowMessageModal(alert); setAlerts(alerts.map(item => item.id === alert.id ? { ...item, seen: true } : item)) }}>
                          <div className={`card bg-light m-0 p-0 ${alert.seen ? 'text-muted' : 'text-black'}`}>
                            <div className='card-header m-0 p-1'>
                              <div className=' card-title m-0 p-0'>{alert.title}</div>
                            </div>
                            <div className='card-body m-0 p-2'>
                              <p className='m-0 p-0'>{alert.seen ? '' : 'Okunmamış '}Mesaj:</p>
                              <p className='m-0 p-0'>{alert.text.slice(0, 20)}...</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='col-xs-3 col-md-3'><button className='btn btn-outline-info w-100 m-0 p-1' onClick={() => setAlertsStartIndex(alertsStartIndex - dropdownpagesize)} disabled={alertsStartIndex - dropdownpagesize < 0}>{'<'}</button></div>
                    <div className='col-xs-6 col-md-6'><div className='d-flex justify-content-center align-items-center'>{`${Math.ceil(alertsStartIndex / dropdownpagesize) + 1}/${Math.ceil(alerts.length / dropdownpagesize)}`}</div></div>
                    <div className='col-xs-3 col-md-3'><button className='btn btn-outline-info w-100 m-0 p-1' onClick={() => setAlertsStartIndex(alertsStartIndex + dropdownpagesize)} disabled={alertsStartIndex + dropdownpagesize >= messages.length}>{'>'}</button></div>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li className="dropdown notification-list topbar-dropdown">
            <NavLink className="nav-link profiledropdown dropdown-toggle nav-user me-0" onClick={toggleProfileDropdown}>
              <img className="rounded-circle" src="https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg" alt="user-image" />
              <span className="pro-user-name ms-1">{username}</span>
              <HiMiniChevronDown className='ms-1' />
            </NavLink>
            {profileDropdown && (
              <div className="dropdown-menu dropdown-menu-end profile-dropdown show profiledropdown" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 52px)" }}>
                <button className="dropdown-item notify-item" onClick={handleLogout}>
                  <FaDoorClosed className="fs-16 align-middle me-2" />
                  <span>Çıkış</span>
                </button>
              </div>
            )}
          </li>
        </ul>
      </div>
      <MessageModal
        show={showMessageModal}
        handleClose={handleCloseMessageModal}
        inputData={inputDataForMessageModal}
      />
    </div>
  );
}

export default Header;