// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllCustomers, getCustomerById, addNewCustomer, updateCustomer, deleteCustomer, resetAddStatus, resetUpdateStatus, resetDeleteStatus } from '../../../storages/slices/backend/CustomerSlice';
// Icons
import { TbPrinter } from "react-icons/tb";
import { RiUserAddLine, RiUserSettingsFill, RiUserStarFill, RiUserUnfollowFill } from "react-icons/ri";
// Modals
import CustomerAddModal from '../modals/customers/CustomerAddModal';
import DeleteModal from '../modals/DeleteModal';
import CustomerUpdateModal from '../modals/customers/CustomerUpdateModal';
import CustomerDetailsModal from '../modals/customers/CustomerDetailsModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Customers() {
  // Storage Import
  const dispatch = useDispatch();
  const { customers, customer, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.customer);
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatus === "fulfilled") {
      const data = customers.map((data) => {
        const id = data.id;
        const name = data.name;
        const phone = data.phone1;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const addressString = `${address}\n${district}/${city}`;
        const addressDiv = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        return { id, name, phone, address, district, city, addressString, addressDiv };
      });
      setDataToTable(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressDiv, searchAddress: item.addressString })));
      setDataToPrint(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressString })));
    }
  }, [getAllStatus, customers]);
  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseCustomerAddModal();
      dispatch(getAllCustomers());
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Müşteri Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseCustomerUpdateModal();
      dispatch(getAllCustomers());
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'userUpdate', message: 'Müşteri Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseCustomerDeleteModal();
      dispatch(getAllCustomers());
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'userDelete', message: 'Müşteri Bilgileri Silindi!', delay: 6000 }));
    }
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForCustomerUpdateModal(customer);
        setShowCustomerUpdateModal(true);
        setDataRequester('');
      } else if (dataRequester === 'delete') {
        setInputDataForCustomerDeleteModal({ id: customer.id, name: customer.name });
        setShowCustomerDeleteModal(true);
        setDataRequester('');
      } else if (dataRequester === 'details') {
        setInputDataForCustomerDetailsModal(customer);
        setShowCustomerDetailsModal(true);
        setDataRequester('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error) { dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 })); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'MÜŞTERİ' }, { value: 'phone', name: 'TELEFON' }, { value: 'address', name: 'ADRES' }];
  const filters = ['id', 'name', 'phone', 'searchAddress'];
  const select = (id) => { handleShowCustomerUpdateModal(id); }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className="button-with-icon btn btn-primary mx-1" onClick={(e) => { e.stopPropagation(); handleShowCustomerUpdateModal(rowData.id); }}>
          <RiUserSettingsFill className='button-icon' />
          Güncelle
        </button>
        <button className="button-with-icon btn btn-warning mx-1" onClick={(e) => { e.stopPropagation(); }}>
          <RiUserStarFill className='button-icon' />
          Detay
        </button>
        <button className="button-with-icon btn btn-danger mx-1" onClick={(e) => { e.stopPropagation(); handleShowCustomerDeleteModal(rowData.id); }}>
          <RiUserUnfollowFill className='button-icon' />
          Sil
        </button>
      </div>
    );
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
          bodyAlignBroker={['address']}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, dataToTable]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowCustomerAddModal(); }}>
          <RiUserAddLine className='button-icon' />
          Yeni Müşteri Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPrint, cols: cols, pdftitle: "Müşteriler" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Customer Add Modal
  const [showCustomerAddModal, setShowCustomerAddModal] = useState(false);
  const handleCloseCustomerAddModal = () => { setShowCustomerAddModal(false) };
  const handleShowCustomerAddModal = () => { setShowCustomerAddModal(true) };
  const handleAddCustomerAddModal = (values) => {
    dispatch(addNewCustomer({ newcustomer: values }));
    handleCloseCustomerAddModal();
  };
  // Customer Update Modal
  const [showCustomerUpdateModal, setShowCustomerUpdateModal] = useState(false);
  const [inputDataForCustomerUpdateModal, setInputDataForCustomerUpdateModal] = useState({});
  const handleCloseCustomerUpdateModal = () => {
    setShowCustomerUpdateModal(false)
    setInputDataForCustomerUpdateModal({});
  };
  const handleShowCustomerUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getCustomerById({ id: id }));
  };
  const handleUpdateCustomerUpdateModal = (values) => {
    dispatch(updateCustomer({ customer: values }));
    handleCloseCustomerUpdateModal();
  };
  // Customer Details Modal
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(false);
  const [inputDataForCustomerDetailsModal, setInputDataForCustomerDetailsModal] = useState({});
  const handleCloseCustomerDetailsModal = () => {
    setShowCustomerDetailsModal(false)
    setInputDataForCustomerDetailsModal({});
  };
  const handleShowCustomerDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getCustomerById({ id: id }));
  };
  // Customer Delete Modal
  const [showCustomerDeleteModal, setShowCustomerDeleteModal] = useState(false);
  const [inputDataForCustomerDeleteModal, setInputDataForCustomerDeleteModal] = useState({ id: -1, name: '' });
  const handleCloseCustomerDeleteModal = () => {
    setShowCustomerDeleteModal(false);
    setInputDataForCustomerDeleteModal(-1);
  };
  const handleShowCustomerDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getCustomerById({ id: id }));
  };
  const handleDeleteCustomerDeleteModal = () => {
    dispatch(deleteCustomer({ id: inputDataForCustomerDeleteModal.id }));
    handleCloseCustomerDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <CustomerAddModal
          show={showCustomerAddModal}
          handleClose={handleCloseCustomerAddModal}
          handleAdd={handleAddCustomerAddModal}
        />
        <CustomerUpdateModal
          show={showCustomerUpdateModal}
          handleClose={handleCloseCustomerUpdateModal}
          handleUpdate={handleUpdateCustomerUpdateModal}
          inputData={inputDataForCustomerUpdateModal}
        />
        <CustomerDetailsModal
          show={showCustomerDetailsModal}
          handleClose={handleCloseCustomerDetailsModal}
          inputData={inputDataForCustomerDetailsModal}
        />
        <DeleteModal
          show={showCustomerDeleteModal}
          handleClose={handleCloseCustomerDeleteModal}
          handleDelete={handleDeleteCustomerDeleteModal}
          title="Müşteri'yi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForCustomerDeleteModal.name} adlı müşteriyi silmek istediğinize emin misiniz?`}
          deleteIcon={<RiUserUnfollowFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage
      title='Müşteriler'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Customers;