// General
import React from 'react';
// Style
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// Components
import PieChart from '../components/PieChart';

function Surveys() {
  // Data & Api
  const data = [
    { id: 1001, question: 'Teknisyen Dediği Saatte Geldi Mi ? ', response: { Yes: 10, No: 20, notSure: 5 } },
    { id: 1002, question: 'Teknisyen Davranışlarından, Kılık ve Kıyafetlerinden Memnun Musunuz ?', response: { Yes: 10, No: 20, notSure: 5 } },
    { id: 1003, question: 'Teknisyen Cihazınızla Yeterince İlgilendi Mi ?', response: { Yes: 10, No: 20, notSure: 5 } },
    { id: 1004, question: 'Genel Olarak Servis Hizmetimizden Memnun Musunuz?', response: { Yes: 10, No: 20, notSure: 5 } }
  ];
  const responseTranslator = { Yes: 'Evet', No: 'Hayır', notSure: 'Belli Değil' }
  // HTML
  return (
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mt-4'>
            <div className="card-header">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0">Anket Tablosu</h5>
              </div>
            </div>
            <div className='card-body'>
              <DataTable
                value={data}
                size='small'
                className='unactivehover'
                stripedRows
                showGridlines>
                <Column field="question" header="ANKET SORUSU" />
                <Column field="response.Yes" header="EVET" align={'center'} bodyClassName="text-center" />
                <Column field="response.No" header="HAYIR" bodyClassName="text-center" align={'center'} />
                <Column field="response.notSure" header="BELLİ DEĞİL" bodyClassName="text-center" align={'center'} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='row'>
          {data.map((item, index) => {
            return (
              <div key={index} className="col-12 col-md-6">
                <div className="card-body">
                  <h6><b>{item.question}</b></h6>
                  <PieChart data={Object.entries(item.response).map(([key, value]) => ({ name: responseTranslator[key], value: value }))} unit='Adet' />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Surveys;